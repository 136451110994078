import { AbstractControl, UntypedFormControl, FormGroup, ValidatorFn } from '@angular/forms';

export function dateValidation(otherDateCtrl: string): ValidatorFn {

    return (control: UntypedFormControl) => {
        if (!control || !control.parent || !control.value || !control.parent.get(otherDateCtrl).value) {
            return null;
        }
        // const otherCtrlDate = new Date(control.parent.get(otherDateCtrl).value);
        // const ctrlDate = new Date(control.value);
        //
        // if(otherCtrlDate && otherDateCtrl === 'deactivationDate') {
        //     if(otherCtrlDate > ctrlDate) {
        //         control.setErrors(null);
        //         control.parent.get(otherDateCtrl).setErrors(null);
        //         return null
        //     } else {
        //         return { 'dateRangeInvalid': true };
        //     }
        // }
        // if(ctrlDate && otherDateCtrl === 'activationDate') {
        //     if(otherCtrlDate < ctrlDate) {
        //         control.setErrors(null);
        //         control.parent.get(otherDateCtrl).setErrors(null);
        //         return null;
        //     } else {
        //         return { 'dateRangeInvalid': true };
        //     }
        // }
    };

}
