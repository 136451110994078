
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authService.getTokenFromCookie();
    const tenantId = this.authService.getTenantIdFromCookie();

    const promise:Promise<boolean | UrlTree> = new Promise((resolve, reject) => {
      // get token from cookie.
      if (token && tenantId) {
        this.authService.verifyToken(token, tenantId).subscribe(
          (res) => {
            if (res.uid) {
              this.authService.saveInCookie('uid', res.uid);
            }
            if (res.claims['super-admin']) {
              this.authService.saveInCookie("superAdmin", res.claims['super-admin']);
            } else if (res.claims['admin']) {
              this.authService.saveInCookie("admin", res.claims['admin']);
            }
            if (res.claims['email']) {
              this.authService.saveInCookie("email", res.claims['email']);
            }
            if (res.claims['siqTenantId']) {
              this.authService.saveInCookie("siqId", res.claims['siqTenantId']);
            }
            if (res.claims['apps']) {
              this.authService.saveInCookie("apps", res.claims['apps']);
            }
            resolve(true);
          },
          (err: HttpErrorResponse) => {
            // if token is invalid then remove it from
            // cookie and navigate user to login
            this.authService.removeTokenFromCookie();
            this.authService.removeTenantIdFromCookie();
            this.router.navigateByUrl('/');
            reject(false);
          }
        );
      } else {
        // let notRegUser = this.authService.getFromCookie("superAdmin") || this.authService.getFromCookie("admin") == "true";
        // let email = this.authService.getFromCookie("email");
        // let siqId = this.authService.getFromCookie("siqId");
        //
        // if (!notRegUser) {
        //   this.router.navigate([`/tenants/${siqId}/users/${email}/view`]);
        // } else {
        //   this.router.navigateByUrl('/dashboard');
        // }
        this.router.navigateByUrl('/');
        reject(false);
      }
    });

    return promise;
  }
}
