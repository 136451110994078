import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormPart } from '../../../../components/tenants/create-tenant/create-tenant.component';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MAC_ADDRESS_REGEX } from '../../../../app.constants';
import {Clipboard} from '@angular/cdk/clipboard';
import { ToasterService } from '../../../../services/toaster.service';

@Component({
  selector: 'app-device-general-info',
  templateUrl: './device-general-info.component.html',
  styleUrls: ['./device-general-info.component.scss'],
  viewProviders: [FormPart]
})
export class DeviceGeneralInfoComponent implements OnInit {
  @Input() readOnly;
  @Input() manufacturers: string[];
  @Input() models: string[]
  @Input() category: string[];
  @Input() isCreateMode: boolean;

  @Output() deviceTypeChange = new EventEmitter<any>();

  deviceId: string;

  constructor(private readonly formGroupDirective: FormGroupDirective, private clipboard: Clipboard, private toaster: ToasterService) {
  }

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.form.get('deviceId').valueChanges.subscribe(deviceId => {
      this.deviceId = deviceId;
    }); 
  }

  onKeyUp(ev): void {
    this.deviceId = (this.deviceId.toUpperCase()
    .replace(/[^\d|A-Z]/g, '')
    .match(/.{1,2}/g) || [])
    .join("-")
    this.form.get("deviceId").setValue(this.deviceId)
  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

}
