<mat-card>
  <mat-card-content>
    <mat-grid-list cols="24" gutterSize="16px" rowHeight="30px">
      <mat-grid-tile [colspan]="11" class="header">
        <div>Name</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="11" class="header">
        <div>Value</div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="24" gutterSize="16px" rowHeight="50px">
      <ng-container *ngIf="!items.controls.length">
        <mat-grid-tile [colspan]="24">
          <div class="text-center">No Data</div>
        </mat-grid-tile>
      </ng-container>
      <ng-container *ngFor="let item of items?.controls; let i = index">
        <!--suppress TypeScriptValidateTypes -->
        <ng-container *ngIf="item.enabled && !readOnly" [formGroup]="item">
          <mat-grid-tile [colspan]="11">
            <mat-form-field class="w-100 small" floatLabel="never">
              <input #value
                     [matAutocomplete]="auto"
                     [placeholder]="readOnly ? '' : 'Enter Name'"
                     [readonly]="readOnly"
                     [required]="!!item.value.value"
                     formControlName="name"
                     matInput
                     maxlength="100"
                     type="text"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let name of filteredNames(value.value)" [value]="name">
                  {{ name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="11">
            <mat-form-field class="w-100 small" floatLabel="never">
              <input [placeholder]="readOnly ? '' : 'Enter value'"
                     [readonly]="readOnly"
                     [required]="!!item.value.name"
                     formControlName="value"
                     matInput
                     maxlength="100"
                     type="text"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <div class="w-100 text-right">
              <button (click)="removeItem(i)" class="mat-elevation-z1 small" color="warn"
                      mat-mini-fab style="margin-right: 14px">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
          </mat-grid-tile>
        </ng-container>
        <ng-container *ngIf="readOnly">
          <mat-grid-tile [colspan]="11">
            <div class="w-100 small">{{ item.value.name || '-' }}</div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="11">
            <div class="w-100 small">{{ item.value.value || '-' }}</div>
          </mat-grid-tile>
        </ng-container>
      </ng-container>
      <mat-grid-tile *ngIf="!readOnly" [colspan]="24">
        <button (click)="addItem()" class="rounded-pill small" color="primary" mat-flat-button type="button">
          <mat-icon>add</mat-icon>
          {{ addLabel }}
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
