<mat-card style="margin-top: 10px">
  <mat-card-content class="px-5 py-3">
    <div class="d-flex">
      <mat-form-field class="w-100 small" floatLabel="never">
        <input [formControl]="inputCtrl" matInput placeholder="{{placeholder}}" type="search">
        <button *ngIf="inputCtrl.value" mat-icon-button matSuffix (click)="clearSearch()" aria-label="Clear search">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <div class="my-auto ml-4">
        <button *ngIf="label" (click)="triggerSearch()" class="rounded-pill small" color="primary" mat-flat-button>
          <mat-icon>search</mat-icon>
          {{ label }}
        </button>
        <button *ngIf="!label" (click)="triggerSearch()" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="my-auto ml-4" *ngIf="filterBtn">
        <button *ngIf="filterLabel" (click)="triggerFilter()" class="rounded-pill small" color="primary" mat-flat-button>
          <mat-icon *ngIf="!filterClicked">filter_alt</mat-icon>
          <mat-icon *ngIf="filterClicked">filter_alt_off</mat-icon>
          {{ filterLabel }}
        </button>
        <button *ngIf="!filterLabel" (click)="triggerFilter()" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
          <mat-icon *ngIf="!filterClicked">filter_alt</mat-icon>
          <mat-icon *ngIf="filterClicked">filter_alt_off</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
