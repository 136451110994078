import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { ZoneService } from 'src/app/services/zone.service';

@Component({
  selector: 'app-view-zones',
  templateUrl: './view-zones.component.html',
  styleUrls: ['./view-zones.component.scss']
})
export class ViewZonesComponent implements OnInit {

  @Input()
  private floorId: string;

  @Input()
  private tenantId: string;

  @Input()
  private buId: string;

  @Input()
  private siteId: string;

  loading = false;
  displayedColumns: string[] = ['name', 'id', 'parentId', 'createdAt', 'isActive', 'action'];
  dataSource = [];

  constructor(private router: Router, private zoneService: ZoneService, private toaster: ToasterService) { }

  ngOnInit(): void {
    this.getZonesByFloorId();
  }

  getZonesByFloorId(){
    this.zoneService.findZonesByFloorId(this.floorId, this.siteId).subscribe(
      (res:any)=>{
        this.dataSource=res.zones;
      }
    )
  }
  
  viewZone(zone){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${zone.id}/view`]);
  }

  editZone(zone) {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${zone.id}/edit`]);
  }
}
