<mat-card>
  <mat-card-content>

    <mat-grid-list cols="24" gutterSize="16px" rowHeight="30px">
      <mat-grid-tile [colspan]="6" class="header">
        <div>Product Code</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3" class="header">
        <div>Cascade</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" class="header">
        <div>Portfolio</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" class="header">
        <div>Activation Date</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" class="header">
        <div>Deactivation Date</div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3" class="header">&nbsp;</mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="24" gutterSize="16px" rowHeight="50px">
      <ng-container *ngIf="!subscriptions.controls.length">
        <mat-grid-tile [colspan]="24">
          <div class="text-center">No Data</div>
        </mat-grid-tile>
      </ng-container>
      <ng-container *ngFor="let subscription of subscriptions.controls; let i = index">
        <!--suppress TypeScriptValidateTypes -->
        <form [formGroup]="subscription" [class.read-only]="subscription.value._disabled || readOnly">
          <mat-grid-tile [colspan]="6">
            <mat-form-field class="w-100 small " floatLabel="never">
              <!-- <input formControlName="productCode" matInput [placeholder]="isReadonly ? '' : 'Product Code'" type="text"
                     [readonly]="subscription.value._disabled || readOnly"
                     [title]="subscription.value.productCode"
              /> -->
              <mat-select matInput formControlName="productCode" [placeholder]="isReadonly ? '' : placeHolder"
                name="productCode" required [disabled]="subscription.value._disabled || readOnly" (selectionChange)="populatePortfolio($event.value, i)">
                <mat-option *ngFor="let data of productData" [value]="data.productCode" hidden="hidden">
                    {{ data.productCode }}
                </mat-option>
                <mat-option *ngFor="let data of subOptions" [value]="data.productCode">
                  {{ data.productCode }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="subscription.controls['productCode'].hasError('required') && !isReadonly && subOptions.length != 0">
                You must enter a Product Code
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="3">
            <mat-form-field class="w-100 small" floatLabel="never">
              <!-- <input formControlName="cascade" matInput [placeholder]="isReadonly ? '' : 'Cascade'" type="text"
                     [readonly]="subscription.value._disabled || readOnly"
              /> -->
              <mat-select matInput formControlName="cascade" [placeholder]="isReadonly ? '' : 'Cascade'"
              name="cascade" required [disabled]="subscription.value._disabled || readOnly" >
              <mat-option *ngFor="let type of types" [value]="type">
                  {{ type }}
              </mat-option>
              </mat-select>
              <mat-error *ngIf="subscription.controls['cascade'].hasError('required') && !isReadonly">
                Enter Cascade
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4">
            <mat-form-field class="w-100 small" floatLabel="never">
              <input formControlName="portfolio" matInput required [placeholder]="isReadonly ? '' : 'Portfolio'" type="text"
                     [readonly]="true"
              >
              <mat-error *ngIf="subscription.controls['portfolio'].hasError('required') && !isReadonly">
                Enter Portfolio
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4">
            <mat-form-field class="w-100 small" floatLabel="never">
              <input [matDatepicker]="picker1" formControlName="activationDate" matInput required (click)="(!subscription.value._disabled && !readOnly)? openDatePicker(picker1):{}"
                     [placeholder]="isReadonly ? '' : 'Activation Date'" [readonly]="true" [max]="maxDate"
              />
              <mat-datepicker-toggle *ngIf="!readOnly" [for]="picker1" matSuffix>
              </mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="subscription.controls['activationDate'].hasError('required') && !isReadonly">
                You must enter a Date
              </mat-error>
              <mat-error *ngIf="subscription.controls['activationDate'].hasError('dateRangeInvalid') && !isReadonly">
                Invalid Activation Date
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4">
            <mat-form-field class="w-100 small" floatLabel="never">
              <input [matDatepicker]="picker2" formControlName="deactivationDate" matInput required (click)="(!subscription.value._disabled && !readOnly)? openDatePicker(picker2):{}"
                     [placeholder]="isReadonly ? '' : 'Deactivation Date'" [readonly]="true" [max]="maxDate"
              />
              <mat-datepicker-toggle *ngIf="(!readOnly) || (!readOnly && subscription.controls['deactivationDate'].hasError('required'))" [for]="picker2" matSuffix>
              </mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error *ngIf="subscription.controls['deactivationDate'].hasError('required') && !readOnly">
                You must enter a Date
              </mat-error>
              <mat-error *ngIf="subscription.controls['deactivationDate'].hasError('dateRangeInvalid') && !isReadonly">
                Invalid Deactivation Date
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="!readOnly" [colspan]="3">
            <div *ngIf="!readOnly && !subscription.controls['cascadedSubscription']?.value" class="w-100 text-right">
              <button *ngIf="!subscription.value._disabled" (click)="removeSubscription(i)" class="mat-elevation-z1 small" color="warn" mat-mini-fab
                      style="margin-right: 14px">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
          </mat-grid-tile>
        </form>
      </ng-container>

      <mat-grid-tile *ngIf="!readOnly" [colspan]="24">
        <button (click)="addSubscription()" class="rounded-pill small" color="primary" mat-flat-button type="button">
          <mat-icon>add</mat-icon>
          Add Subscription
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
