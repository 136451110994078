<mat-card class="mt-4">
  <mat-card-content>
    <div class="loader">
      <app-loader *ngIf="loading" [noText]="true"></app-loader>
    </div>

    <table [dataSource]="dataSource" [ngClass]="{loading: loading}" class="w-100 custom-table"
           mat-table matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>FLOOR NAME</th>
        <td *matCellDef="let element" mat-cell> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th *matHeaderCellDef mat-header-cell>FLOOR LEVEL</th>
        <td *matCellDef="let element" mat-cell> {{element.zindex}} </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell>FLOOR ID</th>
        <td *matCellDef="let element" mat-cell> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th *matHeaderCellDef mat-header-cell>CREATED DATE</th>
        <td *matCellDef="let element" mat-cell> {{element.createdAt | date : 'MM/dd/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th *matHeaderCellDef mat-header-cell>STATUS</th>
        <td *matCellDef="let element" mat-cell>{{element.isActive ? 'ACTIVE' : 'INACTIVE'}}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="text-center" mat-header-cell>ACTION</th>
        <td *matCellDef="let element" mat-cell>
          <div class="w-100 text-center">
            <button (click)="viewFloor(element)" class="mat-elevation-z1 small mr-2" color="primary"
                    mat-mini-fab>
              <mat-icon>visibility</mat-icon>
            </button>
            <button (click)="editFloor(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
              <mat-icon>mode_edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
      <tr *matNoDataRow class="mat-row">
        <td [attr.colspan]="displayedColumns.length" class="mat-cell text-center">
          No Floors found
        </td>
      </tr>
    </table>

  </mat-card-content>
</mat-card>
