import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ToasterService } from '../../../services/toaster.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-zone-general-information',
  templateUrl: './zone-general-information.component.html',
  styleUrls: ['./zone-general-information.component.scss']
})
export class ZoneGeneralInformationComponent implements OnInit {
  @Input() isReadonly;
  @Input() parentId;

  constructor(private readonly formGroupDirective: FormGroupDirective, private clipboard: Clipboard, private toaster: ToasterService) { }

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }

  ngOnInit(): void {
  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

}
