<mat-card>
  <mat-card-content>
    <mat-form-field class="w-100" floatLabel="always">
      <mat-label>Market segment</mat-label>
      <input required [formControl]="inputCtrl" matInput class="d-none"/>
      <mat-chip-list #chipList aria-label="Market segment selection" class="small">
        <mat-chip (removed)="remove(marketSegment)"
                  *ngFor="let marketSegment of marketSegmentCtrl?.value"
                  [removable]="!readOnly"
                  [selectable]="!readOnly"
        >
          {{ marketSegment }}
          <mat-icon *ngIf="!readOnly" matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input #segmentInput
               required
               (matChipInputTokenEnd)="addNewSegment($event)"
               *ngIf="!readOnly"
               [formControl]="inputCtrl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               class="w-100"
               matInput
               [placeholder]="readOnly ? '' : 'Enter segment name...'"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSegment($event)">
        <mat-option *ngFor="let marketSegment of filteredMarketSegments | async" [value]="marketSegment">
          {{ marketSegment }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="marketSegmentCtrl.getError('minLength')">
        At least one market segment is required
      </mat-error>
    </mat-form-field>
  </mat-card-content>
</mat-card>
