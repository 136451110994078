import {Observable} from 'rxjs';
import {TenantService} from '../services/tenant.service';
import {map} from 'rxjs/operators';
import {CustomDataSource, Page} from './CustomDataSource';
import {AuthService} from '../auth/services/auth.service';

export class TenantDataSource extends CustomDataSource<Tenant> {
  constructor(private tenantService: TenantService, private authService: AuthService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string): Observable<Page<Tenant>> {
    let observable: Observable<any>;
    let admin = this.authService.getFromCookie("admin") == "true";
    if (search) {
      observable = this.tenantService.searchTenant(search, page, size, sortField, sortDir);
    } else if (!admin) {
      observable = this.tenantService.findAllTenant(page, size, sortField, sortDir);
    } else {
      let tenantId = this.authService.getFromCookie("tenantId");
      this.tenantService.getTenantbySSOtenantId(tenantId).subscribe((res) => {
        this.search = res["name"];
        observable = this.tenantService.searchTenant(search, page, size, sortField, sortDir);
      })
    }
    return observable.pipe(map(res => ({data: res.tenants, total: res.total})));
  }
}

export interface Tenant {
  id: string;
  name: string;
  createdAt: Date;
  isActive: boolean;
}
