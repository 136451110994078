import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FloorService } from '../../../services/floors-service';
import { FILE_UPLOAD } from '../../../app.constants';
import { ArrayValidators } from '../../../misc/CustomValidators';
import { Observable } from 'rxjs';
import { patchForm } from '../../../misc/Utility';
import { ToasterService } from '../../../services/toaster.service';
import { omit } from 'lodash';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-floor-editor',
  templateUrl: './floor-editor.component.html',
  styleUrls: ['./floor-editor.component.scss']
})
export class FloorEditorComponent implements OnInit {

  tenantId: string;
  buId: string;
  siteId: string;
  form: UntypedFormGroup;
  mode: string;
  floorId: string;
  loading = false;
  keys: string[] = [];
  labels: string[] = [];
  allowedFileSize: number;
  allowedFileTypes: string[];
  uploadedFile: File;
  rawData: any;
  imageURL: any;
  floorPlanUpdated: boolean;
  selectedTabIndex: number;

  get viewMode(): boolean {
    return this.mode === 'view';
  }

  get createMode(): boolean {
    return !this.floorId;
  }

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToasterService,
    private floorService: FloorService,
  ) {
  }


  ngOnInit(): void {
    this.allowedFileSize = FILE_UPLOAD.MAXIMUM_SIZE_KB
    this.allowedFileTypes = FILE_UPLOAD.ALLOWED_FILE_TYPES
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.buId = paramMap.get('buId');
      this.siteId = paramMap.get('siteId');
      this.floorId = paramMap.get('floorId');
      this.mode = paramMap.get('mode') ?? (this.floorId ? 'view' : 'edit');;
      this.initForm();
      // if (this.mode == 'view' || this.mode == 'update') {
      //   this.loadFloorDetails();
      // }
    });
    this.route.queryParamMap.subscribe(query => {
      this.selectedTabIndex = parseInt(query.get('selectedTabIndex'));
    });
    this.loadKeys();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    // do something based on selected tab index
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [this.floorId],
      name: ['', [Validators.required]],
      zIndex: ['', [Validators.required]],
      siteId: [this.siteId, [Validators.required]],
      isActive: [true, Validators.required],
      keys: this.fb.array([]),
      zones: this.fb.array([]),
    });
    if (this.floorId) {
      this.loadFloorDetails();
    }
  }

  loadKeys(): void {
    this.floorService.loadKeys()
      .subscribe(res => this.keys = res);
  }

  goToViewSite(): void {
    this.router.navigate([`tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/view`], { queryParams: { selectedTabIndex: 1 } });
  }

  toggleUpdate(): void {
    this.mode = this.viewMode ? 'edit' : 'view';
  }

  cancel(): void {
    if (this.createMode) {
      this.goToViewSite();
    } else {
      this.toggleUpdate();
      this.loadFloorDetails();
    }
  }

  saveFloor(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toaster.info('Form contains some errors');
      return;
    }
    // if ((!this.uploadedFile && !this.floorId && this.mode == 'view') || 
    // (!this.uploadedFile && this.floorId && this.mode == 'edit' && !this.imageURL)) {
    //   this.toaster.info('Please upload a floor plan');
    //   return;
    // }
    let observable: Observable<any>;
    let data = { ...this.form.value };
    data.keys = data.keys.filter(k => k.name && k.value);
    let payload = {...data, "floorPlanUpdated": this.floorPlanUpdated ?? false, "fileContentType": null, "floorPlanBase64": null}
    if(this.imageURL){
      const plan = this.imageURL.split(";")
      if(plan.length === 2) {
        const floorPlan = {
          "floorPlanUpdated": this.floorPlanUpdated,
          "fileContentType": plan[0]?.slice(5),
          "floorPlanBase64": plan[1]?.slice(7)
        }
        payload = {...payload, ...floorPlan}
      }
    }
    if (this.createMode) {
      observable = this.floorService.createFloor(payload);
    } else {
      payload = omit(payload, 'zones')
      observable = this.floorService.updateFloor(payload);
    }
    this.loading = true;
    observable
      .subscribe(res => {
        if (this.createMode) {
          this.toaster.success('Floor created successfully.\nID: ' + res.id);
          this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${res.id}/view`]);
        } else {
          this.toaster.success('Floor updated successfully.');
          this.toggleUpdate();
          this.loadFloorDetails();
        }
      },
        err => {
          if (err?.error?.message) {
            this.toaster.error(err.error.message.split(',').join('\n'));
          } else {
            this.toaster.error('Something went wrong. Please try again.');
          }
        })
      .add(() => {
        this.loading = false;
      });
  }

  getUploadedFile(event): void {
    this.uploadedFile = event.file;
    this.imageURL = event.imageURL;
    this.floorPlanUpdated = event.floorPlanUpdated;
  }

  resetForm(): void {
    this.form.reset();
    ['keys', 'zones'].forEach(field => {
      (this.form.get(field) as UntypedFormArray).clear();
    });
  }

  loadFloorDetails(): void {
    this.loading = true;
    this.floorService.findById(this.floorId, this.siteId)
      .subscribe(res => {
        this.resetForm();
        res.labels = res.labels ?? [];
        this.rawData = res;
        patchForm(this.form, res);
      },
        err => {
          if (err.status === 404) {
            this.toaster.error(err?.error?.message ?? 'Floor not found');
          } else {
            this.toaster.error('Something went wrong. Please try again');
          }
        })
      .add(() => {
        this.loading = false;
      });
  }

  addZone() {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/new`])
  }

}