import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth/services/auth.service';
import {Observable} from 'rxjs';
import {REST_ENDPOINT} from '../app.constants';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {

  private baseUrl = environment.apigeeLocationUrl;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  findAllBusinessUnits(page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.findAll}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  searchBusinessUnits(search: string, page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.search}?search=${search}&page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  createBusinessUnit(payload): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.add}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (payload.location[objName]) {
        delete payload.location[objName];
      }
    });

    return this.httpClient.post(url, payload,this.authService.getAuthHttpOptions());
  }

  findAllBusinessUnitsByTenant(tenantId:String): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.findByTenant}${tenantId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  findBusinessUnitsByBuId(buId:String): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.get}${buId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  updateBusinessUnit(payload):Observable<any>{
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.update}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (payload.location[objName]) {
        delete payload.location[objName];
      }
    });

    return this.httpClient.post(url, payload , this.authService.getAuthHttpOptions())
  }

  getAllSubscriptions():Observable<any>{
    const url = `${this.baseUrl}${REST_ENDPOINT.subscriptions.get}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions())
  }

  getAllLabelsByBuId(buId:String):Observable<any>{
    const url = `${this.baseUrl}${REST_ENDPOINT.businessUnits.getAllLabels}${buId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  buIDtoNameMapping(payload):Observable<any> {
    const url:string = `${this.baseUrl}${REST_ENDPOINT.businessUnits.IDtoName}`;
    return this.httpClient.post(url, payload, this.authService.getAuthHttpOptions());
  }

  getSubscriptionsByParent(parent: String, parentId: String): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.subscriptions.getByParent}?parent=${parent}&parentId=${parentId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }
}
