<div class="page-container">
  <h1 class="page-title">Data migration</h1>
  <mat-card>
    <mat-card-title class="card-title">
      Tenant data migration
    </mat-card-title>
    <mat-card-subtitle>
      File must be a CSV file with header
    </mat-card-subtitle>
    <mat-card-content>
      <div class="mt-5">
        <input #tenantFile accept="text/csv" name="file" type="file"/>
        <button (click)="uploadTenantFile()" class="rounded-pill small" color="primary" mat-flat-button type="button"
                [disabled]="tenantLoading">
          <mat-icon *ngIf="!tenantLoading">file_upload</mat-icon>
          <mat-icon *ngIf="tenantLoading">hourglass_top</mat-icon>
          {{tenantLoading ? 'Uploading...' : 'Upload'}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="mt-5">
    <mat-card-title class="card-title">
      Business units data migration
    </mat-card-title>
    <mat-card-subtitle>
      File must be a CSV file with header
    </mat-card-subtitle>
    <mat-card-content>
      <div class="mt-5">
        <input #buFile name="file" type="file" accept="text/csv"/>
        <button (click)="uploadBuFile()" class="rounded-pill small" color="primary" mat-flat-button type="button"
                [disabled]="buLoading">
          <mat-icon *ngIf="!buLoading">file_upload</mat-icon>
          <mat-icon *ngIf="buLoading">hourglass_top</mat-icon>
          {{buLoading ? 'Uploading...' : 'Upload'}}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
