<mat-card class="mt-4">
  <mat-card-content>
    <div class="loader">
      <app-loader
        *ngIf="dataSource.loading$ | async"
        [noText]="true"
      ></app-loader>
    </div>

    <table
      [dataSource]="dataSource"
      [ngClass]="{ loading: dataSource.loading$ | async }"
      class="w-100 custom-table"
      mat-table
      matSort
      matSortActive="createdAt"
      matSortDirection="desc"
      matSortDisableClear
    >
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>File Name</th>
        <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date/Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date : "MMM dd, yyyy HH:mm" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalCount">
        <th *matHeaderCellDef mat-header-cell>Total Records</th>
        <td style="text-align: center;" *matCellDef="let element" mat-cell>{{ element.totalCount }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let element" mat-cell>
          <csv-upload-progress [element]="element"></csv-upload-progress>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="text-center" mat-header-cell>ACTION</th>
        <td *matCellDef="let element" mat-cell>
          <div class="w-100 text-center">
            <span class="button">
              <button [disabled]="element.status == 'PROCESSING'" class="rounded-pill small mr-2 download-btn" color="primary" mat-flat-button (click)="download(element.id)">
                DOWNLOAD <img class="download-img" src="../../../../../assets/images/download.svg" />
              </button>
            </span>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <tr *matNoDataRow class="mat-row">
        <td
          [attr.colspan]="displayedColumns.length"
          class="mat-cell text-center"
        >
          No uploads found
        </td>
      </tr>
    </table>

    <mat-paginator
      #paginator
      [pageSize]="20"
      appCustomPaginator
      aria-label="Select page"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
