<mat-card>
    <mat-card-content>
  
      <mat-grid-list cols="24" gutterSize="16px" rowHeight="30px">
        <mat-grid-tile [colspan]="6" class="header">
          <div>Name</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="12" class="header">
          <div>Value</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" class="header">
          <div>Hierarchy Level</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" class="header">&nbsp;</mat-grid-tile>
      </mat-grid-list>
  
      <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
        <ng-container *ngIf="!labels.controls.length">
          <mat-grid-tile [colspan]="24">
            <div class="text-center">No Data</div>
          </mat-grid-tile>
        </ng-container>
        <ng-container *ngFor="let label of labels.controls; let i = index">
          <!--suppress TypeScriptValidateTypes -->
          <form [formGroup]="label" [class.read-only]="label.value._disabled || isReadonly">
            <mat-grid-tile [colspan]="6" [ngClass]="label.get('values').value.length ? 'name-hIndex-style' : ''">
              <mat-form-field class="w-100 small " floatLabel="never">
                <input formControlName="name" matInput [placeholder]="isReadonly ? '' : 'Enter Name'" type="text"
                    [readonly]="label.value._disabled || isReadonly"
                />
                <div ngClass="error-style" *ngIf="label.hasError('nameAndValuesRequired') && !isReadonly">
                  Enter Name and Values 
                </div>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="12">
                <mat-form-field class="w-100 small" floatLabel="never">
                  <!-- <div class="values-container">                       -->
                    <mat-chip-list formArrayName="values" #chipList>
                        <mat-chip *ngFor="let chip of label.get('values').value" [removable]="!isReadonly && !label.value._disabled" (removed)="removeValue(chip, i)">
                        {{ chip }}
                        <mat-icon matChipRemove *ngIf="!isReadonly && !label.value._disabled">cancel</mat-icon>
                        </mat-chip>
                        <input matInput 
                        [placeholder]="isReadonly ? '' : 'Enter Values'" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addValue($event, i)" [readonly]="label.value._disabled || isReadonly"/>
                    </mat-chip-list>
                  <!-- </div> -->
                  <div ngClass="error-style" *ngIf="label.hasError('nameAndValuesRequired') && !isReadonly">
                    Enter Name and Values 
                  </div>
                </mat-form-field>
            </mat-grid-tile>  
            <mat-grid-tile [colspan]="3" [ngClass]="label.get('values').value.length ? 'name-hIndex-style' : ''">
              <mat-form-field class="w-100 small" floatLabel="never">
                <mat-select matInput formControlName="hIndex" [placeholder]="isReadonly ? '' : 'Null'"
                name="hIndex" [disabled]="label.value._disabled || isReadonly" >
                <mat-option *ngFor="let hIndexValue of hIndexValues" [value]="hIndexValue">
                    {{ hIndexValue }}
                </mat-option>
                </mat-select>
                <button mat-button *ngIf="!isReadonly && !label.value._disabled && label.get('hIndex').value" matSuffix mat-icon-button aria-label="Clear" 
                (click)="deleteHIndex($event, i)">
                <mat-icon>cancel</mat-icon>
                </button>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!isReadonly" [colspan]="3">
              <div *ngIf="!isReadonly" class="w-100 text-right">
                <button (click)="removeLabelStructure(i)" class="mat-elevation-z1 small" color="warn" mat-mini-fab
                        style="margin-right: 14px">
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>
            </mat-grid-tile>
          </form>
        </ng-container>
        <mat-grid-tile *ngIf="!isReadonly" [colspan]="24">
          <button (click)="addLabelStructure()" class="rounded-pill small" color="primary" mat-flat-button type="button">
            <mat-icon>add</mat-icon>
            Add Label
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
  