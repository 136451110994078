import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-add-tenant',
  templateUrl: './add-tenant.component.html',
  styleUrls: ['./add-tenant.component.scss']
})
export class AddTenantComponent implements OnInit {

  tenantForm: UntypedFormGroup;
  disabled = false;
  errorMessage: string;

  constructor(private tenantService: TenantService,
    public dialogRef: MatDialogRef<AddTenantComponent>) { }

  ngOnInit(): void {
    this.tenantForm = new UntypedFormGroup({
      tenantName: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(18),
      ])
    });
  }

  addTenant() {
    if (this.tenantForm.valid) {

      // reset the status message
      this.resetErrorMessage();

      // disable the form and button
      this.disabled = true;
      this.tenantForm.disable();


      const payload = {
        tenantName: `Z-${this.tenantForm.value.tenantName}`
      }

      this.tenantService.addTenant(payload).subscribe(
        (res) => {
          this.disabled = false;
          this.tenantForm.reset();

          // close the dialog
          this.dialogRef.close(true);
        },
        (err) => {
          this.errorMessage = err.error.apierror ? err.error.apierror.message : 'Please try again';
          this.disabled = false;
          this.tenantForm.enable();
        }
      );
    }
  }

  resetErrorMessage() {
    this.errorMessage = undefined;
  }

}
