<mat-sidenav-container>
  <mat-sidenav mode="side" opened>
    <div class="overflow-hidden">
      <mat-nav-list class="pt-0">
        <a *ngFor="let menuItem of menuItems" [routerLink]="menuItem.path" mat-list-item routerLinkActive="active" [hidden]="!admin && !superAdmin">
          <mat-icon matListIcon>{{ menuItem.icon }}</mat-icon>
          <p matLine>{{ menuItem.label }}</p>
        </a>
      </mat-nav-list>
    </div>
    <div style="bottom: 8px; position:absolute; left: 30%; font-size: 10px; color: lightgrey">Build No. {{vers}}</div>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
