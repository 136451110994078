import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  vers: string = environment.vers.version;
  admin: boolean = this.authService.getFromCookie("admin") == "true";
  superAdmin: boolean = this.authService.getFromCookie("superAdmin") == "true";
  menuItems = [
    {
      path: '/dashboard/tenants',
      icon: 'games_outlined',
      label: 'Tenant'
    },
    {
      path: '/business-units',
      icon: 'business',
      label: 'Business Units',
    },
    {
      path: '/sites',
      icon: 'store',
      label: 'Manage - Site',
    },
    {
      path: '/devices',
      icon: 'devices',
      label: 'Devices',
    },
  ];

  constructor(private authService: AuthService) {
    if (this.admin) {
      this.menuItems.splice(1,3);
    }
  }

  ngOnInit(): void {
  }

}
