import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-verify',
  templateUrl: './auth-verify.component.html',
  styleUrls: ['./auth-verify.component.scss']
})
export class AuthVerifyComponent implements OnInit,OnDestroy {

  token: string;
  UUID: string;
  userid: string;
  tenantId: string;
  subscription: Subscription;

  constructor(private authService: AuthService, private router: Router) {

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.UUID = this.getParameterByName('uuid', event.url);
        this.userid = this.getParameterByName('userid', event.url);
        this.tenantId = this.getParameterByName('tenant', event.url);
        this.authService.saveInCookie("userId", this.userid);

        if (!this.UUID || !this.tenantId || !this.userid) {
          // fetch token and tenant id from cookie
          this.token = this.authService.getTokenFromCookie();
          this.tenantId = this.authService.getTenantIdFromCookie();

          // if token or tenantid is not present in cookie
          // then navigate user to sso login
          if (!this.token || !this.tenantId) {
               this.loginSSO();
          } else {
            let notRegUser = this.authService.getFromCookie("superAdmin") || this.authService.getFromCookie("admin") == "true";
            let email = this.authService.getFromCookie("email");
            let siqId = this.authService.getFromCookie("siqId");

            if (!notRegUser) {
              this.router.navigate([`/tenants/${siqId}/users/${email}/view`]);
            } else {
              this.router.navigateByUrl('/dashboard');
            }
          }
        } else {
          const url = `/auth-process/${this.userid}/${this.tenantId}/${this.UUID}`;

          this.router.navigateByUrl(url);
        }
      }
    })
  }

  ngOnInit(): void {
  }

  loginSSO() {
    this.authService.loginSSO();
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  ngOnDestroy() {
    if(this.subscription) {
       this.subscription.unsubscribe();
    }
  }

}
