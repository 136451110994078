import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { FormPart } from '../../tenants/create-tenant/create-tenant.component';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
  viewProviders: [FormPart]
})
export class UserRolesComponent implements OnInit {

  applicationNamesArray = ['shrinkanalyzer'];
  userRolesArray = ['REPORT_VIEWER', 'REPORT_EDITOR'];
  @Input() isReadOnly;
  @Input() isCreateMode;
  @Input() claimsFromResponse;

  
  constructor(private fb: UntypedFormBuilder, private readonly formGroupDirective: FormGroupDirective) {
  }

  ngOnInit(): void {
    if (!this.isReadOnly && !this.userRoles.length) {
      this.addUserRole();
    }
  }

  get userRoles(): UntypedFormArray {
    return (this.formGroupDirective.form?.get('userRoles') as UntypedFormArray) ?? this.fb.array([]);
  }

  getRolesFromApps(claimObject) : any{
    const extractedRoles = [];
    claimObject?.apps?.forEach((appObject: string, index) => {
      extractedRoles.push(claimObject?.[appObject]?.roles?.[0])
    });    
    return extractedRoles;
  }

  addUserRole() {
    let role = this.fb.group(
      {
        applicationName: ['', Validators.required],
        userRole: ['', Validators.required],
      },
    );
    this.userRoles.push(role);
  }
}
