<mat-card>
  <mat-card-content *ngIf="form" [formGroup]="form">
    <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label *ngIf="!readOnly">Tenant ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Tenant ID</mat-label>
            <mat-icon
              (click)="showToast('Tenant ID')"
              *ngIf="true"
              matRipple
              matRippleColor="white"
              matSuffix
              [cdkCopyToClipboard]="this.form.get('tenantId').value"
              >content_copy</mat-icon
            >
          </span>
          <input
            matInput
            type="text"
            placeholder="Enter Tenant ID"
            formControlName="tenantId"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label *ngIf="!readOnly">Business unit ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Business unit ID</mat-label>
            <mat-icon
              (click)="showToast('Business unit ID')"
              *ngIf="true"
              matRipple
              matRippleColor="white"
              matSuffix
              [cdkCopyToClipboard]="this.form.get('buId').value"
              >content_copy</mat-icon
            >
          </span>

          <input
            matInput
            type="text"
            placeholder="Enter Business unit ID"
            formControlName="buId"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
        <mat-label *ngIf="!readOnly">Site ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Site ID</mat-label>
            <mat-icon
              (click)="showToast('Site ID')"
              *ngIf="true"
              matRipple
              matRippleColor="white"
              matSuffix
              [cdkCopyToClipboard]="this.form.get('siteId').value"
              >content_copy</mat-icon
            >
          </span>
          <input
            type="text"
            placeholder="Enter Site ID"
            matInput
            formControlName="siteId"
            required
            [readonly]="readOnly"
            [matAutocomplete]="auto"
            (keyup)="searchSite(value.value)"
            #value
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="siteChange.emit($event)">
            <mat-option
              *ngFor="let option of filteredSites"
              [value]="option"
            >
              {{ option.id }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label *ngIf="!readOnly">Site ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Site ID</mat-label>
            <mat-icon
              (click)="showToast('Site ID')"
              *ngIf="true"
              matRipple
              matRippleColor="white"
              matSuffix
              [cdkCopyToClipboard]="this.form.get('siteId').value"
              >content_copy</mat-icon
            >
          </span>
          <input
            matInput
            type="text"
            placeholder="Enter Site ID"
            formControlName="siteId"
            required
            [readonly]="readOnly"
          />
        </mat-form-field> -->
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Customer Site ID</mat-label>
          <input
            matInput
            type="text"
            placeholder="Enter Customer Site ID"
            formControlName="customerSiteId"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Floor Name</mat-label>
          <mat-select
            [placeholder]="readOnly ? '' : 'Select Floor'"
            formControlName="floorId"
            [disabled]="readOnly"
            (selectionChange)="floorChange.emit($event)"
          >
            <mat-option *ngFor="let data of floors" [value]="data.id">
              {{ data.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Zone Name</mat-label>
          <mat-select
            [placeholder]="readOnly ? '' : 'Select Zone'"
            formControlName="zoneId"
            [disabled]="readOnly"
            (selectionChange)="zoneChange.emit($event)"
          >
            <mat-option *ngFor="let data of zones" [value]="data.id">
              {{ data.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" *ngIf="parentId">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Parent Device ID</mat-label>
          <input
            matInput
            type="text"
            placeholder="Enter Parent Device ID"
            formControlName="parentId"
            required
            [readonly]="readOnly"
          />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
