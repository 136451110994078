<h2 mat-dialog-title align="center">Address Validation</h2>
<br>
<div *ngIf="unsupportedCountry" style="text-align:center" >Country is unsupported.</div>
<mat-dialog-content class="md-padding">
    <ul *ngIf="missingValues">
        <li *ngFor="let value of this.data.result.address.missingComponentTypes"><b>{{getMapping(value)}}</b> could be missing.</li>
    </ul>
    <ul *ngIf="unconfirmedValues">
        <li *ngFor="let value of this.data.result.address.unconfirmedComponentTypes" [id]="value"><b>{{getMapping(value)}}</b> could not be
            verified.</li>
    </ul>
    <div *ngIf="replacedComponents">
        <ng-container *ngFor="let each of this.data.result.address.addressComponents">
            <li *ngIf="each?.replaced || each?.spellCorrected"><b>{{getMapping(each.componentType)}}</b> could not be
                verified. Suggested Value: <b>{{each.componentName.text}}</b></li>
        </ng-container>
    </div>
    <br>
    <p>Do you want to edit the address or override the validation?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button class="rounded-pill small mr-4 border border-primary" color="primary" (click)="verify()">Edit Address</button>
    <button mat-raised-button class="rounded-pill small mr-4 border border-primary"
        (click)="override()">Override Address Validation</button>
</mat-dialog-actions>
