<div class="page-container">
    <span class="page-title">SITE</span>
    <span class="button">
      <button class="rounded-pill small" color="primary" mat-flat-button (click)="onClickAdd()" [disabled]="!superAdmin">
        <mat-icon>add</mat-icon>ADD SITE
      </button>
    </span>
    <span class="button">
      <button class="rounded-pill small" color="primary" mat-flat-button (click)="gotoUploads()">
        <mat-icon>upload</mat-icon>UPLOAD DATA
      </button>
    </span>
    <mat-card class="mt-4">
      <mat-card-content>
        <table [dataSource]="dataSource" class="w-100 custom-table" mat-table matSort matSortActive="createdAt"
          matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>SITE NAME</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>SITE ID</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>CREATED DATE</th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'MM/dd/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>STATUS</th>
            <td mat-cell *matCellDef="let element">{{element.isActive ? 'ACTIVE' : 'INACTIVE'}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">ACTION</th>
            <td mat-cell *matCellDef="let element">
              <div class="w-100 text-center">
                <button (click)="viewBusSite(element)" class="mat-elevation-z1 small mr-2" color="primary" mat-mini-fab>
                  <mat-icon>visibility</mat-icon>
                </button>
                <button (click)="editBusSite(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
              No Sites found
            </td>
          </tr>
        </table>
        <mat-paginator #paginator [pageSize]="20" appCustomPaginator aria-label="Select page">
        </mat-paginator>

      </mat-card-content>
    </mat-card>

  </div>
