import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import { SiteService } from 'src/app/services/site.service';
import { DeviceService } from "../../../services/devices.service";
import {ToasterService} from '../../../services/toaster.service';
import { DeviceDataSource } from '../../../misc/deviceDataSource';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-site-device',
  templateUrl: './site-device.component.html',
  styleUrls: ['./site-device.component.scss']
})
export class SiteDeviceComponent implements OnInit, AfterViewInit {

  @Input()
  private tenantId: string;

  @Input()
  private buId: string;

  @Input()
  private siteId: string;

  @Input()
  parentDeviceId?: string;

  loading = false;
  displayedColumns: string[];
  dataSource: DeviceDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private siteService: SiteService, private toaster: ToasterService, private deviceService: DeviceService) {
    this.dataSource = new DeviceDataSource(deviceService);
   }

  ngOnInit(): void {
    this.displayedColumns = ['deviceId', 'deviceName', 'parentId', 'model', 'manufacturer', 'status', 'zoneName', 'floorName', 'action'];
    if(this.parentDeviceId) {
      this.displayedColumns = ['deviceId', 'deviceName', 'model', 'manufacturer', 'status', 'zoneName', 'floorName', 'action'];
    }
  }

  ngAfterViewInit(): void {
    let child = false;
    let id = this.siteId
    if(this.parentDeviceId) {
      child = true
      id = this.parentDeviceId
    }
    this.dataSource.init(this.paginator, this.sort, {child: child, id: id});
  }



  getDevicesBySiteId(){
    this.deviceService.getAllDevicesBySite(this.siteId).subscribe(
      (res:any)=>{
        this.dataSource=res.devices;    
      },
      (err) => {
        this.toaster.error('Please try again');
      }
    )
  }

  getDevicesByParentDeviceId() {
    this.deviceService.getAllDevicesByParentDevice(this.parentDeviceId).subscribe(
      (res:any)=>{
        this.dataSource=res.devices;    
      },
      (err) => {
        // this.toaster.error('Please try again');
      }
    )
  }

  editDevice(device: any): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/devices/${device.uuid}/edit`]);
  }

  viewDevice(device: any): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/devices/${device.uuid}/view`]);
  }

}
