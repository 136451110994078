import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { ZoneService } from '../../../services/zone.service';

@Component({
    selector: 'app-view-child-zones',
    templateUrl: './child-zone.component.html',
    styleUrls: ['./child-zone.component.scss']
})
export class ViewChildZonesComponent implements OnInit {

    @Input()
    private floorId: string;

    @Input()
    private tenantId: string;

    @Input()
    private buId: string;

    @Input()
    private siteId: string;

    @Input()
    private parentZoneId: string;

    loading = false;
    displayedColumns: string[] = ['name', 'id', 'createdAt', 'isActive', 'action'];
    dataSource = [];

    constructor(private router: Router, private zoneService: ZoneService, private toaster: ToasterService) { }

    ngOnInit(): void {
        this.getZonesByparentZoneId();
    }
    getZonesByparentZoneId() {
        this.zoneService.findChildZonesByZoneId(this.parentZoneId, this.siteId).subscribe(
            (res: any) => {
                this.dataSource = res.zones;
            }
        )
    }

    viewZone(zone) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${zone.id}/view`]);
    }

    editZone(zone) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${zone.id}/edit`]);
    }
}
