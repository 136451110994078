import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AddOrgComponent } from '../add-org/add-org.component';

@Component({
  selector: 'app-manage-tenant',
  templateUrl: './manage-tenant.component.html',
  styleUrls: ['./manage-tenant.component.scss']
})
export class ManageTenantComponent implements OnInit {
  tenantName:string;
  tenantId: string;
  orgs: any = [];

  gridApi;
  gridColumnApi;
  paginationPageSize = 20;
  rowSelection = 'single';

  columnDefs = [
    { field: 'buOrgId', headerName: 'org id/bus. unit id', sortable: true },
    {
      field: 'created',
      headerName: 'created date',
      type: 'dateColumn',
      valueFormatter: function (params) {
        if(!params.value) {
          return '-';
        }
        return moment(params.value).format('DD-MMM-YYYY');
      },
      sortable: true,
      unSortIcon: true,
      sortingOrder:  ['asc', 'desc']
    },
  ];

  overlayLoadingTemplate = `<div class="loader-container">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <h1>Loading...</h1>
      </div>`;

  rowData = [];

  constructor(private route: ActivatedRoute, 
    private tenantService: TenantService,
    private dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
  
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    const sortModel = [
      {colId: 'created', sort: 'desc'}
    ];
    this.gridApi.setSortModel(sortModel);
    this.fetchOrgs();
  }

  fetchOrgs() {
    this.tenantName = this.route.snapshot.queryParamMap.get('tenantName');
    this.tenantId = this.route.snapshot.paramMap.get('id')

    if ( this.tenantId ) {
      // show grid overlay
    this.gridApi.showLoadingOverlay();
      this.tenantService.getOrgForTenant(this.tenantId).subscribe(
        (res: any) => {
          // hide grid overlay
          this.gridApi.hideOverlay();
          this.orgs = res.result;
          this.rowData = this.orgs;
          setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
          }, 0);
        }
      );
    } else {
      this.router.navigateByUrl('/dashboard/tenants');
    }
  }

  addNewOrg() {
    const dialogRef = this.dialog.open(AddOrgComponent, 
      { data: 
        {tenantName: this.tenantName, tenantId: this.tenantId} });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchOrgs();
      }
    });
  }
}
