<form [formGroup]="form">
    <mat-card class="password-form">
    <mat-card-content>
        <ng-container>
        <mat-grid-list cols="24" gutterSize="16px" rowHeight="90px">
        <mat-grid-tile [colspan]="5">
            <mat-form-field floatLabel="always">
                <mat-label>Password</mat-label>
                <input
                matInput
                type="password"
                name="password"
                [required]="isCreateMode"
                placeholder="Password"
                formControlName="password"
                (keyup)="onKeyUp($event, 'password')"
              />
              <mat-error *ngIf="form.controls['password'].hasError('required') && (form.controls['password'].dirty || form.controls['password'].touched) && !isReadOnly">
                Password is required!
              </mat-error>
              <mat-error *ngIf="form.controls['password'].hasError('minlength')">
                Password should be longer than 8 characters!
              </mat-error>
              <mat-error *ngIf="form.controls['password'].hasError('maxlength')">
                Password can't be longer than 100 characters!
              </mat-error>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" class="header">&nbsp;</mat-grid-tile>
        <mat-grid-tile [colspan]="5">
            <mat-form-field floatLabel="always">
                <mat-label>Confirm Password</mat-label>
                <input
                matInput
                name="confirmPassword"
                [required]="isCreateMode"
                placeholder="Confirm password"
                type="password"
                formControlName="confirmPassword"
                (keyup)="onKeyUp($event, 'confirmPassword')"
              />
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('required') && (form.controls['confirmPassword'].dirty || form.controls['confirmPassword'].touched) && !isReadOnly">
                Password confirmation is required!
              </mat-error>
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('minlength')">
                Password should be longer than 8 characters!
              </mat-error>
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('maxlength')">
                Password can't be longer than 100 characters!
              </mat-error>
              <mat-error *ngIf="form.controls['confirmPassword'].hasError('not_matching')">
                The password doesn't match!
              </mat-error>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="10" class="header">&nbsp;</mat-grid-tile>
        </mat-grid-list>
        </ng-container>
    </mat-card-content>
</mat-card>
</form>
