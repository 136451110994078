<mat-card>
  <mat-card-content class="px-5 py-3">
    <div class="d-flex">
      <mat-form-field class="w-100 small" floatLabel="never">
        <input hidden #csvUploader (click)="csvUploader.value=null" type="file"  (change)="fileSelected($event)" accept=".csv"/>
        <input
          [value]="fileName"
          matInput
          placeholder="{{ placeholder }}"
          disabled="true"
        />
        <button
          *ngIf="fileName"
          mat-icon-button
          matSuffix
          (click)="clear()"
          aria-label="Clear search"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <div class="my-auto ml-4">
        <button (click)="csvUploader.click()" class="rounded-pill small" color="primary" mat-flat-button>
          BROWSE CSV FILE
        </button>
      </div>
      <div class="my-auto ml-4">
        <button (click)="uploadCSV()" [disabled]="!selectedFile" class="rounded-pill small" color="primary" mat-flat-button>
          UPLOAD
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
