import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { REST_ENDPOINT } from '../app.constants';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  snackbar= new BehaviorSubject(false);
  snackbarTenantId=new BehaviorSubject(' ')
  domain = environment.userManagementUrl;
  siqLocationUrl = environment.siqLocationUrl;
  apigeeLocationUrl = environment.apigeeLocationUrl;

  constructor(private httpCient: HttpClient, private authService: AuthService) { }

  findAllTenant(page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.tenants.get}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpCient.get(url, this.authService.getAuthHttpOptions());
  }

  searchTenant(search: string, page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.tenants.search}?search=${search}&page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpCient.get(url, this.authService.getAuthHttpOptions());
  }

  addTenant(payload: { tenantName: string }): Observable<any> {
    const url = `${this.domain}${REST_ENDPOINT.tenants.add}`;

    return this.httpCient.post(url, payload, this.authService.getAuthHttpOptions());
  }

  createTenant(payload): Observable<any> {
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.createTenants.add}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (payload.location[objName]) {
        delete payload.location[objName];
      }
    });

    return this.httpCient.post(url, payload,this.authService.getAuthHttpOptions());
  }

  getOrgForTenant(tenantId: string) {
    const url = `${this.domain}/v1/api/tenant/${tenantId}/buorgs`;
    return this.httpCient.get(url, this.authService.getAuthHttpOptions());
  }

  addOrgForTenant({buOrgId, tenantName, tenantId}) {
    const url = `${this.domain}${REST_ENDPOINT.orgs.add}`;
    return this.httpCient.post(url, { buOrgId, tenantName, tenantId }, this.authService.getAuthHttpOptions());
  }

  getMarketSegments(): Observable<string[]> {
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.marketSegments.get}`;
    return this.httpCient.get<string[]>(url, this.authService.getAuthHttpOptions());
  }

  getTenant(tenantId:string){
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.tenant.get}${tenantId}`
    return this.httpCient.get(url,this.authService.getAuthHttpOptions());
  }

  updateTenant(payload):Observable<any>{
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.createTenants.update}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (payload.location[objName]) {
        delete payload.location[objName];
      }
    });

    return this.httpCient.post(url, payload,this.authService.getAuthHttpOptions());
  }

  getTenantbySSOtenantId(tenantId) {
    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.tenants.ssoId}${tenantId}`
    return this.httpCient.get(url,this.authService.getAuthHttpOptions());
  }
}
