import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomDataSource, Page} from './CustomDataSource';
import { SiteService } from '../services/site.service';

export class UploadHistoryDataSource extends CustomDataSource<History> {

  constructor(private siteService: SiteService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string, options: any): Observable<Page<History>> {
    let observable: Observable<any>;
    observable = this.siteService.getUploadHistory(options.buId, page, size, sortField, sortDir);
    return observable.pipe(map(res => ({data: res.csvFiles, total: res.total})));
  }
}

export interface History {
  id: string;
  name: string;
  status: string;
  totalCount: number;
  successCount: number;
  failCount: number;
  warningCount: number;
  createdAt: Date;
  updatedAt: Date;
}
