import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import { Device, SearchDeviceDataSource } from '../../../misc/SearchDevicesDataSource';
import { DeviceService } from '../../../services/devices.service';

@Component({
  selector: 'app-devices-overview',
  templateUrl: './devices-overview.component.html',
  styleUrls: ['./devices-overview.component.scss']
})
export class DevicesOverviewComponent implements OnInit, AfterViewInit {

  displayedColumns: string[];
  dataSource: SearchDeviceDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private deviceService: DeviceService) {
    this.dataSource = new SearchDeviceDataSource(deviceService);
  }

  ngOnInit(): void {
    this.displayedColumns = ['deviceId', 'deviceName', 'model', 'manufacturer', 'status', 'siteName', 'action'];
  }

  ngAfterViewInit(): void {
    let options = {
      filter: false
    }
    this.dataSource.init(this.paginator, this.sort, options);
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }

  onFilter(filter: boolean | Event) : void {
    if (typeof filter === 'boolean') {
      this.dataSource.deviceFilter = filter;
    } else {
      // @ts-ignore
      this.dataSource.deviceFilter = filter?.target?.value ?? false;
    }
  }

  editSite(device: Device): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/devices/${device.uuid}/edit`]);
    // this.router.navigate([`tenants/${site.tenantId}/business-units/${site.businessUnitId}/sites/${site.id}/edit`]);
  }

  viewSite(device: Device): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/devices/${device.uuid}/view`]);
    // this.router.navigate([`tenants/${site.tenantId}/business-units/${site.businessUnitId}/sites/${site.id}/view`]);
  }

}
