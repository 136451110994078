import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userInfo: any = {};
  currentUrl='';
  admin: boolean = this.authService.getFromCookie("admin") == "true";
  superAdmin: boolean = this.authService.getFromCookie("superAdmin") == "true";

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {

    this.currentUrl = this.router.url;

    this.authService.fetchUserInformation().subscribe(
      (res) => {
        this.authService.saveInCookie('uinfo', JSON.stringify(res));
        this.userInfo = JSON.parse(this.authService.getFromCookie('uinfo'));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  logout() {
    this.authService.logout();
  }
}
