import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { REST_ENDPOINT } from '../app.constants';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  domain = environment.userManagementUrl;
  constructor(private httpClient: HttpClient, private authService: AuthService) { }


  fetchSuperAdmin(): Observable<any> {
    const url = `${this.domain}${REST_ENDPOINT.superAdmins.get}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  createSuperAdmin(payload): Observable<any> {
    const url = `${this.domain}${REST_ENDPOINT.superAdmins.create}`;
    return this.httpClient.post(url, payload, this.authService.getAuthHttpOptions());
  }
}
