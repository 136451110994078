import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  public message = '';
  public type: 'success' | 'info' | 'warning' | 'error' = 'info';
  public onDismiss = () => {
    console.log('asd');
  }

  get icon(): string {
    return {
      success: 'check_circle',
      info: 'info',
      warning: 'warning',
      error: 'cancel'
    }[this.type];
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
