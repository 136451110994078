<div *ngIf="loading" class="loader-container">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <h1>Loading Device Information...</h1>
  </div>

<div *ngIf="!loading" class="page-container">
    <div class="d-flex info-header">
      <div>
        <button class="rounded-pill small" color="primary" mat-flat-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
          DEVICE HOME
        </button>
      </div>
      <div class="ml-3 my-auto">
        <span>TENANT ID:&nbsp;<strong>{{tenantId}}</strong></span>
        <span>&nbsp;|&nbsp;BU ID:&nbsp;<strong>{{buId}}</strong></span>
        <span *ngIf="siteId">&nbsp;|&nbsp;SITE ID:&nbsp;<strong>{{siteId}}</strong></span>
      </div>
    </div>
    <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex" class="card-tabs" [class.hide-tabs]="createMode">
      <mat-tab label="Device Info">
        <div class="loader" *ngIf="localLoading">
          <app-loader [noText]="true"></app-loader>
        </div>
        <form [formGroup]="form" (ngSubmit)="saveDevice()" class="mb-4" [class.read-only]="viewMode">
          <div class="text-right mt-4">
            <ng-container *ngIf="!viewMode">
              <button (click)="cancel()" class="rounded-pill small mr-4 border border-primary" color="primary" mat-button
                      type="button">
                CANCEL
              </button>
              <button class="rounded-pill small" color="primary" mat-flat-button type="submit">
                {{uuid ? 'UPDATE' : 'CREATE'}}
              </button>
            </ng-container>
            <button (click)="toggleUpdate()" *ngIf="viewMode" class="rounded-pill small" color="primary" mat-flat-button
                    type="button">
              UPDATE DEVICE
            </button>
          </div>
          <section>
            <h2>General Information</h2>
            <app-device-general-info [isCreateMode]="createMode" (deviceTypeChange)="populateDeviceDetails($event)" [manufacturers]="manufacturers" [models]="models" [category]="category" [readOnly]="viewMode"></app-device-general-info>
          </section>
          <section>
            <h2>Parent Information</h2>
            <app-device-parent-info (floorChange)="getZonesByFloorId($event)" (zoneChange)="getSelectedZone($event)" 
            (siteChange)="onSiteSelected($event)" [filteredSites]="filteredSites" [searchSite]="searchSite"
            [zones]="zoneDetails" [floors]="floorDetails" [parentId]="!createMode ? rawData.parentId : parentId" [readOnly]="viewMode"></app-device-parent-info>
          </section>
          <section>
            <h2>Keys</h2>
            <app-name-value-pairs [readOnly]="viewMode" field="keys" addLabel="Add Key" 
                                  [options]="keys">
            </app-name-value-pairs>
          </section>
        </form>
      </mat-tab>
      <mat-tab *ngIf="!createMode" label="Child Devices">
        <section>
          <div class="d-flex mt-5">
            <h2>Child Devices</h2>
            <div class="ml-auto">
              <button *ngIf="!uuid" class="rounded-pill small" color="primary" mat-flat-button (click)="addDevices()">
                <mat-icon>add</mat-icon>
                ADD DEVICE
              </button>
            </div>
          </div>
          <app-site-device [siteId]="siteId" [buId]="buId" [tenantId]="tenantId" [parentDeviceId]="uuid"></app-site-device>
        </section>
      </mat-tab>
    </mat-tab-group>
  </div>