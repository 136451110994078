import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/services/auth.service';
import { REST_ENDPOINT } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class KeysService {
  siqLocationUrl = environment.siqLocationUrl;
  apigeeLocationUrl = environment.apigeeLocationUrl;
  keys = new BehaviorSubject(null)

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getKeys(): Observable<string[]> {

    const url = `${this.apigeeLocationUrl}${REST_ENDPOINT.keys.get}`
    return this.httpClient.get<string[]>(url, this.authService.getAuthHttpOptions());
  }
}
