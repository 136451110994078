import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ToastComponent} from '../components/common/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) {
  }

  show(message: string, type: 'success' | 'info' | 'warning' | 'error'): void {
    const component = this.snackBar.openFromComponent(ToastComponent, {
      panelClass: ['bg-white', 'p-3']
    });
    component.instance.message = message;
    component.instance.type = type;
    component.instance.onDismiss = () => component.dismiss();
  }

  info(message: string): void {
    this.show(message, 'info');
  }

  error(message: string): void {
    this.show(message, 'error');
  }

  warning(message: string): void {
    this.show(message, 'warning');
  }

  success(message: string): void {
    this.show(message, 'success');
  }
}
