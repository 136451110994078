import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { REST_ENDPOINT } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class FloorService {
  private baseUrl = environment.apigeeLocationUrl;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  createFloor(floor): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.floors.create}`;
    const options = this.authService.getAuthHttpOptions();
    return this.httpClient.post(url, floor, options);
  }

  findById(floorId, siteId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.floors.findById}`;
    let params = new HttpParams();
    params = params.append('siteId', siteId);
    params = params.append('floorId', floorId);
    const options = {...this.authService.getAuthHttpOptions(), params: params}
    return this.httpClient.get(url, options);
  }

  findBySiteId(siteId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.floors.findBySiteId}`;
    let params = new HttpParams();
    params = params.append('siteId', siteId);
    const options = {...this.authService.getAuthHttpOptions(), params: params}
    return this.httpClient.get(url, options);
  }

  loadKeys(): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.floors.findKeys}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  updateFloor(floor): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.floors.update}`;
    const options = this.authService.getAuthHttpOptions();
    return this.httpClient.post(url, floor, options);
  }

}