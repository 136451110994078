<div class="container">
    <div class="section-header">
      <div class="header">  
        <h2 class="primary-heading">Manage Organizations / Business Units</h2>
        <h3 class="sub-heading">Tenant Name: 
          <a [routerLink]="['../']"  >{{tenantName}}</a>
        </h3>
      </div>
      <div class="button-box">
        <button mat-raised-button color="primary"
        (click)="addNewOrg()">add new Organization
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  
    <div class="org-grid">
      <ag-grid-angular class="ag-theme-material" [rowData]="rowData" [columnDefs]="columnDefs" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [overlayLoadingTemplate]="overlayLoadingTemplate"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>