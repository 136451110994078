import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/services/auth.service";
import { Observable } from "rxjs";
import { REST_ENDPOINT } from "../app.constants";


@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private locationBaseUrl = environment.apigeeLocationUrl;
    private deviceBaseURL = environment.apigeeDeviceURL;

    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }

    loadKeys(): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.keys}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }

    findBySiteId(siteId): Observable<any> {
        const url = `${this.locationBaseUrl}${REST_ENDPOINT.floors.findBySiteId}`;
        let params = new HttpParams();
        params = params.append('siteId', siteId);
        const options = { ...this.authService.getAuthHttpOptions(), params: params }
        return this.httpClient.get(url, options);
    }

    findZonesByFloorId(floorId, siteId): Observable<any> {
        const url = `${this.locationBaseUrl}${REST_ENDPOINT.zones.findByFloorId}`;
        let params = new HttpParams();
        params = params.append('siteId', siteId);
        params = params.append('floorId', floorId);
        const options = { ...this.authService.getAuthHttpOptions(), params: params }
        return this.httpClient.get(url, options);
    }

    getDeviceByID(deviceId): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.get}/${deviceId}`;
        const options = { ...this.authService.getAuthHttpOptions() }
        return this.httpClient.get(url, options);
    }

    createDevice(deviceDetails): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.create}`;
        const options = this.authService.getAuthHttpOptions();
        return this.httpClient.post(url, deviceDetails, options);
    }

    updateDevice(deviceDetails): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.update}`;
        const options = this.authService.getAuthHttpOptions();
        return this.httpClient.post(url, deviceDetails, options);
    }

    getDeviceTypes(): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.getDeviceTypes}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }

    getAllDevicesBySite(siteId: string, page: number = 0, size: number = 20, sort: string = 'dtCreated', sortDir: string = 'desc'): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.getAllDevicesbySite}${siteId}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }
    
    getAllDevicesByParentDevice(parentDeviceId: string, page: number = 0, size: number = 20, sort: string = 'dtCreated', sortDir: string = 'desc'): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.getAllChildDevices}${parentDeviceId}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }

    getAllDevices(page: number = 0, size: number = 20, sort: string = 'dtCreated', sortDir: string = 'desc'): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.getAllDevices}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }

    searchDevices(search: string, page: number = 0, size: number = 20, sort: string = 'dtCreated', sortDir: string = 'desc'): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.search}?search=${search}&page=${page}&size=${size}&sort=${sort},${sortDir}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
      }
    
    filterDevicesWithoutSites(page: number = 0, size: number = 20, sort: string = 'dtCreated', sortDir: string = 'desc'): Observable<any> {
        const url = `${this.deviceBaseURL}${REST_ENDPOINT.devices.filter}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
        return this.httpClient.get(url, this.authService.getAuthHttpOptions());
    }
}