import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Provider } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { TenantService } from 'src/app/services/tenant.service';
import { Router } from '@angular/router';
import { LocationInformationComponent } from '../../common/location-information/location-information.component';
import { ArrayValidators } from 'src/app/misc/CustomValidators';
import {ToasterService} from '../../../services/toaster.service';
import { dateValidation } from 'src/app/validators/date-field.validators';
import { formatDate } from '@angular/common';
import {AuthService} from '../../../auth/services/auth.service';
import {BusinessUnitService} from '../../../services/business-unit.service';

export const FormPart: Provider = {
  provide: ControlContainer,
  useExisting: FormGroupDirective,
};
@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss']
})
export class CreateTenantComponent implements OnInit {
  tenantForm: UntypedFormGroup;
  disabled: boolean;
  errorMessage: any;
  @Input() tenantValue;
  @Input() isReadonly;
  @Input() viewPage;
  validInformation: Boolean = false;
  @ViewChild(LocationInformationComponent) locationInfoChild: LocationInformationComponent;
  admin: Boolean = this.authService.getFromCookie("admin") == "true";

  get isCreateMode(): boolean {
    return !(this.tenantValue)
  }

  constructor(private _fb: UntypedFormBuilder, private tenantService: TenantService, private router: Router, private toaster: ToasterService,
              private authService: AuthService, private businessUnitService: BusinessUnitService) {
    this.inItForm();
  }

  inItForm() {
    this.tenantForm = this._fb.group({
      name: ["", [Validators.required]],
      type: ["", [Validators.required]],
      serviceMaxAccount: this._fb.group({
        id: ["",[Validators.pattern('^[a-zA-Z0-9]*$')]]
      }),
      legalName: ["", [Validators.required]],
      dunsNumber: ["",[Validators.pattern('^[a-zA-Z0-9]*$')]],
      isActive: ["", [Validators.required]],
      displayName: ["", [Validators.required]],
      keys: this._fb.array([]),
      marketSegment: this._fb.array([], [ArrayValidators.minLength(1)]),
      subscriptions: this._fb.array([])
    })
  }
  ngOnInit(): void {
    if(!this.isReadonly) {
      this.tenantForm.get('isActive').setValue(true);
    }
    if (this.tenantValue != null) {
      this.viewEditTenant();
    }
  }

  onSubmit() {
    if(this.tenantForm.invalid){
      this.tenantForm.markAllAsTouched();
      this.toaster.error('Form contains some errors')
      return;
    }

    const payload = this.tenantForm.value

    const tenantNamePrefix = "Z-" + this.tenantForm.value.name
    let name = {
      name: tenantNamePrefix
    }
    const results = this.tenantForm.value.keys.filter(element => {
      if (Object.keys(element.name)?.length !== 0) {
        return true;
      }
      return false;
    });
    let keys = {
      keys: results
    }
    if(payload.subscriptions.length)
      payload.subscriptions.forEach(element => {
        element.activationDate = formatDate(element.activationDate, "yyyy-MM-dd", "en-us");
        element.deactivationDate = element.deactivationDate? formatDate(element.deactivationDate, "yyyy-MM-dd", "en-us") : element.deactivationDate;
      });
    let forCreate = Object.assign(payload, keys, name);
    if (this.validInformation) {
      this.tenantService.createTenant(forCreate).subscribe(
        (res) => {
          this.disabled = false;
          this.tenantForm.reset();
          this.tenantService.snackbar.next(true);
          this.tenantService.snackbarTenantId.next(res.id)
          this.router.navigate([`dashboard/tenants`]);
        },
        (err) => {
          this.errorMessage = err.error.apierror ? err.error.apierror.message : 'Please try again';
          this.disabled = false;
          if (err.error.code == '400' || err.status == '400') {
            this.toaster.error(err.error.message);
          }
          else {
            this.toaster.error('Please try again');
          }
        }
      );
    }
    else {
      this.toaster.error('Please validate the address');
    }
  }
  onclickCancle() {
    // this.tenantForm.reset();
    this.router.navigate([`dashboard/tenants`]);
  }

  onclickCancleForUpdate() {
    this.router.navigate([`dashboard/view/${this.tenantValue.id}`])
  }

  viewEditTenant() {
    if (this.tenantValue != null) {
      this.viewEditKeys();
      this.businessUnitService.getSubscriptionsByParent("TENANT", this.tenantValue.id).subscribe((res: any) => {
        if (res != null) {
          this.tenantValue.subscriptions = res.subscriptions;
          this.viewEditSubscription();
          sessionStorage.setItem("existingSub", JSON.stringify(this.tenantValue.subscriptions.map(a => a.productCode)));
        }
      })
      this.tenantForm.patchValue(this.tenantValue);
      if(this.isReadonly) {
        this.tenantForm.get('isActive')?.disable();
      }
      const marketSegment = this.tenantForm.get('marketSegment') as UntypedFormArray;
      this.tenantValue.marketSegment.forEach(s => {
        return marketSegment.push(new UntypedFormControl(s));
      });
    }
    else {
      this.inItForm();
    }

  }

  viewEditKeys() {
    if (this.tenantValue != null) {
      const keys = this.tenantForm?.get('keys') as UntypedFormArray;
      for (let item of this.tenantValue.keys) {
        let dummyKey = this._fb.group({
          name: [''],
          value: ['']
        })
        dummyKey.patchValue(item)
        keys?.push(dummyKey);
      }
    }
  }

  viewEditSubscription() {
    const subscriptions = this.tenantForm?.get('subscriptions') as UntypedFormArray;
    for (let item of this.tenantValue.subscriptions) {
      let dummyKey = this._fb.group({
        productCode: [''],
        cascade: [''],
        portfolio: [''],
        activationDate: ['', [dateValidation('deactivationDate')]],
        deactivationDate: ['', [dateValidation('activationDate')]],
        _disabled: [true],
        editing: [false]
      })
      dummyKey.patchValue(item)
      subscriptions?.push(dummyKey);
    }
  }

  forLocationValidator(eventData) {
    this.validInformation = !eventData;
  }

  onClickUpdateTenant() {
    this.router.navigate([`/dashboard/update/${this.tenantValue.id}`]);
  }

  onClickUpdate() {
    if(this.tenantForm.invalid){
      this.tenantForm.markAllAsTouched();
      this.toaster.error('Form contains some errors')
      return;
    }
    let id = {
      id: this.tenantValue.id
    };
    const results = this.tenantForm.value.keys.filter(element => {
      if (Object.keys(element.name)?.length !== 0) {
        return true;
      }
      return false;
    });
    const resultsSubscription = this.tenantForm.value.subscriptions.filter(element => {
      if (Object.keys(element.productCode)?.length !== 0) {
        return true;
      }
      return false;
    });
    let keys = {
      keys: results
    }
    resultsSubscription.forEach(element => {
      element.activationDate = formatDate(element.activationDate, "yyyy-MM-dd", "en-us");
      element.deactivationDate = element.deactivationDate? formatDate(element.deactivationDate, "yyyy-MM-dd", "en-us") : element.deactivationDate;
    });
    let subscriptions = {
      subscriptions: resultsSubscription
    }
    let forUpdate = Object.assign(this.tenantForm.value, id, keys, subscriptions);
    if(this.validInformation){
    this.tenantService.updateTenant(forUpdate).subscribe(
      (res) => {
        this.toaster.success('Record updated successfully');
        this.router.navigate([`/dashboard/view/${this.tenantValue.id}`]);
      },
      (err) => {
        this.toaster.error(err?.error?.message);
      }
    );}
    else{
      this.toaster.error('Please validate the address')
    }
  }
}
