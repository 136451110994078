<mat-card>
  <mat-card-content *ngIf="form" [formGroup]="form">
    <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label>Name</mat-label>
          <input matInput type="text" placeholder="Enter Name Here" formControlName="name" required
                 [readonly]="readOnly"  maxlength="100" minlength="4"
          />
          <mat-error *ngIf="form.controls['name'].hasError('required') && !isReadonly">You must enter a site name</mat-error>
          <mat-error *ngIf="form.controls['name'].hasError('minlength') && !isReadonly">Name must be 4-100 chars</mat-error>
          <mat-error *ngIf="form.controls['name'].hasError('maxlength') && !isReadonly">Name must be 4-20 chars</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label *ngIf="!readOnly">Business Unit ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Business Unit ID</mat-label>
            <mat-icon
              (click)="showToast('Business Unit ID')"
              matRipple
              matRippleColor="white"
              [cdkCopyToClipboard]="this.form.get('businessUnitId').value"
              >content_copy</mat-icon
            >
          </span>
          <input matInput type="text" placeholder="Enter Business Unit ID" formControlName="businessUnitId"
                 readonly
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label>DUNS Number</mat-label>
          <input matInput placeholder="Enter DUNS number" formControlName="dunsNumber" maxlength="30" [readonly]="readOnly"/>
          <mat-error *ngIf="form.get('dunsNumber').hasError('pattern')">Special char. not allowed</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label>Customer Site ID</mat-label>
          <input matInput type="text" placeholder="Enter Customer Site ID" formControlName="customerSiteId" required
                 [readonly]="readOnly"
          />
          <mat-error *ngIf="form.controls['customerSiteId'].hasError('required') && !isReadonly">You must enter a customer site id</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label *ngIf="!readOnly">Tenant ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Tenant ID</mat-label>
            <mat-icon
              (click)="showToast('Tenant ID')"
              *ngIf="readOnly"
              matRipple
              matSuffix
              matRippleColor="white"
              [cdkCopyToClipboard]="this.form.get('tenantId').value"
              >content_copy</mat-icon
            >
          </span>
          <input matInput type="text" placeholder="Enter Tenant ID here" formControlName="tenantId" readonly/>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small" [class.read-only]="readOnly">
          <mat-label>Status</mat-label>
          <mat-select placeholder="Select status" formControlName="isActive" required [disabled]="readOnly">
            <mat-option [value]="true">Active</mat-option>
            <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card-content>
</mat-card>
