import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth/services/auth.service';
import {Observable} from 'rxjs';
import {REST_ENDPOINT} from '../app.constants';
import {environment} from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userManagementUrl = environment.userManagementUrl;

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  createUser(payload): Observable<any> {
    const url = `${this.userManagementUrl}${REST_ENDPOINT.user.create}`;
    return this.httpClient.post(url, payload, this.authService.getAuthHttpOptionsForUms());
  }

  findAllUsersByTenant(tenantId:String): Observable<any> {
    const url = `${this.userManagementUrl}${REST_ENDPOINT.user.getAllUsers}?tenantId=${tenantId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForUms()).pipe(
      map((response) => {
        return this.modifyGetAllUsersResponse(response["userDetails"]);
      })
    );
  }

  findUserByUserId(userId:String): Observable<any> {
    const url = `${this.userManagementUrl}${REST_ENDPOINT.user.get}?email=${userId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForUms()).pipe(
      map((response) => {
        return this.modifyResponse(response);
      })
    );
  }

  private modifyGetAllUsersResponse(responseArray: any): any {
    if(responseArray.length !== 0) {
      responseArray?.forEach((originalResponse: string, index) => {
        responseArray[index] = this.modifyResponse(originalResponse);
      });
    }
    return responseArray;
  }

  private modifyResponse(originalResponse: any): any {
    let claims:any = {
        userAccessId: "",
        siqTenantId: "",
        apps: originalResponse?.claims?.apps,
        admin: originalResponse.claims.admin
    };
    if(!this.isAppInApplications(originalResponse?.claims?.apps)) {
        return originalResponse;
    }
    originalResponse?.claims?.apps?.forEach((appObject: string, index) => {
        if(appObject === "stan") {
            let stan = {
              buOrgIds: [],
              roles: ["Managed in Application"]
            }
            claims = {...claims,
              stan: stan
            }
          } else if(appObject === "tvc") {
            let tvc = {
              buOrgIds: [],
              roles: ["Managed in Application"]
            }
            claims = {...claims,
              tvc: tvc
            }
          } else if(appObject === "smaas") {
            let smaas = {
              buOrgIds: [],
              roles: originalResponse?.claims?.isMemberOf?.smaas ?? ["Managed in Application"]
            }
            claims = {...claims,
              smaas: smaas
            }
          } else if(appObject === "shrinkanalyzer") {
            let shrinkanalyzer = {
              buOrgIds: originalResponse?.claims?.shrinkanalyzer?.buOrgIds,
              roles: originalResponse?.claims?.shrinkanalyzer?.roles
            }
            claims = {...claims,
                shrinkanalyzer: shrinkanalyzer
            }
          }
    });
    return { ...originalResponse, claims: claims };
  }

  private isAppInApplications(appsFromResponse: any): boolean {
    const appsArray = ["stan", "tvc", "smaas", "shrinkanalyzer"];
    if(appsArray?.some(value => appsFromResponse?.includes(value))) {
        return true;
    }
    return false;
  }

  updateUser(payload):Observable<any>{
    const url = `${this.userManagementUrl}${REST_ENDPOINT.user.update}`;
    return this.httpClient.put(url, payload , this.authService.getAuthHttpOptionsForUms())
  }

  getSsoTenantIdBySorTenantId(siqId:String): Observable<any> {
    const url = `${this.userManagementUrl}${REST_ENDPOINT.user.getTenantId}${siqId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForUms());
  }
}
