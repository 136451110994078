import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, observable } from 'rxjs';
import { ZoneService } from 'src/app/services/zone.service';
import { ToasterService } from '../../../services/toaster.service';


@Component({
  selector: 'app-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.scss']
})
export class CreateZoneComponent implements OnInit {
  form: UntypedFormGroup;
  isReadonly: Boolean;
  keys: string[];
  mode: string;
  tenantId: string;
  buId: string;
  siteId: string;
  floorId: string;
  zoneId: string;
  zoneValue: any;
  parentId: string;
  selectedTabIndex: number;

  get viewMode(): boolean {
    return this.mode === 'view';
  }

  get createMode(): boolean {
    return !this.zoneId;
  }

  get updateMode(): boolean {
    return this.mode === 'edit'
  }
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private snackBar: MatSnackBar, private zoneService: ZoneService, private router: Router,     private toaster: ToasterService,
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.buId = paramMap.get('buId');
      this.siteId = paramMap.get('siteId');
      this.floorId = paramMap.get('floorId');
      this.zoneId = paramMap.get('zoneId');
      this.mode = paramMap.get('mode') ?? (this.zoneId ? 'view' : 'edit');
    });
    this.route.queryParamMap.subscribe(query => {
      this.parentId = query.get('parentId');
      this.selectedTabIndex = parseInt(query.get('selectedTabIndex'));
    });
    this.inItForm();
    this.loadKeys();
  }

  inItForm() {
    this.form = this.fb.group({
      id: [this.zoneId],
      name: ['', [Validators.required]],
      abbreviation: ['', [Validators.required]],
      parentId: this.parentId,
      floorId: this.floorId,
      siteId: this.siteId,
      zoneType: ['', [Validators.required]],
      description: [''],
      isActive: [true, [Validators.required]],
      keys: this.fb.array([])
    });
    if (this.zoneId) {
      this.loadZone();
    }
  }

  loadKeys(): void {
    this.zoneService.loadKeys().subscribe((sites) => {
      this.keys = sites;
    })
  }

  goToFloorHome() {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/view`], {
      queryParams: {
        selectedTabIndex: 1
      }
    })
  }

  goToZoneHome() {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${this.parentId}/view`], {
      queryParams: {
        selectedTabIndex: 1
      }
    })
  }

  goBack() {
    if (this.parentId) {
      this.goToZoneHome();
    } else {
      this.goToFloorHome();
    }
  }

  toggleUpdate(): void {
    this.mode = this.viewMode ? 'edit' : 'view';
  }

  cancel(): void {
    if (this.createMode) {
      if (this.parentId) {
        this.goToZoneHome();
      } else {
        this.goToFloorHome();
      }
    } else {
      this.toggleUpdate();
      this.loadZone();
    }
  }

  onClickUpdateZone() {
    this.router.navigate([`tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${this.zoneId}/edit`])
  }

  saveZone() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.snackBar.open("Form contains some errors");
      return;
    }
    let observable: Observable<any>;
    const data = { ...this.form.value };
    data.keys = data.keys.filter(k => k.name && k.value);
    if (this.createMode) {
      observable = this.zoneService.createZone(data);
    } else {
      observable = this.zoneService.updateZone(data);
    }
    observable
      .subscribe(res => {
        if (this.createMode) {
          this.snackBar.open('Zone created successfully.\nID: ' + res.id);
          this.router.navigate([`tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/${res.id}/view`])

        } else {
          this.toaster.success('Zone updated successfully.');
          this.toggleUpdate();
          this.loadZone();        
        }
      },
        err => {
          if (err?.error?.message) {
            this.snackBar.open(err.error.message.split(',').join('\n'));
          } else {
            this.snackBar.open('Something went wrong. Please try again.');
          }
        })
  }

  loadZone() {
    this.zoneService.findZoneByZoneId(this.zoneId, this.floorId, this.siteId).subscribe(
      (res) => {
        if (this.mode == 'view') {
          this.isReadonly = true;
          this.zoneValue = res;
          this.viewZone();
        }
        else if (this.mode == 'edit') {
          this.isReadonly = false;
          this.zoneValue = res;
          this.viewZone();
        }
      }
    )
  }

  viewZone() {
    if (this.zoneValue != null) {
      this.viewEditKeys();
      this.parentId = this.zoneValue.parentId;
      this.form.patchValue(this.zoneValue, {emitEvent: true});
    }
  }

  viewEditKeys() {
    if (this.zoneValue != null) {
      const keys = this.form?.get('keys') as UntypedFormArray;
      keys.clear();
      for (let item of this.zoneValue.keys) {
        let dummyKey = this.fb.group({
          name: [''],
          value: ['']
        })
        dummyKey.patchValue(item);
        keys?.push(dummyKey);
      };
    }
  }

  addChildZone() {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${this.floorId}/zones/new`], { queryParams: { parentId: this.zoneId } })
  }
}
