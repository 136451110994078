<div *ngIf="loading" class="loader-container">
  <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
  </div>
  <h1>Loading Site Information...</h1>
</div>
<div *ngIf="!loading" class="page-container">
  <div class="d-flex info-header">
    <div>
      <button class="rounded-pill small" color="primary" mat-flat-button (click)="goToSiteHome()" id="backButton">
        <mat-icon>arrow_back</mat-icon>
        {{ this.admin == true ? "Back to Tenant Details":"Site Home" }}
      </button>
    </div>
    <div class="ml-3 my-auto">
      <span>TENANT ID:&nbsp;<strong>{{tenantId}}</strong></span>
      <span>&nbsp;|&nbsp;BU ID:&nbsp;<strong>{{buId}}</strong></span>
      <span *ngIf="siteId">&nbsp;|&nbsp;SITE ID:&nbsp;<strong>{{siteId}}</strong></span>
    </div>
  </div>
  <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex" class="card-tabs" [class.hide-tabs]="createMode">
    <mat-tab label="Site Info">
      <!-- <div class="loader" *ngIf="loading">
        <app-loader [noText]="true"></app-loader>
      </div> -->
      <form [formGroup]="form" (ngSubmit)="saveSite()" class="mb-4" [class.read-only]="viewMode">
        <div class="text-right mt-4">
          <ng-container *ngIf="!viewMode">
            <button (click)="cancel()" class="rounded-pill small mr-4 border border-primary" color="primary" mat-button
                    type="button">
              CANCEL
            </button>
            <button class="rounded-pill small" color="primary" mat-flat-button type="submit">
              {{siteId ? 'UPDATE' : 'CREATE'}}
            </button>
          </ng-container>
          <button (click)="OnClickUpdate()" *ngIf="viewMode" class="rounded-pill small" color="primary" mat-flat-button
                  type="button">
            UPDATE SITE
          </button>
        </div>
        <section>
          <h2>General Information</h2>
          <app-site-general-info [readOnly]="viewMode"></app-site-general-info>
        </section>
        <section>
          <h2>Keys</h2>
          <app-name-value-pairs [readOnly]="viewMode" field="keys" addLabel="Add Key"
                                [options]="keys">
          </app-name-value-pairs>
        </section>
        <section>
          <h2>Labels</h2>
          <app-labels-sites [readOnly]="viewMode" field="labels" [labelsFromBu]="labels"></app-labels-sites>
        </section>
        <section>
          <h2>Location Information</h2>
          <app-address-validation (locationValidator)="updateLocationValidity($event)" [isReadonly]="viewMode"
                                    [location]="rawData?.location">
          </app-address-validation>
        </section>
        <section>
          <h2>Market segments</h2>
          <app-market-segment [readOnly]="true"></app-market-segment>
        </section>
        <section>
          <h2>Subscriptions</h2>
          <app-subscriptions [readOnly]="viewMode" [isCreateMode]="isCreateMode"></app-subscriptions>
        </section>
      </form>
    </mat-tab>
    <mat-tab *ngIf="!createMode" label="Floors">
      <section>
        <div class="d-flex mt-5">
          <h2>Floors</h2>
          <div class="ml-auto">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addFloor()">
              <mat-icon>add</mat-icon>
              ADD FLOOR
            </button>
          </div>
        </div>
        <app-site-floor [siteId]="siteId" [buId]="buId" [tenantId]="tenantId"></app-site-floor>
      </section>
    </mat-tab>
    <mat-tab *ngIf="!createMode" label="Devices">
      <section>
        <div class="d-flex mt-5">
          <h2>Devices</h2>
          <div class="ml-auto">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addDevice()">
              <mat-icon>add</mat-icon>
              ADD DEVICE
            </button>
          </div>
        </div>
        <app-site-device [siteId]="siteId" [buId]="buId" [tenantId]="tenantId"></app-site-device>
      </section>
    </mat-tab>
  </mat-tab-group>
</div>
