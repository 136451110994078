<mat-card class="access-form">
    <mat-card-content>
        <ng-container>
            <mat-grid-list cols="24" gutterSize="110px" rowHeight="50px">
                <mat-grid-tile class="header" [colspan]="9">
                    <div>Business Unit Names</div>
                </mat-grid-tile>
                <mat-grid-tile class="header" [colspan]="9">
                    <div>Access via BUs</div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6" class="header">&nbsp;</mat-grid-tile>
            </mat-grid-list>
        </ng-container>
        <ng-container *ngIf="!userAccesses.controls.length">
        <mat-grid-list cols="24" gutterSize="16px" rowHeight="50px">
            <mat-grid-tile [colspan]="12">
            <div class="text-center">No Business Units</div>
            </mat-grid-tile>
        </mat-grid-list>
        </ng-container>

        <mat-grid-list cols="24" gutterSize="110px">
        <mat-list>
        <ng-container *ngFor="let userAccess of userAccesses.controls; let i = index">
        <form [formGroup]="userAccess"> 
            <mat-list-item>
                <input class="input-style" matInput [matTooltip]="buList[i]?.name" type="text" formControlName="buName" [readonly]="true" [value]="buList[i]?.name"/>
                <mat-slide-toggle class="toggle-style" formControlName="enable" color="primary" [disabled]="userAccess.value._disabled || isReadonly"></mat-slide-toggle>
            </mat-list-item>
        </form>
        </ng-container>
        </mat-list>
        </mat-grid-list>
    </mat-card-content>
</mat-card>
