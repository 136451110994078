import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { FloorService } from '../../../services/floors-service';
import {ToasterService} from '../../../services/toaster.service';

@Component({
  selector: 'app-site-floor',
  templateUrl: './site-floor.component.html',
  styleUrls: ['./site-floor.component.scss']
})
export class SiteFloorComponent implements OnInit {

  @Input()
  private tenantId: string;

  @Input()
  private buId: string;

  @Input()
  private siteId: string;
  loading = false;
  displayedColumns: string[] = ['name', 'level', 'id', 'createdAt', 'isActive', 'action'];
  dataSource = [];

  constructor(private router: Router, private floorService: FloorService, private toaster: ToasterService) {
  }

  ngOnInit(): void {
    this.getFloorsBySiteId();
  }

  getFloorsBySiteId(){
    this.floorService.findBySiteId(this.siteId).subscribe(
      (res:any)=>{
        this.dataSource=res.floors;        
      },
      (err) => {
        this.toaster.error('Please try again');
      }
    
    )
  }

  editFloor(floor: any): void {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${floor.id}/edit`]);
  }

  viewFloor(floor: any): void {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/${floor.id}/view`]);
  }

}
