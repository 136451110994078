import {Component, Input, OnInit} from '@angular/core';
import {FormPart} from '../../tenants/create-tenant/create-tenant.component';
import {UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import { ToasterService } from '../../../services/toaster.service';
import {Clipboard} from '@angular/cdk/clipboard';


@Component({
  selector: 'app-site-general-info',
  templateUrl: './site-general-info.component.html',
  styleUrls: ['./site-general-info.component.scss'],
  viewProviders: [FormPart]
})
export class SiteGeneralInfoComponent implements OnInit {
  @Input() readOnly;

  constructor(private readonly formGroupDirective: FormGroupDirective, private clipboard: Clipboard, private toaster: ToasterService) {
  }

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }

  ngOnInit(): void {
  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

}
