<mat-card [formGroup]="form">
    <mat-card-content formGroupName="generalInformation">
        <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
            <mat-grid-tile class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput type="text" placeholder="Enter Name Here" formControlName="name" required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select placeholder="Select Type" formControlName=type required>
                        <mat-option value="retail">retail</mat-option>
                        <mat-option value="mall">mail</mat-option>
                        <mat-option value="casino">casino</mat-option>
                        <mat-option value="cultural">cultural</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile formGroupName="serviceMaxAccount" class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>Service Max Account</mat-label>
                    <input matInput placeholder="Enter Service Max Account" formControlName=id>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
            <mat-grid-tile class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>Legal Name</mat-label>
                    <input matInput placeholder="Enter legal name here" formControlName=legalName required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>DUNS Number</mat-label>
                    <input matInput placeholder="Enter DUNS number" formControlName=dunsNumber>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="header" [colspan]="8">
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select placeholder="Select status" formControlName=isActive required>
                        <mat-option value="true">Active</mat-option>
                        <mat-option value="false">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>