import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  tenantData: any;
  routeSub: any;
  isReadonly:Boolean;
  viewPage:Boolean=false;
  loader:boolean=true;
  selectedIndex:number;

  constructor(private tenantService: TenantService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params)=>{
      if(params.get('tab')=='bu'){
        this.selectedIndex=1
      }
    });
    this.route.queryParamMap.subscribe((params)=>{
      if(params.get('tab')=='users'){
        this.selectedIndex=2
      }
    });
    this.route.params.subscribe((params: any) => {
      this.routeSub = params['id']
    });
    if (this.router.url.includes('/dashboard/view') && this.routeSub) {
      this.viewTenant();
    }
    else if(this.router.url.includes('/dashboard/update') && this.routeSub){
      this.updateTenant();
    }
    else if(this.router.url.includes('/dashboard/createTenant')){
      this.loader=false
    }
  }

  viewTenant() {
    this.tenantService.getTenant(this.routeSub).subscribe((res: any) => {
      if (res != null) {
        this.viewPage=true;
        this.tenantData = res;
        this.isReadonly=true;
        this.loader=false;
      }
    })
  }

  updateTenant() {
    this.tenantService.getTenant(this.routeSub).subscribe((res: any) => {
      if (res != null) {
        this.viewPage=false;
        this.tenantData = res;
        this.isReadonly=false;
        this.loader=false;
      }
    })
  }

  onClickBack() {
    this.router.navigate(['dashboard/tenants']);
  }
}
