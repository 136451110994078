import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators} from '@angular/forms';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import {FormPart} from '../../tenants/create-tenant/create-tenant.component';
import { MatDialog } from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
  selector: 'app-label-structure',
  templateUrl: './label-structure.component.html',
  styleUrls: ['./label-structure.component.scss']
})
export class LabelStructureComponent implements OnInit {

  @Input() isReadonly: boolean;
  @Input() isCreateMode: boolean;
  hIndexValues = [1,2,3,4,5,6,7,8,9];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private fb: UntypedFormBuilder, private readonly formGroupDirective: FormGroupDirective, private businessUnitService: BusinessUnitService,) {
  }

  ngOnInit(): void {
    if (this.isCreateMode) {
      this.addLabelStructure();
    }
  }

  get labels(): UntypedFormArray {
    return (this.formGroupDirective.form?.get('labelStructure') as UntypedFormArray) ?? this.fb.array([]);
  }

  addValue(event: MatChipInputEvent, labelIndex: number): void {
    const input = event.chipInput;
    const value = event.value.trim();

    if (value) {
      const index = this.labels.at(labelIndex).get('values').value.indexOf(value);
      if (index === -1) {
        const labelValueArray = this.labels.at(labelIndex).get('values') as UntypedFormArray;
        labelValueArray.push(this.fb.control(value));
      }
    }

    if (input) {
      input.clear();
    }
  }

  removeValue(chipValue: string, labelIndex: number): void {
    const values = this.labels.at(labelIndex).get('values').value || [];
    const chipIndex = values.indexOf(chipValue);

    const labelValueArray = this.labels.at(labelIndex).get('values') as UntypedFormArray;
    labelValueArray.removeAt(chipIndex);
  }

  addLabelStructure(): void {
    const label = this.fb.group({
      name: [''],
      values: this.fb.array([]),
      hIndex: [''],
    },);
    label.setValidators(this.validateNameAndValues.bind(this));
    label.updateValueAndValidity();
    this.labels.push(label);
  }

  validateNameAndValues(control: AbstractControl): ValidationErrors | null {
    const nameValue = control.get('name').value;
    const valuesArray = control.get('values') as UntypedFormArray;
  
    const valuesValue = valuesArray.controls.some((control: AbstractControl) => {
      return control.value !== '';
    });
  
    if ((nameValue && !valuesValue) || (!nameValue && valuesValue)) {
      return { nameAndValuesRequired: true };
    }
  
    return null;
  }

  removeLabelStructure(index: number): void {
    this.labels.removeAt(index);
  }

  deleteHIndex(event: Event, index: number): void {
    event.stopPropagation();
    this.labels.at(index).get('hIndex').setValue('');
  }

}
