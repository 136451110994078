<div class="page-container">
    <div class="d-flex info-header">
        <div>
          <button class="rounded-pill small" color="primary" mat-flat-button (click)="goToViewSite()">
            <mat-icon>arrow_back</mat-icon>
            FLOOR HOME
          </button>
        </div>
        <div class="ml-3 my-auto">
          <span>TENANT ID:&nbsp;<strong>{{tenantId}}</strong></span>
          <span>&nbsp;|&nbsp;BU ID:&nbsp;<strong>{{buId}}</strong></span>
          <span *ngIf="siteId">&nbsp;|&nbsp;SITE ID:&nbsp;<strong>{{siteId}}</strong></span>
          <!-- <span *ngIf="!floorId">&nbsp;|&nbsp; ADD FLOOR</span> -->
          <span *ngIf="floorId">&nbsp;|&nbsp;FLOOR ID:&nbsp;<strong>{{floorId}}</strong></span>
        </div>
      </div>
      <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex" class="card-tabs" [class.hide-tabs]="createMode">
    <mat-tab label="Floor Info">
      <div class="loader">
        <app-loader *ngIf="loading" [noText]="true"></app-loader>
      </div>
      <form [formGroup]="form" (ngSubmit)="saveFloor()" class="mb-4" [class.read-only]="viewMode">
        <div class="text-right mt-4">
          <ng-container *ngIf="!viewMode">
            <button (click)="cancel()" class="rounded-pill small mr-4 border border-primary" color="primary" mat-button
                    type="button">
              CANCEL
            </button>
            <button class="rounded-pill small" color="primary" mat-flat-button type="submit">
              {{floorId ? 'UPDATE' : 'CREATE'}}
            </button>
          </ng-container>
          <button (click)="toggleUpdate()" *ngIf="viewMode" class="rounded-pill small" color="primary" mat-flat-button
                  type="button">
            UPDATE FLOOR
          </button>
        </div>
        <section>
          <h2>General Information</h2>
          <app-floor-general-info [readOnly]="viewMode"></app-floor-general-info>
        </section>
        <section>
          <h2>Floor Plan</h2>
          <app-floor-plan [floorId]="floorId" [storageImageURL]="rawData?.floorPlan" (onFileUploaded)="getUploadedFile($event)" [allowedFileTypes]="allowedFileTypes" [fileMaxSizeKB]="allowedFileSize" [mode]="mode"></app-floor-plan>
        </section>
        <section>
          <h2>Keys</h2>
          <app-name-value-pairs [readOnly]="viewMode" field="keys" addLabel="Add Key"
                                [options]="keys">
          </app-name-value-pairs>
        </section>
        <!-- <section>
          <h2>Labels</h2>
          <app-name-value-pairs [readOnly]="viewMode" field="labels" addLabel="Add Label"
                                [options]="labels">
          </app-name-value-pairs>
        </section> -->
      </form>
    </mat-tab>
    <mat-tab *ngIf="!createMode" label="Zones">
      <section>
        <div class="d-flex mt-5">
          <h2>Zones</h2>
          <div class="ml-auto">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addZone()">
              <mat-icon>add</mat-icon>
              ADD ZONE
            </button>
          </div>
        </div>
        <app-view-zones [tenantId]="this.tenantId" [buId]="this.buId" [siteId]="this.siteId" [floorId]="this.floorId"></app-view-zones>
      </section>
    </mat-tab>
  </mat-tab-group>
</div>