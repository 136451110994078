<div class="container">
    <div class="section-header">
      <h2 class="heading">Manage Super admins</h2>
      <div class="button-box">
        <button mat-raised-button color="primary"
        (click)="addNewSuperAdmin()">add new super admin
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  
    <div class="super-admin-grid">
      <ag-grid-angular class="ag-theme-material" [rowData]="rowData" [columnDefs]="columnDefs" [pagination]="true"
        [paginationPageSize]="paginationPageSize" [overlayLoadingTemplate]="overlayLoadingTemplate"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>