import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TenantService} from 'src/app/services/tenant.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AddOrgComponent} from '../../../components/tenants/add-org/add-org.component';
import {MatPaginator} from '@angular/material/paginator';
import {Tenant, TenantDataSource} from '../../../misc/TenantDataSource';
import {MatSort} from '@angular/material/sort';
import {ToasterService} from '../../../services/toaster.service';
import {AuthService} from '../../../auth/services/auth.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tanant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['name', 'displayName', 'id', 'createdAt', 'isActive', 'action'];
  dataSource: TenantDataSource;
  admin: boolean = this.authService.getFromCookie("admin") == "true";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private tenantService: TenantService,
    private dialog: MatDialog, private router: Router,
    private route: ActivatedRoute,
    private toaster: ToasterService,
    private authService: AuthService, private userService: UserService) {
    this.dataSource = new TenantDataSource(this.tenantService, this.authService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort);
  }

  ngDoCheck() {
    this.tenantService.snackbar.subscribe((res: any) => {
      if (res) {
        this.tenantService.snackbarTenantId.subscribe((res1: any) => {
          this.openSnackbar(res1)
        })
      }
    })
  }

  editTenant(tenant: Tenant): void {
    this.authService.saveInCookie("siqId", tenant.id);
    this.userService.getSsoTenantIdBySorTenantId(tenant.id).subscribe(res => {
      this.authService.saveInCookie("selectedTenantId", res.tenantId)
    });
    this.router.navigate([`dashboard/update/${tenant.id}`]);
  }

  viewTenant(tenant: Tenant): void {
    let superAdmin = this.authService.getFromCookie("superAdmin") == "true";
    this.authService.saveInCookie("siqId", tenant.id);
    if (superAdmin) {
      this.userService.getSsoTenantIdBySorTenantId(tenant.id).subscribe(res => {
        this.authService.saveInCookie("selectedTenantId", res.tenantId);
        this.router.navigate([`dashboard/view/${tenant.id}`], {
          // relativeTo: this.route,
        });
      });
    } else {
      this.router.navigate([`dashboard/view/${tenant.id}`], {
        // relativeTo: this.route,
      });
    }
  }

  addNewOrg(data: any) {
    const selectedRows = data.rowData;
    const dialogRef = this.dialog.open(AddOrgComponent,
      {
        data:
          {tenantName: selectedRows.name, tenantId: selectedRows.id}
      });
  }

  createTenant(): void {
    this.router.navigate(['dashboard/createTenant']);
  }

  openSnackbar(res1: String) {
    let message = 'Tenant created succesfully with id ' + res1
    this.toaster.success(message);
    this.tenantService.snackbar.next(false);
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }
}
