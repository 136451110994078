import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { AuthProcessComponent } from './auth/components/auth-process/auth-process.component';
import { AuthVerifyComponent } from './auth/components/auth-verify/auth-verify.component';
import { HomeComponent } from './components/home/home.component';
import { TenantComponent } from './pages/tenants/tenants-overview/tanant.component';
import { SuperAdminComponent } from './components/super-admin/super-admin.component';
import { ManageTenantComponent } from './components/tenants/manage-tenant/manage-tenant.component';
import { CommonHeaderComponent } from './components/tenants/common-header/common-header.component';
import { BusinessUnitsOverviewComponent } from './pages/bu/business-units-overview/business-units-overview.component';
import { SiteEditorComponent } from './pages/sites/site-editor/site-editor.component';
import { CreateBusinessUnitComponent } from './pages/bu/create-business-unit/create-business-unit.component';
import { SitesOverviewComponent } from './pages/sites/sites-overview/sites-overview.component';
import { DataMigrationComponent } from './pages/migration/data-migration/data-migration.component';
import { CreateZoneComponent } from './pages/zones/create-zone/create-zone.component';
import { FloorEditorComponent } from './pages/sites/floor-editor/floor-editor.component';
import { DeviceEditorComponent } from './pages/devices/device-editor/device-editor.component';
import { CreateUserComponent } from './pages/users/create-user/create-user.component';
import { DevicesOverviewComponent } from './pages/devices/devices-overview/devices-overview.component';
import { UploadHistoryComponent } from './pages/sites/upload-history/upload-history.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'createTenant',
        component: CommonHeaderComponent
      },
      {
        path: 'view/:id',
        component: CommonHeaderComponent
      },
      {
        path: 'update/:id',
        component: CommonHeaderComponent
      },
      {
        path: 'tenants',
        component: TenantComponent
      },
      {
        path: 'tenants/:id',
        component: ManageTenantComponent
      },
      {
        path: 'users',
        component: SuperAdminComponent,
      },
      {
        path: '',
        redirectTo: 'tenants',
        pathMatch: 'prefix' as PathMatch
      }
    ]
  },
  {
    path: '',
    redirectTo: '/auth-verify',
    pathMatch: 'full' as PathMatch,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'business-units',
        component: BusinessUnitsOverviewComponent
      },
      {
        path: 'sites',
        component: SitesOverviewComponent
      },
      {
        path: 'devices',
        children: [
          {
            path: 'new',
            component: DeviceEditorComponent
          },
          {
            path: ':deviceId',
            children: [
              {
                path : ':mode',
                component : DeviceEditorComponent
              }
            ]
          },
          {
            path: '',
            component: DevicesOverviewComponent,
          }
        ]
      },
      {
        path: 'tenants',
        children: [
          {
            path: ':tenantId',
            children: [
              {
                path: 'business-units',
                children: [
                  {
                    path: 'new',
                    component: CreateBusinessUnitComponent
                  },
                  {
                    path: ':buId',
                    children: [
                      {
                        path: 'uploads',
                        component: UploadHistoryComponent
                      },
                      {
                        path: ':mode',
                        component: CreateBusinessUnitComponent
                      },
                      {
                        path: 'sites',
                        children: [
                          {
                            path: 'new',
                            component: SiteEditorComponent
                          },
                          {
                            path: ':siteId',
                            children: [
                              {
                                path: ':mode',
                                component: SiteEditorComponent
                              },
                              {
                                path: 'devices',
                                children: [
                                  {
                                    path: 'new',
                                    component: DeviceEditorComponent
                                  },
                                  {
                                    path: ':deviceId',
                                    children: [
                                      {
                                        path : ':mode',
                                        component : DeviceEditorComponent
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                path: 'floors',
                                children: [
                                  {
                                    path: 'new',
                                    component: FloorEditorComponent
                                  },
                                  {
                                    path: ':floorId/:mode',
                                    component: FloorEditorComponent
                                  },
                                  {
                                    path: ':floorId',
                                    children: [
                                      {
                                        path: 'zones',
                                        children: [
                                          {
                                            path: 'new',
                                            component: CreateZoneComponent
                                          },
                                          {
                                            path: ':zoneId',
                                            children: [
                                              {
                                                path : ':mode',
                                                component : CreateZoneComponent
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'users',
                children: [
                  {
                    path: 'new',
                    component: CreateUserComponent
                  },
                  {
                    path: ':userId',
                    children: [
                      {
                        path: ':mode',
                        component: CreateUserComponent
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '',
            pathMatch: 'full' as PathMatch,
            redirectTo: '/dashboard/tenants'
          }
        ]
      },
      {
        path: 'data-migration',
        component: DataMigrationComponent
      }
    ]
  },
  {
    path: 'auth-verify',
    component: AuthVerifyComponent
  },
  {
    path: 'auth-process/:userid/:tenantId/:uuid',
    component: AuthProcessComponent
  },
];

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
