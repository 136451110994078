import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services/site.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UploadHistoryDataSource } from 'src/app/misc/UploadHistoryDataSource';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'upload-history-list',
    templateUrl: './upload-history-list.component.html',
    styleUrls: ['./upload-history-list.component.scss']
})
export class UploadHistoryListComponent implements OnInit, AfterViewInit {

    @Input()
    private buId: string;

    loading = false;
    displayedColumns: string[];
    dataSource: UploadHistoryDataSource;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private spinner: NgxSpinnerService, private router: Router, private siteService: SiteService, private toaster: ToasterService) {
        this.dataSource = new UploadHistoryDataSource(siteService);
    }

    ngOnInit(): void {
        this.displayedColumns = ['name', 'createdAt', 'totalCount', 'status', 'action'];
    }

    ngAfterViewInit(): void {
        this.dataSource.init(this.paginator, this.sort,  {buId: this.buId});
    }

    download(fileId: string) {
        this.spinner.show()
        this.siteService.downloadCSV(fileId).subscribe(
            res => {
                if(res && res.file) {
                    window.open(res.file, '_blank');
                } else {
                    this.toaster.warning("File cannot be downloaded")
                }
            },
            err => {
                if (err?.error?.message) {
                    this.toaster.error(err.error.message.split(',').join('\n'));
                  } else {
                    this.toaster.error('Something went wrong. Please try again.');
                  }
            }).add(() => {
            this.spinner.hide();
        });
    }

}
