import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { SuperAdminService } from '../../services/super-admin.service';
import { CreateSuperAdminComponent } from './create-super-admin/create-super-admin.component';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit {

  gridApi;
  gridColumnApi;
  paginationPageSize = 20;
  rowData;
  isError = false;
  errorMessage = '';

  columnDefs = [
    { field: 'uid', headerName: 'Username', sortable: true },
    { field: 'email', sortable: true },
    {
      field: 'userMetadata.creationTimestamp',
      headerName: 'created date',
      type: 'dateColumn',
      valueFormatter: function (params) {
        return moment(params.value).format('DD-MMM-YYYY');
      },
      sortable: true
    }
  ];

  overlayLoadingTemplate = `<div class="loader-container">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <h1>Loading...</h1>
</div>`;

  constructor(private superAdminService: SuperAdminService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.fetchSuperAdmins();
  }

  addNewSuperAdmin() {
    const dialogRef = this.dialog.open(CreateSuperAdminComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchSuperAdmins();
      }
    });
  }

  fetchSuperAdmins() {
    // show grid overlay
    this.gridApi.showLoadingOverlay();

    this.superAdminService.fetchSuperAdmin().subscribe(
      (res) => {
        this.rowData = res.result;
        // hide grid overlay
        this.gridApi.hideOverlay();
        // After view model update keep the column to fit the full width
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 0);
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            // redirect to the login route
            // or show a modal 
            // if token is invalid then remove it from
            // cookie and navigate user to login
            this.isError = true;
            this.errorMessage = 'You are unauthorized to see this information';
          }
        }
      }
    )
  }

}
