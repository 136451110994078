import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import {AuthService} from '../../../auth/services/auth.service';
import {UserDataSource} from '../../../misc/UserDataSource';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-tenants-users-overview',
  templateUrl: './tenants-users-overview.component.html',
  styleUrls: ['./tenants-users-overview.component.scss']
})
export class TenantsUsersOverviewComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'username', 'email', 'userRole', 'lastLogin', 'enabled', 'action'];
  @Input() tenantId;
  tenantIdSso: any;
  dataSource: UserDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  admin: Boolean = this.authService.getFromCookie("admin") == "true";
  superAdmin: Boolean = this.authService.getFromCookie("superAdmin") == "true";

  constructor(private router:Router, private userService:UserService, private toaster: ToasterService, private authService: AuthService) { }

  ngOnInit(): void {
    // this.tenantIdSso = this.authService.getTenantIdFromCookie();
    // this.getAllUsers();
    this.dataSource = new UserDataSource(this.authService, this.userService);
  }

  ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort);
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }

  getRoleFromClaims(claimObject) : any{
    const roleFromClaim = [];
    claimObject?.apps?.forEach((appObject: string, index) => {
      if(claimObject?.[appObject]?.roles?.[0])
        roleFromClaim.push(claimObject?.[appObject]?.roles?.[0])
    });
    return roleFromClaim;
  }

  getAllUsers(){
    if (this.tenantIdSso) {
      this.userService.findAllUsersByTenant(this.tenantIdSso).subscribe(
        (res:any)=>{
          this.dataSource=res;
        });
    }
  }

  onClickAdd(){
    this.router.navigate([`/tenants/${this.tenantId}/users/new`]);
  }

  viewTenantsUser(user:any){
    this.router.navigate([`/tenants/${this.tenantId}/users/${user.email}/view`]);
  }

  editTenantsUser(user:any){
    this.router.navigate([`/tenants/${this.tenantId}/users/${user.email}/edit`]);
  }
}

