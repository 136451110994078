<div *ngIf="loading" class="loader-container">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <h1>Loading Device Information...</h1>
</div>
<div *ngIf="!loading" class="page-container">
  <div class="d-flex info-header">
    <div>
      <button
        class="rounded-pill small"
        color="primary"
        mat-flat-button
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
        BACK
      </button>
    </div>
    <div class="ml-3 my-auto">
      <div class="full-width"><strong>UPLOAD CSV</strong></div>
    </div>
  </div>
  <app-file-upload
    [uploadCSV]="uploadCSV"
    (onFileSelected)="onFileSelected($event)"
    [placeholder]="'Browse for the CSV file'"
    [buId]="buId"
  ></app-file-upload>
  <upload-history-list [buId]="buId"></upload-history-list>
</div>


