import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "../../../services/toaster.service";
import { DeviceService } from "../../../services/devices.service";
import { SiteService } from "../../../services/site.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { patchForm } from "../../../misc/Utility";
import { Observable } from "rxjs";
import { uniq } from 'lodash';
import { MAC_ADDRESS_REGEX } from "src/app/app.constants";

@Component({
    selector: 'app-device-editor',
    templateUrl: './device-editor.component.html',
    styleUrls: ['./device-editor.component.scss']
})
export class DeviceEditorComponent implements OnInit {

    tenantId: string;
    buId: string;
    siteId: string;
    floorId: string;
    floorName: string;
    zoneId: string;
    zoneName: string;
    uuid: string;
    parentId: string;
    form: UntypedFormGroup;
    mode: string;
    custSiteId: string;
    siteName: string;
    locationValid = false;
    keys: string[] = [];
    labels: string[] = [];
    loadingCount = 0;
    selectedTabIndex: number;
    floorDetails: any;
    zoneDetails: any;
    rawData: any;
    deviceTypes: any;
    manufacturers: string[];
    models: string[];
    category: string[];
    localLoading = false;
    filteredSites: [] = [];

    get viewMode(): boolean {
        return this.mode === 'view';
    }

    get loading(): boolean {
        return this.loadingCount > 0;
    }

    get createMode(): boolean {
        return !this.uuid;
    }

    constructor(private fb: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private deviceService: DeviceService,
        private siteService: SiteService
    ) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(paramMap => {
            this.tenantId = paramMap.get('tenantId') ?? "";
            this.buId = paramMap.get('buId') ?? "";
            this.siteId = paramMap.get('siteId') ?? "";
            this.uuid = paramMap.get('deviceId') ?? "";
            this.mode = paramMap.get('mode') ?? (this.uuid ? 'view' : 'edit');
        });
        this.route.queryParamMap.subscribe(query => {
            this.parentId = query.get('parentId') ?? "";
            this.custSiteId = query.get('custSiteId') ?? "";
            this.siteName = query.get('siteName') ?? "";
            this.selectedTabIndex = parseInt(query.get('selectedTabIndex'));
        });
        this.initForm();
        this.loadKeys();
    }

    getSelectedZone(event) {
        this.zoneId = event.value
        this.zoneName = event.source.triggerValue
    }

    clearZone() {
        this.zoneId = null
        this.form?.get('zoneId').setValue(null)
        this.zoneName = null;
    }

    getZonesByFloorId(event) {
        this.clearZone()
        this.floorId = event.value
        this.floorName = event?.source?.triggerValue
        this.localLoading = true;
        this.deviceService.findZonesByFloorId(this.floorId, this.siteId).subscribe(
            (res: any) => {
                this.zoneDetails = res.zones;
            },
            (err) => {
                this.toaster.error('Please try again');
            })
            .add(() => {
                this.localLoading = false;
            });
    }

    getFloorsBySiteId() {
        this.clearZone()
        this.localLoading = true;
        this.deviceService.findBySiteId(this.siteId).subscribe(
            (res: any) => {
                this.floorDetails = res.floors;
            },
            (err) => {
                this.toaster.error('Please try again');
            })
            .add(() => {
                this.localLoading = false;
            });
    }

    initForm(): void {
        if (this.siteId)
            this.getFloorsBySiteId();
        this.getAllDeviceTypes();
        this.form = this.fb.group({
            uuid: [this.uuid],
            deviceId: ['', [Validators.required, Validators.pattern(new RegExp(MAC_ADDRESS_REGEX))]],
            tenantId: [this.tenantId],
            buId: [this.buId],
            siteId: [this.siteId],
            customerSiteId: [this.custSiteId],
            floorId: [this.floorId],
            zoneId: [this.zoneId],
            parentId: [this.parentId],
            name: ['', [Validators.required]],
            description: [''],
            manufacturer: ['', [Validators.required]],
            model: ['', [Validators.required]],
            category: ['', [Validators.required]],
            isActive: [true, Validators.required],
            keys: this.fb.array([]),
        });
        if (this.uuid) {
            this.loadDeviceDetails();
        }
    }

    getAllDeviceTypes(): void {
        this.loadingCount++;
        this.deviceService.getDeviceTypes()
            .subscribe(res => {
                this.deviceTypes = res;
                this.manufacturers = uniq(this.deviceTypes.map(value => value.manufacturer)).sort();
                this.populateDeviceDetails();
            },
                err => {
                    if (err.status === 404) {
                        this.toaster.error(err?.error?.message ?? 'Device Types not found');
                    } else {
                        this.toaster.error('Something went wrong. Please try again');
                    }
                })
            .add(() => {
                this.loadingCount--;
            });
    }

    loadDeviceDetails(): void {
        this.loadingCount++;
        this.deviceService.getDeviceByID(this.uuid)
            .subscribe(res => {
                this.resetForm();
                res.labels = res.labels ?? [];
                this.rawData = res;
                if (res?.location?.tenantId) this.tenantId = res?.location?.tenantId
                if (res?.location?.businessUnitId) this.buId = res?.location?.businessUnitId
                if (res?.location?.siteName && !this.siteName) this.siteName = res?.location?.siteName
                if (res?.location?.siteId && !this.siteId) {
                    this.siteId = res?.location?.siteId
                    this.getFloorsBySiteId()
                }
                if (res?.location?.floorId) {
                    this.getZonesByFloorId({ value: res?.location?.floorId })
                    this.floorName = res?.location?.floorName
                }
                if (res?.location?.zoneId) {
                    this.zoneName = res?.location?.zoneName
                }
                patchForm(this.form, this.setFormData(res));
                this.populateDeviceDetails();
            },
                err => {
                    if (err.status === 404) {
                        this.toaster.error(err?.error?.message ?? 'Device not found');
                    } else {
                        this.toaster.error('Something went wrong. Please try again');
                    }
                })
            .add(() => {
                this.loadingCount--;
            });
    }

    loadKeys(): void {
        this.deviceService.loadKeys()
            .subscribe(res => this.keys = res);
    }

    cancel(): void {
        if (this.createMode) {
            this.goToSiteHome();
        } else {
            this.toggleUpdate();
            this.loadDeviceDetails();
        }
    }

    goBack(): void {
        // if (this.tenantId && this.viewMode)
        if (this.router.url.includes('tenants'))
            this.goToSiteHome()
        else
            this.router.navigate([`/devices`])
    }

    goToSiteHome(): void {
        this.router.navigate([`tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/view`], { queryParams: { selectedTabIndex: 2 } });
    }

    toggleUpdate(): void {
        this.mode = this.viewMode ? 'edit' : 'view';
    }
    onTabChange(event: MatTabChangeEvent) {
        this.selectedTabIndex = event.index;
    }

    addDevices(): void {
        this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/devices/new`], { queryParams: { parentId: this.uuid, custSiteId: this.form.get('customerSiteId').value ?? "" } });
    }


    resetForm(): void {
        this.form.reset();
        ['keys'].forEach(field => {
            (this.form.get(field) as UntypedFormArray).clear();
        });
    }

    setFormData(res) {
        return {
            "buId": res?.location?.businessUnitId,
            "tenantId": res?.location?.tenantId,
            "siteId": res?.location?.siteId,
            "customerSiteId": res?.location?.customerId,
            "floorId": res?.location?.floorId,
            "zoneId": res?.location?.zoneId,
            "manufacturer": res?.deviceType?.manufacturer,
            "model": res?.deviceType?.model,
            "category": res?.deviceType?.category,
            "isActive": res.isActive,
            "description": res.description,
            "keys": res.keys,
            "parentId": res.parentId ?? "",
            "name": res.name,
            "deviceId": res.id,
            "uuid": res.uuid
        }
    }

    populateDeviceDetails(e?) {
        if (e?.key === 'manufacturer') {
            this.models = null;
            this.form?.get('model').setValue(null);
            this.form?.get('category').setValue(null);
            this.category = null;
            this.models = this.deviceTypes?.filter(data => data.manufacturer == e.ev.value).map(value => value.model).sort()
        }
        if (e?.key === 'model') {
            this.category = null;
            this.form?.get('category').setValue(null);
            let manufacturer = this.form?.get('manufacturer').value;
            this.category = [this.deviceTypes.find(data => data.manufacturer == manufacturer && data.model == e.ev.value).category]
        }
        if (!e) {
            let manufacturer = this.form?.get('manufacturer').value;
            let model = this.form?.get('model').value;
            this.manufacturers = uniq(this.deviceTypes?.map(value => value.manufacturer)).sort();
            this.models = this.deviceTypes?.filter(data => data.manufacturer == manufacturer).map(value => value.model).sort()
            this.category = [this.deviceTypes?.find(data => data.manufacturer == manufacturer && data.model == model)?.category]
        }
    }

    createPayload(data) {
        return {
            "location": {
                "businessUnitId": data.buId.trim(),
                "tenantId": data.tenantId.trim(),
                "siteId": data.siteId.trim(),
                "customerId": data.customerSiteId.trim(),
                "floorId": data.floorId?.trim(),
                "floorName": this.floorName?.trim(),
                "zoneId": data.zoneId?.trim(),
                "zoneName": this.zoneName?.trim(),
                "siteName": this.siteName
            },
            "deviceType": {
                "manufacturer": data.manufacturer,
                "model": data.model,
                "category": data.category
            },
            "isActive": data.isActive,
            "status": data.isActive ? "online" : "offline", //hardcoded for now. To be removed after confirmation.
            "description": data.description,
            "keys": data.keys,
            "name": data.name.trim(),
            "id": data.deviceId,
            "uuid": data.uuid ?? ""
        }
    }

    searchSite(value) {
        if (value.length > 10) {
            this.localLoading = true;
            let observable: Observable<any> = this.siteService.searchSites(value)
            observable.subscribe(
                (res: any) => {
                    this.filteredSites = res.sites;
                },
                (err) => {
                    this.toaster.error('Please try again');
                })
                .add(() => {
                    this.localLoading = false;
                });
        }
    }


    onSiteSelected(event) {
        let site = event.option.value
        if (site) {
            this.siteId = site.id
            this.form.get('siteId').setValue(site.id)
            this.siteName = site.name;
            this.form.get('customerSiteId').setValue(site.customerSiteId)
            this.buId = site.businessUnitId
            this.form.get('buId').setValue(site.businessUnitId)
            this.tenantId = site.tenantId
            this.form.get('tenantId').setValue(site.tenantId)
            if (this.siteId)
                this.getFloorsBySiteId();
        }
    }

    saveDevice(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.toaster.info('Form contains some errors');
            return;
        }
        let observable: Observable<any>;
        let data = { ...this.form.value };
        data = this.createPayload(data);
        data.keys = data.keys.filter(k => k.name && k.value);
        if (this.createMode) {
            observable = this.deviceService.createDevice(data);
        } else {
            observable = this.deviceService.updateDevice(data);
        }
        this.loadingCount++;
        observable
            .subscribe(res => {
                if (this.createMode) {
                    this.toaster.success('Device created successfully.\nID: ' + res.uuid);
                    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/devices/${res.uuid}/view`]);
                } else {
                    this.toaster.success('Device updated successfully.');
                    this.toggleUpdate();
                    this.loadDeviceDetails();
                }
            },
                err => {
                    if (err?.error?.message) {
                        this.toaster.error(err.error.message.split(',').join('\n'));
                    } else {
                        this.toaster.error('Something went wrong. Please try again.');
                    }
                })
            .add(() => {
                this.loadingCount--;
            });
    }
}