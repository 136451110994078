<div class="page-container">
  <div class="d-flex info-header">
    <div *ngIf="buId">
      <button class="rounded-pill small" color="primary" mat-flat-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        ZONE HOME
      </button>
    </div>
    <div class="ml-3 my-auto">
      <span>TENANT ID:&nbsp;<strong>{{tenantId}} | </strong></span>
      <span>BU ID:&nbsp;<strong>{{buId}} | </strong></span>
      <span>SITE ID:&nbsp;<strong>{{siteId}} | </strong></span>
      <span>FLOOR ID:&nbsp;<strong>{{floorId}}</strong></span>
      <!-- <span *ngIf="!zoneId"> | ADD ZONE</span> -->
      <span *ngIf="zoneId">&nbsp;|&nbsp;ZONE ID:&nbsp;<strong>{{zoneId}}</strong></span>
    </div>
  </div>
  <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex" class="card-tabs" [class.hide-tabs]="createMode">
    <mat-tab label="Zone info">
      <form [formGroup]="form" (ngSubmit)="saveZone()" class="mb-4" [class.read-only]="viewMode">
        <div class="text-right mt-4">
          <ng-container *ngIf="!viewMode">
            <button (click)="cancel()" class="rounded-pill small mr-4 border border-primary" color="primary" mat-button
              type="button">
              CANCEL
            </button>
            <button class="rounded-pill small" color="primary" mat-flat-button type="submit">
              {{zoneId ? 'UPDATE' : 'CREATE'}}
            </button>
          </ng-container>
          <button *ngIf="viewMode" class="rounded-pill small" color="primary" mat-flat-button type="button" (click)="toggleUpdate()">UPDATE ZONE</button>
        </div>
        <section>
          <h2>General Information</h2>
          <app-zone-general-information [isReadonly]='viewMode' [parentId]="parentId"></app-zone-general-information>
        </section>
        <section>
          <h2>Keys</h2>
          <app-name-value-pairs [readOnly]="viewMode" field="keys" addLabel="Add Key" [options]="keys">
          </app-name-value-pairs>
        </section>
      </form>
    </mat-tab>
    <mat-tab *ngIf="!createMode" label="Child Zones">
      <section>
        <div class="d-flex mt-5">
          <h2>Child Zones</h2>
          <div class="ml-auto">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addChildZone()">
              <mat-icon>add</mat-icon>
              ADD Child Zone
            </button>
          </div>
        </div>
        <app-view-child-zones [tenantId]="this.tenantId" [buId]="this.buId" [siteId]="this.siteId" [floorId]="this.floorId" [parentZoneId]="zoneId"></app-view-child-zones>
      </section>
    </mat-tab>
  </mat-tab-group>
</div>