import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '../../../services/site.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from '../../../services/toaster.service';

@Component({
    selector: 'csv-upload-history',
    templateUrl: './upload-history.component.html',
    styleUrls: ['./upload-history.component.scss']
})
export class UploadHistoryComponent implements OnInit {

    loadingCount = 0;
    tenantId: string;
    buId: string;

    selectedFile: File;

    get loading(): boolean {
        return this.loadingCount > 0;
    }

    constructor(private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private toaster: ToasterService,
        private siteService: SiteService) {
    }


    ngOnInit(): void {
        this.route.paramMap.subscribe(paramMap => {
            this.tenantId = paramMap.get('tenantId');
            this.buId = paramMap.get('buId');
        });
    }

    onFileSelected(file: File) {
        this.selectedFile = file
    }

    goBack() {
        this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/view`], { queryParams: { selectedTabIndex: 1 } });
        // this.router.navigate([`/business-units`]);
    }

    uploadCSV() {
        if (this.selectedFile) {
            this.spinner.show();
            this.siteService.uploadCSV(this.buId, this.selectedFile)
                .subscribe(
                    res => { 
                        console.log(res)
                        this.toaster.success('CSV uploaded successfully.');
                    }, 
                    err => {
                        if (err?.error?.message) {
                            this.toaster.error(err.error.message.split(',').join('\n'));
                          } else {
                            this.toaster.error('Something went wrong. Please try again.');
                          }
                    }).add(() => {
                    this.spinner.hide();
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.onSameUrlNavigation = 'reload';
                    this.router.navigate([this.router.url]);
                });
        }
    }

}