import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import {ToasterService} from '../../../services/toaster.service';
import { isEqual, some } from 'lodash'

@Component({
  selector: 'app-address-validation',
  templateUrl: './address-validation.component.html',
  styleUrls: ['./address-validation.component.scss']
})

export class AddressValidationComponent implements OnInit {
  locationInformation: UntypedFormGroup;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  parentForm!: UntypedFormGroup;
  longitude: any;
  latitude: any;
  timezoneArray: any[];
  @Input() isReadonly;
  @Input() location;
  @Output() locationValidator = new EventEmitter<boolean>();

  constructor(private readonly formGroupDirective: FormGroupDirective,
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService,
    private dialog:MatDialog) {
  }

  getAllCountries() {
    this.locationService.getAllCountries().subscribe((res:any) => {
      this.countries = res.countries;
      this.editLocation();
      this.locationInformation.get('countryObj').valueChanges.subscribe((country)=>{
        this.countryStateCity(country);
        this.timezoneDropdown(country);
      });
      this.timezoneDropdown(this.locationInformation.get('countryObj').value);
      this.countryStateCityForUpdate();
    });
  }

  getAllStatesByCountryCode(country: any) {
    if(country) {
      this.locationService.getAllStatesByCountry(country).subscribe((res:any) => {
        this.states = res.states;
        if (this.location != null) {
          let oldState = this.states.find(elem => elem?.name == this.location?.state)
          this.locationInformation.get('stateObj').setValue(oldState)
          if(!oldState) {
            this.parentForm?.removeControl('location');
            this.parentForm?.addControl('location', this.locationInformation);
          }
        }
        this.getAllCitiesByCountryCodeAndStateCode(this.locationInformation.get('countryObj')?.value,this.locationInformation.get('stateObj').value);
      });
    }
  }

  getAllCitiesByCountryCodeAndStateCode(country: any, state: any) {
    this.locationService.getAllCitiesByStateAndCountry(country, state).subscribe((res:any) => {
      this.cities = res.cities;
      if (this.location != null) {
        let oldCity = this.cities.find(elem => elem?.name == this.location?.city)
        this.locationInformation.get('cityObj').setValue(oldCity)
        this.parentForm?.removeControl('location');
        this.parentForm?.addControl('location', this.locationInformation);
        this.locationValidator.emit(false);
      }
    });
  }

  getStates(country:any){
    if(country) {
      this.locationService.getAllStatesByCountry(country).subscribe((res:any) => {
        this.states = res.states;
      });
    }
  }

  getCities(country:any,state:any){
    this.locationService.getAllCitiesByStateAndCountry(country, state).subscribe((res:any) => {
      this.cities = res.cities;
    });
  }

  ngOnChanges(){
    this.editLocation();
    this.countryStateCityForUpdate();
  }

  ngOnInit(): void {
    this.getAllCountries();
    this.inItForm();
  }

  inItForm() {
    this.locationInformation = this.fb.group({
      address1: ['',[Validators.required]],
      cityObj: [{value: '',  disabled: this.isReadonly},[Validators.required]],
      stateObj: [{value: '',  disabled: this.isReadonly},[Validators.required]],
      address2: [''],
      countryObj: [{value: '',  disabled: this.isReadonly},[Validators.required]],
      zipcode: ['', [Validators.required]],
      address3: [''],
      longitude: '',
      latitude: '',
      city: ['',[Validators.required]],
      state: ['',[Validators.required]],
      country: ['',[Validators.required]],
      timeZone: [{value: '', disabled: this.isReadonly},[Validators.required]]
    })
    this.parentForm = this.formGroupDirective.form;
    this.parentForm?.addControl('location', this.locationInformation)
  }

  countryStateCity(country: any) {
    this.getStates(country);
    this.states=null;
    this.cities=null;
    this.locationInformation.get('state').reset();
    this.locationInformation.get('stateObj').reset();
    this.locationInformation.get('city').reset();
    this.locationInformation.get('cityObj').reset();
    this.locationInformation.get('timeZone').reset();
    this.locationInformation.get('country').setValue(country?.iso2)

    this.locationInformation.get('stateObj').valueChanges.subscribe((state) => {
      if (state) {
        this.locationInformation.get('state').setValue(state.name)
        this.getCities(this.locationInformation?.get('countryObj').value, this.locationInformation?.get('stateObj').value);
      }
    });
    this.locationInformation.get('cityObj').valueChanges.subscribe((city) => {
      if(city){
        this.locationInformation.get('city').setValue(city.name)
      }
    });
  }

  countryStateCityForUpdate() {
    this.locationInformation?.get('countryObj').valueChanges.subscribe((res: any) => {
      this.countryStateCity(res);
      this.timezoneDropdown(res);
    });
    this.locationInformation?.get('stateObj').valueChanges.subscribe((res: any) => {
      this.locationInformation.get('cityObj').reset();
      this.locationInformation.get('city').reset();
      this.cities = null;
      if (this.locationInformation?.get('stateObj').value) {
        this.locationInformation.get('state').setValue(res.name)
        this.getCities(this.locationInformation?.get('countryObj').value, this.locationInformation?.get('stateObj').value);
      }
    });
    this.locationInformation?.get('cityObj').valueChanges.subscribe((city) => {
      if(city){
        this.locationInformation.get('city').setValue(city.name)
      }
    });
  }

  editLocation() {
    if (this.location != null) {
      this.locationInformation = this.fb.group({
        address1: [''],
        city: [''],
        state: [''],
        address2: [''],
        country: [''],
        zipcode: ['', [Validators.required]],
        address3: [''],
        longitude: '',
        latitude: '',
        cityObj: [''],
        stateObj: [''],
        countryObj: [''],
        timeZone: ['']
      });

      this.locationInformation.patchValue(this.location);
      if(this.isReadonly) {
        this.locationInformation.get('countryObj').disable();
        this.locationInformation.get('stateObj').disable();
        this.locationInformation.get('cityObj').disable();
        this.locationInformation.get('timeZone').disable();
      }
      this.locationInformation.valueChanges.subscribe((res)=>{
        if(!isEqual(res, this.location)) {
          this.locationValidator.emit(true);
        }
      })
      if(this.location.country != null) {
        let oldCountry = this.countries.find(elem => elem?.iso2 == this.location.country);
        this.locationInformation.get('countryObj').setValue(oldCountry);
        this.locationInformation.get('timeZone').setValue(this.location.timeZone);
        this.getAllStatesByCountryCode(this.locationInformation.get('countryObj')?.value);
        this.parentForm?.removeControl('location');
        this.parentForm?.addControl('location', this.locationInformation);
      } else {
        this.parentForm?.removeControl('location');
        this.parentForm?.addControl('location', this.locationInformation);
      }
    }
  }

  validateAddress() {
    let address = {
      "address": {
        "regionCode": this.locationInformation.get('country').value,
        "locality": this.locationInformation.get('city').value,
        "administrativeArea": this.locationInformation.get('state').value,
        "postalCode": this.locationInformation.get('zipcode').value,
        "addressLines": [this.locationInformation.get('address1').value,
        this.locationInformation.get('address2').value,
        this.locationInformation.get('address3').value]
      }
    }
    this.locationService.validateAddress(address).subscribe(
      (res: any) => {
        this.locationInformation.get('longitude').setValue(res.result.geocode.location.longitude);
        this.locationInformation.get('latitude').setValue(res.result.geocode.location.latitude);

        if (res.result.verdict.addressComplete && res.result.verdict.hasUnconfirmedComponents) {
          this.openDialog(res);
        } else if (res.result.address.hasOwnProperty('missingComponentTypes')) {
          if (res.result.address.missingComponentTypes?.length != 0) {
            this.openDialog(res);
          }
        } else if (res.result.verdict.hasUnconfirmedComponents) {
          this.openDialog(res);
        } else if (res.result.verdict.addressComplete && some(res.result.address.addressComponents, (res) => {
          return res.spellCorrected || res.replaced
        })){
          this.openDialog(res);
        } else if ((res.result.verdict.addressComplete && res.result.address.missingComponentTypes?.length != 0) || some(res.result.address.addressComponents, (res) => {
          return res.unexpected && res.confirmationLevel==="CONFIRMED"
        })) {
          this.locationValidator.emit(false);
          this.toaster.success('Address Validation Successful');
        }
      },
      (err: any) => {
        this.locationValidator.emit(false);
        // this.toaster.error('Address validation is not supported for the selected country');
        // this.openDialog(err.error.error.message);
        this.openDialog(err);
      }
    )
  }

  openDialog(data:any){
    const dialogRef =this.dialog.open(DialogBodyComponent,{
      width:'40%',
      data:data
    })
    dialogRef.afterClosed().subscribe((response)=>{
      if(response=="verify"){
        this.locationValidator.emit(true);
      }
      else if(response=="override"){
        this.locationValidator.emit(false);
      }
    })
  }

  timezoneDropdown(country) {
    this.timezoneArray = [];
    if (country) {
      this.locationService.getTimezone(country?.iso2).subscribe(res => {
        if (res) {
          for (let i = 0; i < res.timezones.length; i++) {
            if (!this.timezoneArray.includes(res.timezones[i].name)) {
              this.timezoneArray.push(res.timezones[i].name);
            }
          }
        }
      })
    }
  }

  timezoneSelection(event: any) {
    this.locationInformation.get('timeZone').setValue(event.value);
  }
}
