<mat-card>
  <mat-card-content *ngIf="form" [formGroup]="form">
    <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
      <mat-grid-tile [colspan]="4" *ngIf="readOnly">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
        <mat-label *ngIf="!readOnly">UUID</mat-label>
        <span *ngIf="readOnly" class="w-100 small">
          <mat-label>UUID</mat-label>
          <mat-icon
            (click)="showToast('UUID')"
            *ngIf="readOnly"
            matRipple
            matSuffix
            matRippleColor="white"
            [cdkCopyToClipboard]="this.form.get('uuid').value"
            >content_copy</mat-icon
          >
        </span>
          <input
            matInput
            type="text"
            placeholder="Enter Device UUID"
            formControlName="uuid"
            required
            [readonly]="true"
          />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
        <mat-label *ngIf="!readOnly">Device Id</mat-label>
        <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Device Id</mat-label>
            <mat-icon
              (click)="showToast('Device ID')"
              *ngIf="readOnly"
              matRipple
              matSuffix
              matRippleColor="white"
              [cdkCopyToClipboard]="this.form.get('deviceId').value"
              >content_copy</mat-icon
            >
          </span>
          <input
            matInput
            type="text"
            placeholder="Enter Device MAC ID"
            formControlName="deviceId"
            required
            (keyup)="onKeyUp($event)"
            [readonly]="!isCreateMode"
            maxlength="17"
          />
          <mat-error
            *ngIf="form.controls['deviceId'].hasError('pattern') && !readOnly"
            >Invalid device id</mat-error
          >
          <mat-error
            *ngIf="form.controls['deviceId'].hasError('required') && !readOnly"
            >Please enter a device id</mat-error
          >
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label>Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="Enter Name Here"
            formControlName="name"
            required
            maxlength="100"
            minlength="4"
            [readonly]="readOnly"
          />
          <mat-error
            *ngIf="form.controls['name'].hasError('minlength') && !readOnly"
            >Name must be 4-100 chars</mat-error
          >
          <mat-error
            *ngIf="form.controls['name'].hasError('required') && !readOnly"
            >Please enter a device name</mat-error
          >
          <mat-error
            *ngIf="form.controls['name'].hasError('maxlength') && !readOnly"
            >Name must be 4-100 chars</mat-error
          >
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Status</mat-label>
          <mat-select
            placeholder="Select Status"
            formControlName="isActive"
            required
            [disabled]="readOnly"
          >
            <mat-option [value]="true">Active</mat-option>
            <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Manufacturer</mat-label>
          <mat-select
            placeholder="Select Manufacturer"
            formControlName="manufacturer"
            required
            [disabled]="readOnly"
            (selectionChange)="
              deviceTypeChange.emit({ ev: $event, key: 'manufacturer' })
            "
          >
            <mat-option *ngFor="let data of manufacturers" [value]="data">
              {{ data }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls['manufacturer'].hasError('required') && !readOnly
            "
            >Please select a manufacturer</mat-error
          >
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Model</mat-label>
          <mat-select
            placeholder="Select Model"
            formControlName="model"
            required
            [disabled]="readOnly"
            (selectionChange)="
              deviceTypeChange.emit({ ev: $event, key: 'model' })
            "
          >
            <mat-option *ngFor="let data of models" [value]="data">
              {{ data }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls['model'].hasError('required') && !readOnly"
            >Please select a model</mat-error
          >
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Category</mat-label>
          <mat-select
            placeholder="Select Category"
            formControlName="category"
            required
            [disabled]="readOnly"
          >
            <mat-option *ngFor="let data of category" [value]="data">
              {{ data }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls['category'].hasError('required') && !readOnly"
            >Please select a category</mat-error
          >
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="8">
        <mat-form-field
          floatLabel="always"
          [class]="!readOnly ? 'w-100 small' : 'w-100 small mt-5'"
        >
          <mat-label>Description</mat-label>
          <textarea
            matInput
            [rows]="readOnly ? 3 : 1"
            [placeholder]="readOnly ? '' : 'Enter Description'"
            formControlName="description"
            [readonly]="readOnly"
          ></textarea>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
