<mat-card>
  <mat-card-content
    class="floor-plan-card"
    *ngIf="form && mode == 'view'"
    [formGroup]="form"
  >
    <mat-grid-list
      cols="3"
      gutterSize="16px"
      *ngIf="storageImageURL && storageImageURL !== ''"
    >
      <mat-grid-tile class="storageImageHolder" [colspan]="3">
        <div>
          <!-- <h2 class="paddingLeft">Preview</h2> -->
          <img class="storageImagePreview" [src]="storageImageURL" [alt]="" />
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list
      cols="3"
      gutterSize="16px"
      *ngIf="!storageImageURL"
    >
      <mat-grid-tile class="storageImageHolder" [colspan]="3">
        <h2>
          <!-- <h2 class="paddingLeft">Preview</h2> -->
          No floor plan available
        </h2>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
  <mat-card-content
    class="floor-plan-card"
    *ngIf="form && mode != 'view'"
    [formGroup]="form"
  >
    <mat-grid-list
      cols="3"
      gutterSize="16px"
      *ngIf="imageURL && imageURL !== ''"
    >
      <div *ngIf="storageImageURL">
        <h2 class="paddingLeft">Preview</h2>
      </div>
      <mat-grid-tile class="imageHolder" [colspan]="2" *ngIf="storageImageURL">
        <div>
          <img class="imagePreview" [src]="imageURL" [alt]="" />
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="storageImageURL ? 1 : 3">
        <ngx-file-drop
          [directory]="false"
          [multiple]="false"
          (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)"
          (onFileLeave)="fileLeave($event)"
          dropZoneClassName="floor-plan-content"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div>
              <h4 *ngIf="uploadedFile">Uploaded File: {{ uploadedFile.name }}</h4>
              <h4 *ngIf="storageImageURL && floorId && !uploadedFile">Uploaded File: {{floorId}}</h4>
              <button
                type="button"
                class="rounded-pill small mr-4 border border-primary"
                color="primary"
                mat-button
                (click)="clear()"
              >
                CLEAR
              </button>
              <button
                type="button"
                class="rounded-pill"
                color="primary"
                mat-flat-button
                (click)="openFileSelector()"
              >
                BROWSE
              </button>
            </div>
          </ng-template>
        </ngx-file-drop>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" gutterSize="16px" *ngIf="!imageURL">
      <div class="floor-plan-card">
        <ngx-file-drop
          [directory]="false"
          [multiple]="false"
          (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)"
          (onFileLeave)="fileLeave($event)"
          dropZoneClassName="floor-plan-card"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div class="floor-plan-content">
              <div class="drag-text" *ngIf="!imageURL">
                Drag and Drop the Floor Plan
              </div>
              <div *ngIf="!imageURL">
                <img src="../../../../../assets/images/upload.svg" alt="">
              </div>
              <button
                type="button"
                class="rounded-pill"
                color="primary"
                mat-flat-button
                (click)="openFileSelector()"
              >
                BROWSE
              </button>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
