import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export function patchForm(form: UntypedFormGroup, data: object): void {
  form.patchValue(data);
  for (const field in data) {
    if (Array.isArray(data[field])) {
      const arr = form.get(field) as UntypedFormArray;
      patchArray(data[field], arr);
    }
  }
}

function patchArray(data: any[], arr: UntypedFormArray): void {
  for (const value of data) {
    if (typeof value === 'string') {
      arr.push(new UntypedFormControl(value));
    } else if (value && typeof value === 'object') {
      arr.push(createFormGroup(value));
    }
  }
}

function createFormGroup(data: any): UntypedFormGroup {
  const group = new UntypedFormGroup({});
  for (const field in data) {
    if (typeof data[field] === 'string') {
      group.addControl(field, new UntypedFormControl(data[field]));
    } else if (Array.isArray(data[field])) {
      const arr = new UntypedFormArray([]);
      patchArray(data[field], arr);
      group.addControl(field, arr);
    } else if (data[field] && typeof data[field] === 'object') {
      group.addControl(field, createFormGroup(data[field]));
    } else {
      group.addControl(field, new UntypedFormControl());
    }
  }
  return group;
}
