import { Component, Input, OnInit } from '@angular/core';
import { History } from '../../../../misc/UploadHistoryDataSource';

@Component({
    selector: 'csv-upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {

    @Input()
    element: History;

    progressValue = 0;
    progressColor;

    ngOnInit(): void {
        this.progressValue = this.getValue();
        this.progressColor = this.getColor(this.progressValue);
    }

    getColor(val) {
        if (val >= 70) return "progress-green";
        else return "progress-amber";
    }

    getTextColor(){
        if(this.element.status == "SUCCESSFUL") return "successful-txt"
        else if(this.element.status == "FAILED") return "failed-txt"
    }

    getValue() {
        let value = 0;
        if (this.element.successCount && this.element.totalCount) {
            value = parseInt(((this.element.successCount / this.element.totalCount) * 100).toString())
        }
        return value;
    }

    showProgressBar() {
        let show = false;
        switch (this.element.status) {
            case "SUCCESSFUL":
                show = false;
                break;
            case "FAILED":
                show = false;
                break;
            case "PROCESSING":
                show = false;
                break;
            case "WARNING":
                show = true;
                break;
            default:
                show = true;
                break;
        }
        return show;
    }

}