<h2 class="mat-dialog-title" mat-dialog-title>Add new Org/Bus. unit</h2>
<h3 class="mat-dialog-title tenant-name-title">Tenant Name : {{tenantName}}</h3>
<mat-dialog-content class="mat-typography add-org-content">

    <!-- loading indicator -->
    <div *ngIf="disabled" class="loader-container">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h1>Adding Organization/Bus Unit...</h1>
    </div>

    <!-- Error Message message -->
    <div *ngIf="errorMessage" class="error-message-block">
        <span class="text-danger">{{ errorMessage }}</span>
    </div>

    <form class="w-100" [formGroup]="orgForm" (submit)="addOrg()">
        <div class="w-100">
            <mat-form-field>
                <mat-label>Org/Bus. Unit ID</mat-label>
                <input matInput formControlName="orgId" placeholder="Enter Org or Bus. Unit ID">
                <mat-error *ngIf="orgForm.controls['orgId'].hasError('required')">You must enter an Org/Bus. Unit ID</mat-error>
            </mat-form-field>
        </div>
        <div class="btn-action-block">
            <button type="button" mat-button mat-dialog-close class="btn-cancel" color="primary" mat-stroked-button
                [disabled]="disabled">Cancel</button>
            <button mat-button class="btn-create" [disabled]="disabled || orgForm.invalid" mat-raised-button color="primary"
                cdkFocusInitial>create</button>
        </div>
    </form>
</mat-dialog-content>