import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-process',
  templateUrl: './auth-process.component.html',
  styleUrls: ['./auth-process.component.scss']
})
export class AuthProcessComponent implements OnInit {

  UUID: string;
  userid: string;
  tenantId: string;
  siqId: string;

  constructor(private route: ActivatedRoute, private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {


    this.route.params.subscribe((params) => {
      this.UUID = params['uuid'];
      this.userid = params['userid'];
      this.tenantId = params['tenantId'];

      // save tenant in cookie
      this.authService.saveTenantIdInCookie(this.tenantId);

      if(this.UUID) {
        this.authService.fetchTokenFromSSOServer(this.UUID)
          .subscribe( (res) => {
            if(res.idToken) {
              const token =this.authService.saveTokenInCookie(res.idToken);
              if(token){
                // this.router.navigateByUrl('/dashboard');
                this.authService.verifyToken(token, this.tenantId).subscribe((res2) => {
                  let notRegUser = res2.claims["super-admin"] || res2.claims["admin"];
                  this.siqId = res2.claims["siqTenantId"];
                  if (!notRegUser) {
                    this.router.navigate([`/tenants/${this.siqId}/users/${this.userid}/view`]);
                  } else {
                    this.router.navigateByUrl('/dashboard');
                  }
                });
              }else {
                this.router.navigateByUrl('/');
              }
            }
          }, (err) => {
            // if error is happened
            this.router.navigateByUrl("/");
          });
      }

    })

  }

}
