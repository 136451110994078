<form>
    <mat-card>
        <mat-card-content>
            <ng-container>
                <ng-container [formGroup]="locationInformation">
                    <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>Country</mat-label>
                                <mat-select matInput formControlName="countryObj" [placeholder]="isReadonly ? '' : 'Country Name'"
                                    name="country" required>
                                    <mat-option *ngFor="let country of countries" [value]="country">
                                        {{ country?.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="locationInformation.controls['country'].hasError('required') && !isReadonly">You must enter a country</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>State/Prov</mat-label>
                                <mat-select formControlName="stateObj" name="state" [placeholder]="isReadonly ? '' : 'State/Prov'"
                                    required>
                                    <mat-option *ngFor="let state of states" [value]="state">
                                        {{ state?.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="locationInformation.controls['state'].hasError('required') && !isReadonly">You must enter a state</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>City</mat-label>
                                <mat-select formControlName="cityObj" name="city" [placeholder]="isReadonly ? '' : 'City Name'"
                                     required>
                                    <mat-option *ngFor="let city of cities" [value]="city">
                                        {{ city?.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="locationInformation.controls['city'].hasError('required') && !isReadonly">You must enter a city</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>Address 1</mat-label>
                                <input matInput [placeholder]="isReadonly ? '' : 'Address Line 01'" formControlName="address1" [readonly]="isReadonly" [required]="!isReadonly">
                                <mat-error *ngIf="locationInformation.controls['address1'].hasError('required') && !isReadonly">You must enter a address1</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>Address 2</mat-label>
                                <input matInput [placeholder]="isReadonly ? '' : 'Address Line 02'" formControlName="address2" [readonly]="isReadonly">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>Address 3</mat-label>
                                <input matInput [placeholder]="isReadonly ? '' : 'Address Line 03'" formControlName="address3" [readonly]="isReadonly">
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                    <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
                        <mat-grid-tile class="header" [colspan]="8">
                            <mat-form-field floatLabel="always">
                                <mat-label>Zip</mat-label>
                                <input type="text" maxlength="7" minlength="4" matInput [placeholder]="isReadonly ? '' : 'Enter Zip'"
                                    formControlName="zipcode" [readonly]="isReadonly" [required]="!isReadonly">
                                    <mat-error *ngIf="locationInformation.controls['zipcode'].hasError('required') && !isReadonly">You must enter a zipcode</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]="8">
                          <mat-form-field floatLabel="always">
                            <mat-label>Timezone</mat-label>
                            <mat-select [placeholder]="isReadonly ? '' : 'Time Zone'" formControlName="timeZone" (selectionChange)="timezoneSelection($event)" required>
                              <mat-option *ngFor="let time of timezoneArray" [value]="time">
                                {{time}}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="locationInformation.controls['timeZone'].hasError('required') && !isReadonly">You must select a time zone</mat-error>
                          </mat-form-field>
                      </mat-grid-tile>
                        <mat-grid-tile class="header" [colspan]='8'></mat-grid-tile>
                        <mat-grid-tile *ngIf="!isReadonly" class="header" [colspan]="8">
                            <button class="rounded-pill small" color="primary" mat-flat-button (click)="validateAddress()" [disabled]=locationInformation.invalid>VALIDATE ADDRESS</button>
                        </mat-grid-tile>
                    </mat-grid-list>
                </ng-container>
            </ng-container>
        </mat-card-content>
    </mat-card>
</form>
