<h2 class="mat-dialog-title" mat-dialog-title>Add new tenant</h2>
<mat-dialog-content class="mat-typography tenant-content">

    <!-- loading indicator -->
    <div *ngIf="disabled" class="loader-container">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h1>Adding Tenant...</h1>
    </div>

    <!-- Error Message message -->
    <div *ngIf="errorMessage" class="error-message-block">
        <span class="text-danger">{{ errorMessage }}</span>
    </div>

    <form class="w-100" [formGroup]="tenantForm" (submit)="addTenant()">
        <div class="w-100">
            <mat-form-field>
                <mat-label>tenant name</mat-label>
                <span matPrefix>Z- &nbsp;</span>
                <input matInput formControlName="tenantName" placeholder="Enter Tenant Name">
                <mat-error *ngIf="tenantForm.controls['tenantName'].hasError('required')">You must enter a tenant name</mat-error>
                <mat-error *ngIf="tenantForm.controls['tenantName'].hasError('minlength')">Tenant name must be 4-20 chars</mat-error>
                <mat-error *ngIf="tenantForm.controls['tenantName'].hasError('maxlength')">Tenant name must be 4-20 chars</mat-error>
            </mat-form-field>
        </div>
        <div class="btn-action-block">
            <button type="button" mat-button mat-dialog-close class="btn-cancel" color="primary" mat-stroked-button
                [disabled]="disabled">Cancel</button>
            <button mat-button class="btn-create" [disabled]="disabled" mat-raised-button color="primary"
                cdkFocusInitial>add</button>
        </div>
    </form>
</mat-dialog-content>