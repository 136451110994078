import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormPart } from '../../../../components/tenants/create-tenant/create-tenant.component';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToasterService } from '../../../../services/toaster.service';
import { SiteService } from '../../../../services/site.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-device-parent-info',
  templateUrl: './device-parent-info.component.html',
  styleUrls: ['./device-parent-info.component.scss'],
  viewProviders: [FormPart],
})
export class DeviceParentInfoComponent implements OnInit {
  @Input() readOnly;
  @Input() floors;
  @Input() zones;
  @Input() parentId;
  @Output() floorChange = new EventEmitter<any>();
  @Output() zoneChange = new EventEmitter<any>();
  @Output() siteChange = new EventEmitter<any>();
  @Input() filteredSites: [] = [];
  @Input() searchSite: Function;

  constructor(private readonly formGroupDirective: FormGroupDirective, private clipboard: Clipboard, private toaster: ToasterService,
    private siteService: SiteService) {
  }

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }

  ngOnInit(): void {

  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

}
