import { AbstractControl, ValidatorFn } from '@angular/forms';

  export function validateEmailDomain(control: AbstractControl) {
    const email = control.value;
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(email)){
            //Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
            if(email.indexOf("@jci.com", email.length - "@jci.com".length) !== -1 ||
            email.indexOf("@shoppertrak.com", email.length - "@shoppertrak.com".length) !== -1){
                //VALID
                return null;
            } else {
                return { "emailDomain" : "email is not matching domain name" }
            }
        } 
        return { "email" : "invalid email" }
  }
  