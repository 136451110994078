import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {UntypedFormArray, UntypedFormControl, FormGroupDirective, Validators} from '@angular/forms';
import {FormPart} from '../../tenants/create-tenant/create-tenant.component';
import {TenantService} from '../../../services/tenant.service';

@Component({
  selector: 'app-market-segment',
  templateUrl: './market-segment.component.html',
  styleUrls: ['./market-segment.component.scss'],
  viewProviders: [FormPart]
})
export class MarketSegmentComponent implements OnInit {
  @ViewChild('segmentInput') marketSegmentInput: ElementRef<HTMLInputElement>;
  @Input() readOnly;

  constructor(private readonly formGroupDirective: FormGroupDirective, private tenantService: TenantService) {
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new UntypedFormControl();

  filteredMarketSegments: Observable<string[]>;

  allMarketSegments: string[] = [];

  get marketSegmentCtrl(): UntypedFormArray {
    return this.formGroupDirective?.form?.get('marketSegment') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.filteredMarketSegments = this.inputCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allMarketSegments.slice()));
    this.tenantService.getMarketSegments().subscribe(segments => {
      this.allMarketSegments = segments;
    });
    this.updateInputStatus(this.marketSegmentCtrl.status);
    this.marketSegmentCtrl.statusChanges.subscribe(status => this.updateInputStatus(status));
  }

  updateInputStatus(status): void {
    if (status === 'INVALID') {
      this.inputCtrl.setValidators(Validators.required);
      this.inputCtrl.setErrors(this.marketSegmentCtrl.errors);
    } else {
      this.inputCtrl.setValidators(null);
      this.inputCtrl.setErrors(null);
    }
    this.inputCtrl.updateValueAndValidity();
  }

  addNewSegment(event: MatChipInputEvent): void {
    const input = event.chipInput;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.add(value.trim());
    }

    // Reset the input value
    if (input) {
      input.clear();
    }
  }

  selectedSegment(event: MatAutocompleteSelectedEvent): void {
    this.add(event.option.viewValue);
    this.marketSegmentInput.nativeElement.value = '';
    this.inputCtrl?.setValue(null);
  }

  remove(marketSegment: string): void {
    const index = this.marketSegmentCtrl.value.indexOf(marketSegment);

    if (index >= 0) {
      this.marketSegmentCtrl.removeAt(index);
    }
  }

  add(marketSegment: string): void {
    const index = this.marketSegmentCtrl.value.indexOf(marketSegment);

    if (index === -1) {
      this.marketSegmentCtrl.push(new UntypedFormControl(marketSegment));
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allMarketSegments.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

}
