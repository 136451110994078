import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import {ToasterService} from '../../../services/toaster.service';
import { isEqual, some } from 'lodash'

@Component({
  selector: 'app-location-information',
  templateUrl: './location-information.component.html',
  styleUrls: ['./location-information.component.scss']
})
export class LocationInformationComponent implements OnInit {
  locationInformation: UntypedFormGroup;
  countries: any[];
  states: any[];
  cities: any[];
  parentForm!: UntypedFormGroup;
  longitude: any;
  latitude: any;
  timezoneArray: any[];
  @Input() isReadonly;
  @Input() location;
  @Output() locationValidator = new EventEmitter<boolean>();

  constructor(private readonly formGroupDirective: FormGroupDirective,
    private locationService: LocationService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService,
    private dialog:MatDialog) {
    this.countries = this.locationService.getCountries().sort((a, b) => a.name.localeCompare(b.name));
  }

  getStates(country:any){
    this.states=this.locationService.getStatesByCountry(country);
    if (this.location != null) {
      let oldState = this.states.find(elem => elem.name == this.location.state)
      this.locationInformation.get('stateObj').setValue(oldState)
    }
    if(this.states.length == 0) {
      this.states.push(country);
      this.locationInformation.get('stateObj').setValue(country)
    }
  }
  getCities(country:any,state:any){
    this.cities=this.locationService.getCitiesByState(country,state);
    if (this.location != null) {
      let oldCity = this.cities.find(elem => elem.name == this.location.city)
      this.locationInformation.get('cityObj').setValue(oldCity)
    }
    if(this.cities?.length==0){
      this.cities.push(state);
      this.locationInformation.get('cityObj').setValue(state)
    }
  }

  ngOnInit(): void {
    this.inItForm();
    this.editLocation();
    this.locationInformation.get('countryObj').valueChanges.subscribe((country)=>{
      this.countryStateCity(country);
      this.timezoneDropdown(country);
    })
    this.countryStateCityForUpdate();
  }
  inItForm() {
    this.locationInformation = this.fb.group({
      address1: ['',[Validators.required]],
      cityObj: ['',[Validators.required]],
      stateObj: ['',[Validators.required]],
      address2: [''],
      countryObj: ['',[Validators.required]],
      zipcode: ['', [Validators.required]],
      address3: [''],
      longitude: '',
      latitude: '',
      city: ['',[Validators.required]],
      state: ['',[Validators.required]],
      country: ['',[Validators.required]],
      timeZone: ['']
    })
    this.parentForm = this.formGroupDirective.form;
    this.parentForm?.addControl('location', this.locationInformation)
  }

  validateAddress() {
    let address = {
      "address": {
        "regionCode": this.locationInformation.get('country').value,
        "locality": this.locationInformation.get('city').value,
        "administrativeArea": this.locationInformation.get('state').value,
        "postalCode": this.locationInformation.get('zipcode').value,
        "addressLines": [this.locationInformation.get('address1').value,
        this.locationInformation.get('address2').value,
        this.locationInformation.get('address3').value]
      }
    }
    this.locationService.validateAddress(address).subscribe(
      (res: any) => {
        this.locationInformation.get('longitude').setValue(res.result.geocode.location.longitude);
        this.locationInformation.get('latitude').setValue(res.result.geocode.location.latitude);

        if (res.result.verdict.addressComplete && res.result.verdict.hasUnconfirmedComponents) {
          this.openDialog(res);
        }
        else if (res.result.address.hasOwnProperty('missingComponentTypes')) {
          if (res.result.address.missingComponentTypes?.length != 0) {
            this.openDialog(res);
          }
        }
        else if (res.result.verdict.hasUnconfirmedComponents) {
          this.openDialog(res);
        }
        else if (res.result.verdict.addressComplete && some(res.result.address.addressComponents, (res) => {
          return res.spellCorrected || res.replaced
        })){
          this.openDialog(res);
        }
        else if ((res.result.verdict.addressComplete && res.result.address.missingComponentTypes?.length != 0) || some(res.result.address.addressComponents, (res) => {
          return res.unexpected && res.confirmationLevel==="CONFIRMED"
        })) {
          this.locationValidator.emit(false);
          this.toaster.success('Address Validation Successfull');
        }
      },
      (err: any) => {
        this.locationValidator.emit(false);
        this.toaster.error('Address validation is not supported for the selected country');
      }
    )
  }

  openDialog(data:any){
    const dialogRef =this.dialog.open(DialogBodyComponent,{
      width:'40%',
      data:data
    })
    dialogRef.afterClosed().subscribe((response)=>{
      if(response=="verify"){
        this.locationValidator.emit(true);
      }
      else if(response=="override"){
        this.locationValidator.emit(false);
      }
    })
  }


  countryStateCity(country) {
    this.states=null;
    this.cities=null;
    this.locationInformation.get('state').reset();
    this.locationInformation.get('stateObj').reset();
    this.locationInformation.get('city').reset();
    this.locationInformation.get('cityObj').reset();
    this.locationInformation.get('timeZone').reset();
    this.locationInformation.get('country').setValue(country?.iso2);
    if (country) {
      this.states = this.locationService.getStatesByCountry(country);
      if(this.states.length == 0) {
        this.states.push(country);
      }
    }

    this.locationInformation.get('stateObj').valueChanges.subscribe((state) => {
      if (state) {
        this.locationInformation.get('state').setValue(state.name)
        this.cities = this.locationService.getCitiesByState(country, state);
        if (this.cities?.length == 0) {
          this.cities.push(state)
        }
      }
    });
    this.locationInformation.get('cityObj').valueChanges.subscribe((city) => {
      if(city){
        this.locationInformation.get('city').setValue(city.name)
      }
    });
  }

  countryStateCityForUpdate(){
    this.locationInformation?.get('countryObj').valueChanges.subscribe((res: any) => {
      this.countryStateCity(res);
    })
    this.locationInformation?.get('stateObj').valueChanges.subscribe((res: any) => {
      this.locationInformation.get('cityObj').reset();
      this.locationInformation.get('city').reset();
        if (this.locationInformation?.get('stateObj').value) {
          this.locationInformation.get('state').setValue(res.name)
          this.cities = this.locationService.getCitiesByState(this.locationInformation?.get('countryObj').value, this.locationInformation?.get('stateObj').value);
          if (this.cities?.length == 0) {
            this.cities.push(this.locationInformation?.get('stateObj').value)
          }
        }
    })
    this.locationInformation?.get('cityObj').valueChanges.subscribe((city) => {
      if(city){
        this.locationInformation.get('city').setValue(city.name)
      }
    });
  }

  ngOnChanges(){
    this.editLocation();
    this.countryStateCityForUpdate();
  }

  editLocation() {
    if (this.location != null) {
      this.locationInformation = this.fb.group({
        address1: [''],
        city: [''],
        state: [''],
        address2: [''],
        country: [''],
        zipcode: ['', [Validators.required]],
        address3: [''],
        longitude: '',
        latitude: '',
        cityObj: [''],
        stateObj: [''],
        countryObj: [''],
        timeZone: ['']
      });

      this.locationInformation.patchValue(this.location);
      this.locationInformation.valueChanges.subscribe((res)=>{
        if(!isEqual(res, this.location)) {
          this.locationValidator.emit(true);
        }
      })
      if(this.location.country) {
        let oldCountry = this.countries.find(elem => elem?.isoCode == this.location.country)
        this.locationInformation.get('countryObj').setValue(oldCountry);
        this.getStates(this.locationInformation.get('countryObj')?.value);
        this.getCities(this.locationInformation.get('countryObj')?.value,this.locationInformation.get('stateObj').value);
        this.timezoneDropdown(oldCountry);
      }
      this.parentForm?.removeControl('location');
      this.parentForm?.addControl('location', this.locationInformation);
      this.locationValidator.emit(false);
    }
  }

  timezoneDropdown(country) {
    this.timezoneArray = [];
    this.locationService.getTimezone(country?.iso2).subscribe(res => {
      if (res) {
        for (let i = 0; i < res.timezones.length; i++) {
          if (!this.timezoneArray.includes(res.timezones[i].name)) {
            this.timezoneArray.push(res.timezones[i].name);
          }
        }
      }
    })
  }

  timezoneSelection(event: any) {
    this.locationInformation.get('timeZone').setValue(event.value);
  }
}
