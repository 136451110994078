<header>
    <mat-toolbar class="toolbar">
        <div class="logobox">
            <img src="assets\images\Sensormatic IQ logo.jpg" alt="logo">
            <!-- <img class="line" src="assets\images\Line 3.png" alt="line"> -->
            <span class="line">|</span>
            <span class="sor">SOR</span>
        </div>


        <div class="user-profile-box">
            <div *ngIf="userInfo.email" class="user-profile-image">
                {{getShortName(userInfo.email)}}
            </div>
            <div class="user-profile-name" mat-icon-button [matMenuTriggerFor]="menu">
                <span>{{ userInfo.email }}</span>
                <mat-icon aria-hidden="false" aria-label="down arrow icon">keyboard_arrow_down</mat-icon>
            </div>
            <mat-menu #menu="matMenu" overlapTrigger="false" panelClass="user-profile-menu">
                <button mat-menu-item
                [routerLink]="currentUrl.includes('/dashboard') ? ['./users'] : ['dashboard/users']" routerLinkActive="active" [hidden]="admin || !superAdmin">
                    <span class="text-uppercase">Manage users</span>
                </button>
                <button mat-menu-item
                [routerLink]="['./tenants']" routerLinkActive="active" [hidden]="!superAdmin && !admin">
                    <span class="text-uppercase">Manage Tenant</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <span class="text-uppercase">log out</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>

</header>
