import {Component, Input, OnInit} from '@angular/core';
import {FormPart} from '../../../../components/tenants/create-tenant/create-tenant.component';
import {UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import { ToasterService } from '../../../../services/toaster.service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-floor-general-info',
  templateUrl: './floor-general-info.component.html',
  styleUrls: ['./floor-general-info.component.scss'],
  viewProviders: [FormPart]
})
export class FloorGeneralInfoComponent implements OnInit {
  @Input() readOnly;

  floorPlan = Array(999).fill(0).map((e,i)=>i+1)

  constructor(private readonly formGroupDirective: FormGroupDirective, private clipboard: Clipboard, private toaster: ToasterService) {
  }

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }

  ngOnInit(): void {
  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

}
