import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayValidators } from 'src/app/misc/CustomValidators';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import {ToasterService} from '../../../services/toaster.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { UserService } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/validators/password.validators';
import {AuthService} from '../../../auth/services/auth.service';
import {TenantService} from '../../../services/tenant.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  @Input() isReadonly;
  @Input() tenantValue;

  userForm: UntypedFormGroup;
  validEmailIdPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
  validNamePattern = /^(?!\s+$).+/;
  buList: any;
  idList: any;
  enabledBuList: any;
  errorMessage: any;
  tenantId: any;
  tenantName: string;
  tenantIdSso: any;
  userId: any;
  mode: any;
  userValue: any;
  loader: boolean = true;
  loadingCount = 0;
  admin: Boolean = this.authService.getFromCookie("admin") == "true";
  superAdmin: Boolean = this.authService.getFromCookie("superAdmin") == "true";
  email = this.authService.getFromCookie("email");
  selectedAdmin: Boolean;

  get viewMode(): boolean {
    return this.mode === 'view';
  }

  get isCreateMode(): boolean {
    return !this.userId
  }

  get createMode(): boolean {
    return !this.userId;
  }

  get editMode(): boolean {
    return this.mode === 'edit';
  }

  get loading(): boolean {
    return this.loadingCount > 0;
  }


  constructor(private _fb: UntypedFormBuilder, private businessUnitService: BusinessUnitService, public userService: UserService,
    private toaster: ToasterService, private route: ActivatedRoute, private router: Router, private clipboard: Clipboard, private authService: AuthService,
              private tenantService: TenantService) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.tenantIdSso = this.authService.getFromCookie("selectedTenantId");
      this.userId = paramMap.get('userId');
      this.mode = paramMap.get('mode') ?? (this.userId ? 'view' : 'edit');
      this.getBuList();
    });
    this.tenantService.getTenant(this.tenantId).subscribe((res) => {
      this.tenantName = res["name"];
    })
  }

  getBuList() {
    if (this.admin || this.superAdmin) {
      this.loadingCount++;
      this.businessUnitService.findAllBusinessUnitsByTenant(this.tenantId).subscribe(
        (res) => {
          this.buList = res["businessUnits"];
          this.inItForm();
        },
        (err) => {
          this.toaster.error('Please try again');
        }
      ).add(() => {
        this.loadingCount--;
      })
    } else {
      this.inItForm();
    }
  }

  inItForm() {
    this.userForm = this._fb.group({
      firstName: ["", [Validators.required, Validators.pattern(this.validNamePattern)]],
      lastName: ["", [Validators.required, Validators.pattern(this.validNamePattern)]],
      userName: ["", [Validators.required]],
      emailId: ["", [Validators.required, Validators.pattern(this.validEmailIdPattern)]],
      password: [""],
      confirmPassword: [""],
      userRoles: this._fb.array([], [ArrayValidators.minLength(1)]),
      userAccesses: this._fb.array([])
    },
    { validator: CustomValidators.MatchingPasswords });
    this.updatePasswordValidator();

    if (this.userId) {
      this.loadUserInfo();
    }
    else {
      this.loader = false;
      if (this.loadingCount > 0) {
        this.loadingCount--;
      }
    }
  }

  updatePasswordValidator() {
    const passwordControl = this.userForm.get('password');
    const confirmPasswordControl = this.userForm.get('confirmPassword');
    if (this.createMode) {
      passwordControl.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(100)]);
      confirmPasswordControl.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(100)]);
    }
    else if(this.editMode) {
      passwordControl.setValidators([Validators.minLength(8), Validators.maxLength(100)]);
      confirmPasswordControl.setValidators([Validators.minLength(8), Validators.maxLength(100)]);
    }
    else {
      passwordControl.clearValidators();
      confirmPasswordControl.clearValidators();
    }

    passwordControl.updateValueAndValidity();
    confirmPasswordControl.updateValueAndValidity();
  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.toaster.error('Form contains some errors');
      return;
    }

    if(this.isCreateMode)
      this.checkUserExists();
    if(!this.isCreateMode) {
      const payload = this.userForm.value;
      this.createUpdatePayload(payload);
    }
  }

  checkUserExists() {
    const payload = this.userForm.value;
    const userEmail = payload.emailId;
    this.userService.findUserByUserId(userEmail).subscribe(
      (res) => {
        if(res) {
          this.toaster.error('User creation unsuccessful. User already exists');
          return;
        }
      },
      (err) => {
        this.createUpdatePayload(payload);
      }
    );
  }

  createUpdatePayload(forCreate: any) {
    let apps = [];
    let roles = [];
    forCreate.userRoles.forEach(userRoleObj => {
      apps.push(userRoleObj.applicationName);
      roles.push(userRoleObj.userRole);
    });

    let buOrgIds = [];
    forCreate.userAccesses.forEach(userAccessObj => {
      if (userAccessObj.enable)
        buOrgIds.push(userAccessObj.buId);
    });

    let shrinkanalyzer = {
      buOrgIds: buOrgIds,
      roles: roles
    }
    let claims = {
      userAccessId: "",
      siqTenantId: this.tenantId,
      apps: apps,
      shrinkanalyzer: shrinkanalyzer,
      admin: this.selectedAdmin
    }

    let tenant = this.tenantIdSso;
    if (tenant === undefined) {
      tenant = this.authService.getTenantIdFromCookie();
    }

    let payloadForCreateUpdate = {
      accountNonExpired: true,
      accountNonLocked: true,
      app: "",
      buOrgId: "",
      claims: claims,
      credentialsNonExpired: true,
      email: forCreate.emailId,
      enabled: true,
      name: forCreate.firstName.trim() + " " + forCreate.lastName.trim(),
      ...(forCreate.password ? { password: forCreate.password } : {}),
      tenant: tenant,
      uid: forCreate.userName,
      userAlias: "",
      username: forCreate.userName
    }

    if (this.createMode) {
      this.createUser(payloadForCreateUpdate);
    } else {
      this.updateUser(payloadForCreateUpdate)
    }
  }

  createUser(payload) {
    this.loadingCount++;
    this.userService.createUser(payload).subscribe(
      (res) => {
        this.toaster.success(`User created successfully`);

        this.router.navigate([`dashboard/view/${this.tenantId}`], {
          queryParams: {
            tab: 'users'
          }
        });
      },
      (err) => {
        if (err.error.code == '400' || err.status == '400') {
          this.errorMessage = err.error.apierror?.subErrors?.[0].message ?? err.error.apierror?.message
          if (this.errorMessage === "User already exists with some other username") {
            this.errorMessage = "User with this Email Id already exists";
          }
          this.toaster.error(this.errorMessage);
        }
        else {
          this.toaster.error('User Creation failed');
        }
      }
    ).add(() => {
      this.loadingCount--;
    })
  }

  updateUser(payload) {
    this.loadingCount++;
    this.userService.updateUser(payload).subscribe(
      (res) => {
        this.toaster.success(`User updated successfully`);

        this.router.navigate([`/tenants/${this.tenantId}/users/${this.userId}/view`], {
          queryParams: {
            tab: 'users'
          }
        });
      },
      (err) => {
        if (err.error.code == '400' || err.status == '400') {
          this.errorMessage = err.error.apierror?.subErrors?.[0].message ?? err.error.apierror?.message;
          this.toaster.error(this.errorMessage);
        }
        else {
          this.toaster.error('User Updation failed');
        }
      }
    ).add(() => {
      this.loadingCount--;
    })
  }

  loadUserInfo() {
    this.loadingCount++;
    if (this.email === this.userId || this.superAdmin || this.admin) {
      this.userService.findUserByUserId(this.userId).subscribe(
        (res) => {
          this.loader = false;
          this.selectedAdmin = res.claims["admin"] == true;
          if (this.mode == 'view') {
            this.isReadonly = true;
            this.userValue = res;
            this.viewUser();
          }
          else if (this.mode == 'edit') {
            this.isReadonly = false;
            this.userValue = res;
            this.viewUser();
          }
        }
      ).add(() => {
        this.loadingCount--;
      })
    } else {
      this.userService.findUserByUserId(this.email).subscribe(
        (res) => {
          this.loader = false;
          this.selectedAdmin = res.claims["admin"] == true;
          if (this.mode == 'view') {
            this.isReadonly = true;
            this.userValue = res;
            this.viewUser();
          }
          else if (this.mode == 'edit') {
            this.isReadonly = false;
            this.userValue = res;
            this.viewUser();
          }
        }
      ).add(() => {
        this.loadingCount--;
      })
    }
  }

  viewUser() {
    if (this.userValue != null && (this.viewMode || this.editMode)) {
      const nameArray = this.userValue?.name?.split(' ');
      const lastname = nameArray?.pop();
      const firstname = nameArray?.join(' ')
      const formValue = {
        firstName: firstname ? firstname : lastname,
        lastName: firstname ? lastname : firstname,
        userName: this.userValue.username,
        emailId: this.userValue.email,
      }
      this.viewUserRoles();
      this.viewUserAccesses();
      this.userForm.patchValue(formValue);
    }
  }

  viewUserRoles() {
    if (this.userValue != null) {
      const userRoles = this.userForm.get('userRoles') as UntypedFormArray;
      userRoles.clear();
      if(this.userValue?.claims?.apps) {
        for (let item of this.userValue?.claims?.apps) {
          let dummyUserRole = this._fb.group({
            applicationName: [''],
            userRole: [''],
          })
          let userRoleValue = {
            applicationName: item,
            userRole: this.userValue?.claims[item]?.roles?.[0]
          }

          dummyUserRole.patchValue(userRoleValue);
          userRoles?.push(dummyUserRole);
          }
      }
    }
  }


  viewUserAccesses() {
    if (this.userValue != null && this.userValue?.claims?.apps) {
      const userAccesses = this.userForm.get('userAccesses') as UntypedFormArray;
      userAccesses.clear();

      if (this.admin || this.superAdmin) {
        this.buList.forEach((bu) => {
          let dummyUserAccess = this._fb.group({
            buName: [bu?.name],
            buId: [bu?.id],
            enable: [this.isEnabled(bu)],
          })

          userAccesses?.push(dummyUserAccess);
        });
      } else {
        let payload = {};
        let app = this.authService.getFromCookie("apps");
        this.idList = this.userValue.claims[app]['buOrgIds'];
        if (this.idList != "") {
          payload["orgIds"] = this.idList;
          this.businessUnitService.buIDtoNameMapping(payload).subscribe((res) => {
            this.buList = res;
            Object.keys(this.buList).forEach((key) => {
              let dummyUserAccess = this._fb.group({
                buName: [this.buList[key]],
                buId: [key],
                enable: [true]
              });
              userAccesses?.push(dummyUserAccess);
            });
          });
        }
      }
    }
  }

  isEnabled(bu) {
    for (let item of this.userValue?.claims?.apps) {
      this.enabledBuList = this.userValue?.claims[item]?.buOrgIds ?? this.enabledBuList;
    }
    if(this.enabledBuList?.includes(bu?.id))
      return true;
    else
      return false;
  }

  onClickCancel() {
    if (this.createMode) {
      this.router.navigate([`dashboard/view/${this.tenantId}`], {
        queryParams: {
          tab: 'users'
        }
      });
    }
    else if (this.editMode) {
      this.router.navigate([`/tenants/${this.tenantId}/users/${this.userId}/view`]);
    }
  }

  OnClickUpdate() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/tenants/${this.tenantId}/users/${this.userId}/edit`]);
    this.loader=true;
  }

  goToUsersHome() {
    this.router.navigate([`dashboard/view/${this.tenantId}`], {
      queryParams: {
        tab: 'users'
      }
    });
  }
}
