import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormPart } from '../../../../components/tenants/create-tenant/create-tenant.component';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-floor-plan',
    templateUrl: './floor-plan.component.html',
    styleUrls: ['./floor-plan.component.scss'],
    viewProviders: [FormPart]
})
export class FloorPlanComponent implements OnInit, OnChanges {
    @Input() mode;
    @Input() fileMaxSizeKB: number;
    @Input() allowedFileTypes: string[];
    @Input() storageImageURL: any;
    @Input() floorId: string;

    droppedFile: NgxFileDropEntry;
    uploadedFile: File;
    imageURL: any;

    @Output() onFileUploaded = new EventEmitter<any>();

    constructor(private readonly formGroupDirective: FormGroupDirective,
        private snackBar: MatSnackBar, protected sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes?.storageImageURL?.currentValue != null && !this.imageURL){
            this.imageURL = this.storageImageURL
            this.onFileUploaded.emit({file: null, floorPlanUpdated: false, imageURL: this.imageURL});
        }
        if(changes?.mode?.currentValue == 'edit' && !this.imageURL && this.storageImageURL){
            this.imageURL = this.storageImageURL
            this.onFileUploaded.emit({file: null, floorPlanUpdated: false, imageURL: this.imageURL});
        }
      }

    get form(): UntypedFormGroup {
        return this.formGroupDirective.form as UntypedFormGroup;
    }
    reader = new FileReader();

    dropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.snackBar.open("Multiple file upload is not supported");
            return;
        }
        this.droppedFile = files[0];
        if (this.droppedFile.fileEntry.isFile) {
            const fileEntry = this.droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                if (!this.allowedFileTypes.includes(file.type)) {
                    this.snackBar.open('Please upload only SVG files');
                    return;
                }

                if (file.size > (this.fileMaxSizeKB * 1024)) {
                    this.snackBar.open(`Uploaded file size should not exceed ${this.fileMaxSizeKB}KB`);
                    return;
                }

                this.uploadedFile = file;
                this.form.patchValue({
                    uploadedFileName: file.name,
                }, { onlySelf: false, emitEvent: true });
                this.onFileUploaded.emit({file: file, floorPlanUpdated: true, imageURL: this.imageURL});
                
                this.reader.readAsDataURL(file)
                this.reader.onload = () => {
                    // this.imageURL = this.sanitizer.bypassSecurityTrustUrl(this.reader.result as string);
                    this.imageURL = (this.reader.result as string)
                    this.onFileUploaded.emit({file: file, floorPlanUpdated: true, imageURL: this.imageURL});
                }

            });
        }
    }

    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

    ngOnInit(): void {
    }

    clear() {
        this.imageURL = "";
        this.storageImageURL = ""
        this.uploadedFile = null;
        this.onFileUploaded.emit({file: null, floorPlanUpdated: true, imageURL: this.imageURL});
        this.form.patchValue({
            uploadedFileName: null,
            floorPlanUpdated: true
        }, { onlySelf: false, emitEvent: true });
    }

}
