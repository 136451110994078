import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-name-value-pairs',
  templateUrl: './name-value-pairs.component.html',
  styleUrls: ['./name-value-pairs.component.scss']
})
export class NameValuePairsComponent implements OnInit {
  @Input() readOnly;
  @Input() field;
  @Input() addLabel;
  @Input() private options: string[] = [];

  constructor(private formGroupDirective: FormGroupDirective, private fb: UntypedFormBuilder) {
  }

  get items(): UntypedFormArray {
    return (this.formGroupDirective.form?.get(this.field) as UntypedFormArray) ?? this.fb.array([]);
  }

  addItem(): void {
    const item = this.fb.group({
      name: [''],
      value: [''],
      editing: [true]
    });
    this.items.push(item);
  }

  filteredNames(name): string[] {
    return this.options?.filter(option => option.toLowerCase().includes(name?.toLowerCase()));
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  editItem(index: number): void {
    const control = this.items.controls[index] as UntypedFormControl;
    if (control.disabled) {
      control.enable();
    } else {
      control.disable();
    }
  }

  ngOnInit(): void {
    if (!this.readOnly && !this.items.length) {
      this.addItem();
    }
  }

}
