<div *ngIf="loader" class="loader-container">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <h1>Loading Tenant Information...</h1>
</div>
<div *ngIf="tenantData==null && !loader" class="head">
    <div class="head">
        <span class="head_name">Tenant | Create</span>
    </div>
    <app-create-tenant></app-create-tenant>
</div>
<div *ngIf="tenantData!=null && !loader">
    <div class="container">
        <div class="view-head">
            <button class="rounded-pill small" color="primary" mat-flat-button type="button" (click)="onClickBack()">
                <mat-icon>arrow_back</mat-icon>
                TENANT HOME
            </button>
            <span>&nbsp;TENANT ID : <b>{{tenantData?.id}}</b></span>
        </div>
        <mat-card class="common-header-mat-card">
            <mat-tab-group class="card-tabs" [(selectedIndex)]="selectedIndex">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="bold-label">Tenant Info</span>
                      </ng-template>
                    <app-create-tenant [viewPage]="viewPage" [tenantValue]="tenantData" [isReadonly]="isReadonly">
                    </app-create-tenant>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="bold-label">Business Units</span>
                      </ng-template>
                    <app-tenants-business-unit [tenantId]=tenantData.id></app-tenants-business-unit>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span class="bold-label">Users</span>
                    </ng-template>
                    <app-tenants-users-overview [tenantId]=tenantData.id></app-tenants-users-overview>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
