<mat-card class="roles-form">
    <mat-card-content>
  
      <mat-grid-list cols="24" gutterSize="16px" rowHeight="30px">
        <mat-grid-tile [colspan]="9" class="header">
          <div>Application</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="9" class="header">
          <div>User Role</div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="6" class="header">&nbsp;</mat-grid-tile>
      </mat-grid-list>
  
      <ng-container *ngIf="isReadOnly && !userRoles.controls.length">
        <mat-grid-list cols="24" gutterSize="16px" rowHeight="50px">
            <mat-grid-tile [colspan]="10">
              <div class="text-center">No Data</div>
            </mat-grid-tile>
        </mat-grid-list>
      </ng-container>


      <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
        <ng-container *ngFor="let userRole of userRoles.controls; let i = index">
          <!--suppress TypeScriptValidateTypes -->
          <form [formGroup]="userRole" [class.read-only]="userRole.value._disabled || isReadOnly">
            <mat-grid-tile [colspan]="9">
              <mat-form-field class="w-100 small" [ngClass]="{'form-field-padding': !isReadOnly}" floatLabel="never">
                <mat-select matInput formControlName="applicationName" [placeholder]="isReadOnly ? '' : 'Application Name'"
                  name="applicationName" [required]="!isReadOnly" [disabled]="userRole.value._disabled || isReadOnly">
                  <mat-option *ngFor="let applicationName of isReadOnly ? claimsFromResponse?.apps : applicationNamesArray" [value]="applicationName">
                      {{ applicationName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userRole.controls['applicationName'].hasError('required') && !isReadOnly">
                  You must enter an Application
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="9">
              <mat-form-field class="w-100 small" [ngClass]="{'form-field-padding': !isReadOnly}" floatLabel="never">
                <mat-select matInput formControlName="userRole" [placeholder]="isReadOnly ? '' : 'User Role'"
                name="userRole" [required]="!isReadOnly" [disabled]="userRole.value._disabled || isReadOnly" >
                <mat-option *ngFor="let userRole of isReadOnly ? getRolesFromApps(claimsFromResponse) : userRolesArray" [value]="userRole">
                    {{ userRole }}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="userRole.controls['userRole'].hasError('required') && !isReadOnly">
                  Enter User Role
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="6" class="header">&nbsp;</mat-grid-tile>
            <!-- To be implemented -->
            <!-- <mat-grid-tile *ngIf="!isReadOnly" [colspan]="4">
              <button (click)="addUserRole()" class="rounded-pill small" color="primary" mat-flat-button type="button">
                <mat-icon>add</mat-icon>
                Add Role
              </button>
            </mat-grid-tile> -->
          </form>
        </ng-container>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
  