import {Observable} from 'rxjs';
import {CustomDataSource, Page} from './CustomDataSource';
import {BusinessUnitService} from '../services/business-unit.service';
import {map} from 'rxjs/operators';
import {AuthService} from '../auth/services/auth.service';

export class BusinessUnitDataSource extends CustomDataSource<BusinessUnit> {

  superAdmin: Boolean = this.authService.getFromCookie("superAdmin") == "true";

  constructor(private businessUnitService: BusinessUnitService, private authService: AuthService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string): Observable<Page<BusinessUnit>> {
    let observable: Observable<any>;
    let siqId = this.authService.getFromCookie("siqId");

    if (this.superAdmin && siqId === undefined) {
      if (search) {
        observable = this.businessUnitService.searchBusinessUnits(search, page, size, sortField, sortDir);
        return observable.pipe(map(res => ({data: res.businessUnits, total: res.total})));
      } else {
        observable = this.businessUnitService.findAllBusinessUnits(page, size, sortField, sortDir);
        return observable.pipe(map(res => ({data: res.businessUnits, total: res.total})));
      }
    } else {
      observable = this.businessUnitService.findAllBusinessUnitsByTenant(siqId);
      if (!search) {
        return observable.pipe(map(res => ({data: res.businessUnits, total: res.businessUnits.length})));
      } else {
        let length: number;
        let searchArray: [];
        observable.subscribe(res => {
          searchArray = res.businessUnits.filter((org) => {
            let name =  org.name.toLowerCase();
            search = search.toLowerCase();
            return name.includes(search);
          });
          if (searchArray.length == 0) {
            searchArray = res.businessUnits.filter((org) => {
              return org.id.includes(search);
            });
          }
          length = searchArray.length;
        });
        return (observable.pipe(map(res => ({data: searchArray, total: length}))));
      }
    }
  }
}

export interface BusinessUnit {
  id: string;
  name: string;
  tenantId: string;
  tenantName: string;
  createdAt: Date;
  isActive: boolean;
}
