<form>
    <mat-card class="keys-form">
        <mat-card-content>
            <ng-container>
                <mat-grid-list cols="24" gutterSize="110px" rowHeight="50px">
                    <mat-grid-tile class="header" [colspan]="4">Key</mat-grid-tile>
                    <mat-grid-tile class="header" [colspan]="14">Value</mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="24" gutterSize="16px" rowHeight="15px">
                    <ng-container *ngIf="!keys.controls.length">
                      <mat-grid-tile [colspan]="22">
                        <div class="text-center">No Data</div>
                      </mat-grid-tile>
                    </ng-container>
                </mat-grid-list>
            </ng-container>
            <ngContainer *ngFor="let key of keys?.controls; let i = index">
                <ng-container *ngIf="key.enabled" [formGroup]="key">
                    <mat-grid-list cols="24" gutterSize="16px" rowHeight="60px">
                        <mat-grid-tile [colspan]="10">
                            <mat-form-field class="w-100" floatLabel="never">
                                <input type="text" [placeholder]="isReadonly ? '' : 'Enter Key'" matInput formControlName="name"
                                    [matAutocomplete]="auto" (keyup)="filterKeys(value.value)" [readonly]="isReadonly" maxlength="100"
                                    #value>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="key.controls['name'].hasError('maxlength')">key
                                    must be less than 100 chars</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="10">
                            <mat-form-field class="w-100" floatLabel="never">
                                <input formControlName="value" matInput [placeholder]="isReadonly ? '' : 'Enter value'" type="text"
                                    [readonly]="isReadonly">
                                <mat-error *ngIf="key.controls['value'].hasError('maxlength')">value
                                        must be less than 100 chars</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="4">
                            <div class="w-100 text-right">
                                <button *ngIf="!isReadonly" (click)="removeSubscription(i)" class="mat-elevation-z1"
                                    color="warn" mat-mini-fab style="margin-right: 14px">
                                    <mat-icon>remove_circle_outline</mat-icon>
                                </button>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </ng-container>
            </ngContainer>
            <div *ngIf="!isReadonly" class="keys-button">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="addKeys()">
                <mat-icon>add</mat-icon>
                Add keys
            </button>
        </div>
        </mat-card-content>
    </mat-card>
</form>