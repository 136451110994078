<div class="main-container">
    <div class="container" [ngStyle]="{'width.%': isReadonly || tenantValue!=null ? '100' : '85'}" [class.custom-div]="tenantValue!=null">
        <form [formGroup]="tenantForm" (ngSubmit)="onSubmit()" [class.read-only]="isReadonly">
            <div *ngIf="tenantValue==null" class="buttons">
                <button class="button-cancle" type="button" (click)="onclickCancle()">cancel</button>
                <button class="button-create" color="primary" mat-flat-button type="submit">create tenant</button>
            </div>
            <div *ngIf="tenantValue!=null && viewPage" class="buttons" [hidden]="admin">
                <button class="button-create" color="primary" mat-flat-button type="button" (click)="onClickUpdateTenant()" [hidden]="admin" [disabled]="admin">update tenant</button>
            </div>
            <div *ngIf="!viewPage && tenantValue!=null" class="buttons">
                <button class="button-cancle" color="primary" mat-flat-button type="button" (click)="onclickCancleForUpdate()">cancel</button>
                <button class="button-create" color="primary" mat-flat-button type="button" (click)="onClickUpdate()">Update</button>
            </div>
            <div class="gi-container">
                <section>
                    <h2>General Information</h2>
                    <mat-card>
                        <mat-card-content>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Name</mat-label>
                                        <span *ngIf="isCreateMode" matPrefix>Z- &nbsp;</span>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Name Here'" formControlName="name" minlength="4" maxlength="20" [required]="!isReadonly" [readonly]="tenantValue!=null">
                                        <mat-error *ngIf="tenantForm.controls['name'].hasError('required') && !isReadonly">You must enter a tenant name</mat-error>
                                        <mat-error *ngIf="tenantForm.controls['name'].hasError('minlength') && !isReadonly">Tenant name must be 4-20 chars</mat-error>
                                        <mat-error *ngIf="tenantForm.controls['name'].hasError('maxlength') && !isReadonly">Tenant name must be 4-20 chars</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Display Name</mat-label>
                                        <input matInput maxlength="50" [placeholder]="isReadonly ? '' : 'Enter display name here'"
                                            formControlName=displayName [required]="!isReadonly" [readonly]='isReadonly'>
                                            <mat-error *ngIf="tenantForm.controls['name'].hasError('required') && !isReadonly">You must enter a display name</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Type</mat-label>
                                        <input matInput type="text" *ngIf="isReadonly" [placeholder]="isReadonly ? '' : 'Select Type'"
                                            formControlName=type [readonly]='isReadonly'>
                                        <mat-select *ngIf="!isReadonly" [placeholder]="isReadonly ? '' : 'Select Type'" formControlName=type
                                        [required]="!isReadonly">
                                            <mat-option value="retail">Retail</mat-option>
                                            <mat-option value="mall">Mall</mat-option>
                                            <mat-option value="casino">Casino</mat-option>
                                            <mat-option value="cultural">Cultural</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="tenantForm.controls['type'].hasError('required') && !isReadonly">You must enter a type</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile formGroupName="serviceMaxAccount" class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Service Max Account</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter Service Max Account'" formControlName=id maxlength="30"
                                            [readonly]='isReadonly'>
                                        <mat-error *ngIf="tenantForm.get('serviceMaxAccount.id').hasError('pattern')">Special char. not allowed</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Legal Name</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter legal name here'" formControlName=legalName
                                        [required]="!isReadonly" [readonly]='isReadonly'>
                                        <mat-error *ngIf="tenantForm.controls['legalName'].hasError('required')">You must enter a legal name</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>DUNS Number</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter DUNS number'" formControlName=dunsNumber maxlength="30"
                                            [readonly]='isReadonly'>
                                        <mat-error *ngIf="tenantForm.controls['dunsNumber'].hasError('pattern')">Special char. not allowed</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                      <mat-label>Status</mat-label>
                                      <mat-select [placeholder]="isReadonly ? '' : 'Select status'" formControlName="isActive" [required]="!isReadonly">
                                        <mat-option [value]="true">Active</mat-option>
                                        <mat-option [value]="false">Inactive</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-card-content>
                    </mat-card>
                </section>
                <section>
                    <h2>Keys</h2>
                    <app-keys [isReadonly]="isReadonly" [id]="tenantValue?.id" ></app-keys>
                </section>
                <section>
                    <h2>Location Information</h2>
                    <app-address-validation [isReadonly]='isReadonly' [location]="tenantValue?.location"
                        (locationValidator)="forLocationValidator($event)"></app-address-validation>
                </section>

                <section>
                    <h2>Market Segment</h2>
                    <app-market-segment [readOnly]='isReadonly'></app-market-segment>
                </section>

                <section>
                    <h2>Subscriptions</h2>
                    <app-subscriptions [readOnly]='isReadonly' [isCreateMode]="isCreateMode"></app-subscriptions>
                </section>

            </div>
        </form>
    </div>
</div>
