import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../auth/services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {ToasterService} from '../../../services/toaster.service';

@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss']
})
export class DataMigrationComponent implements OnInit {
  private baseUrl = environment.apigeeLocationUrl;
  @ViewChild('tenantFile') tenantFile: ElementRef;
  @ViewChild('buFile') buFile: ElementRef;

  tenantLoading = false;

  buLoading = false;
  upload = false;

  constructor(private fb: UntypedFormBuilder,
              private httpClient: HttpClient,
              private authService: AuthService,
              private toaster: ToasterService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(query => {
      this.upload = !!query.get('upload') ?? false;
    });
  }

  uploadTenantFile(): void {
    if (!this.tenantFile.nativeElement.files.length) {
      this.toaster.warning('Please select the file first');
      return;
    }
    this.tenantLoading = true;
    const url = `${this.baseUrl}/v1/api/import/tenants?parseOnly=${!this.upload}`;
    const data = new FormData();
    data.append('file', this.tenantFile.nativeElement.files[0]);
    this.httpClient.post(url, data, this.authService.getAuthHttpOptions())
      .subscribe(() => {
          this.toaster.success('Tenant file successfully uploaded');
        },
        err => {
          if (err?.status === 404) {
            this.toaster.error('Data migration is not enabled');
          } else if (err?.error?.message) {
            this.toaster.error(err.error.message);
          } else {
            this.toaster.error('Something went wrong. Please try again.');
          }
        })
      .add(() => {
        this.tenantLoading = false;
      });
  }

  uploadBuFile(): void {
    if (!this.buFile.nativeElement.files.length) {
      this.toaster.warning('Please select the file first');
      return;
    }
    this.buLoading = true;
    const url = `${this.baseUrl}/v1/api/import/business-units?parseOnly=${!this.upload}`;
    const data = new FormData();
    data.append('file', this.buFile.nativeElement.files[0]);
    this.httpClient.post(url, data, this.authService.getAuthHttpOptions())
      .subscribe(() => {
          this.toaster.success('Business unit file successfully uploaded');
        },
        err => {
          if (err?.status === 404) {
            this.toaster.error('Data migration is not enabled');
          } else if (err?.error?.message) {
            this.toaster.error(err.error.message);
          } else {
            this.toaster.error('Something went wrong. Please try again.');
          }
        })
      .add(() => {
        this.buLoading = false;
      });
  }
}
