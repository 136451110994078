import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '../../../services/site.service';
import { ArrayValidators } from '../../../misc/CustomValidators';
import { patchForm } from '../../../misc/Utility';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { ToasterService } from '../../../services/toaster.service';
import { BusinessUnitService } from '../../../services/business-unit.service';
import { dateValidation } from 'src/app/validators/date-field.validators';
import { formatDate } from '@angular/common';
import {AuthService} from '../../../auth/services/auth.service';

@Component({
  selector: 'app-site-editor',
  templateUrl: './site-editor.component.html',
  styleUrls: ['./site-editor.component.scss']
})
export class SiteEditorComponent implements OnInit {

  tenantId: string;
  buId: string;
  siteId: string;
  form: UntypedFormGroup;
  mode: string;
  locationValid = false;
  keys: string[] = [];
  labels: string[] = [];
  loadingCount = 0;
  selectedTabIndex: number;
  rawData: any;
  cascadeSubscriptions = [];
  admin: Boolean = this.authService.getFromCookie("admin") == "true";
  siteOnlySubList = [];

  get viewMode(): boolean {
    return this.mode === 'view';
  }

  get loading(): boolean {
    return this.loadingCount > 0;
  }

  get createMode(): boolean {
    return !this.siteId;
  }

  get isCreateMode(): boolean {
    return !this.siteId
  }

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private siteService: SiteService,
    private buService: BusinessUnitService,
    private toaster: ToasterService,
    private router: Router,
    private businessUnitService: BusinessUnitService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.buId = paramMap.get('buId');
      this.siteId = paramMap.get('siteId');
      this.mode = paramMap.get('mode') ?? (this.siteId ? 'view' : 'edit');
      if (!this.siteId || this.mode !== 'edit') {
        this.initForm();
      }
    });
    this.route.queryParamMap.subscribe(query => {
      this.selectedTabIndex = parseInt(query.get('selectedTabIndex'));
    });
    this.loadKeys();
    this.loadLabels();
    this.loadingCount++;
    this.businessUnitService.getSubscriptionsByParent("BUSINESS_UNIT", this.buId).subscribe(
      (res) => {
        const buSubscriptions = res["subscriptions"];
        buSubscriptions.forEach((subscription) => {
          if (subscription.cascade === "Yes") {
            subscription = {...subscription, cascadedSubscription: true};
            this.cascadeSubscriptions?.push(subscription);
          }
        })
        if (this.siteId && this.mode === 'edit') {

          this.initForm();
          this.form.get('isActive').setValue(true);
        }
        if (!this.siteId && this.cascadeSubscriptions?.length) {
          this.populateCascadeSubscriptions();
        }
      },
      () => {
        this.toaster.error("Please enter a deactivation date in business unit's subscriptions");
      }
    ).add(() => {
      this.loadingCount--;
    })
  }

  populateCascadeSubscriptions() {
    const subscriptions = this.form?.get('subscriptions') as UntypedFormArray;
    subscriptions.clear();
    for (let subscription of this.cascadeSubscriptions) {
      let dummyKey = this.fb.group({
        productCode: [''],
        cascade: [''],
        portfolio: [''],
        activationDate: ['', [dateValidation('deactivationDate')]],
        deactivationDate: ['', [dateValidation('activationDate')]],
        cascadedSubscription: [''],
        editing: [false]
      })
      dummyKey.patchValue(subscription)
      subscriptions?.push(dummyKey);
    }
    for (let index = 0; index < this.cascadeSubscriptions?.length; index++) {
      const control = subscriptions.controls[index] as UntypedFormGroup;
      let disabledCtrl = this.fb.control(true);
      control.setControl('_disabled', disabledCtrl);
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [this.siteId],
      name: ['', [Validators.required]],
      businessUnitId: [this.buId, [Validators.required]],
      dunsNumber: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      customerSiteId: ['', [Validators.required]],
      tenantId: [this.tenantId, [Validators.required]],
      isActive: [true, Validators.required],
      keys: this.fb.array([]),
      labels: this.fb.array([]),
      // location: this.fb.group({}),
      marketSegment: this.fb.array([], [ArrayValidators.minLength(1)]),
      subscriptions: this.fb.array([])
    });

    if (this.siteId) {
      this.loadSiteDetails();
    }
    if (this.createMode) {
      this.prefillFields();
    }
  }

  loadKeys(): void {
    this.siteService.loadKeys()
      .subscribe(res => this.keys = res);
  }

  loadLabels(): void {
    this.businessUnitService.getAllLabelsByBuId(this.buId)
      .subscribe(res => this.labels = res.labels);
  }

  cancel(): void {
    if (this.createMode) {
      this.goToSiteHome();
    } else {
      this.toggleUpdate();
      this.loadSiteDetails();
    }
  }

  OnClickUpdate() {
    this.mode = this.viewMode ? 'edit' : 'view';
    if(this.rawData?.location) {
      this.locationValid = true
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/edit`]);
  }

  toggleUpdate(): void {
    this.mode = this.viewMode ? 'edit' : 'view';
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    // do something based on selected tab index
  }

  saveSite(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toaster.error('Form contains some errors');
      return;
    }
    if (!this.locationValid) {
      this.toaster.error('Please validate the address first');
      return;
    }
    let observable: Observable<any>;
    const data = { ...this.form.value };
    data.keys = data.keys.filter(k => k.name && k.value);
    data.labels = data.labels.filter(k => k.name && k.value);
    data.subscriptions = data.subscriptions.filter(s => !!Object.values(s).find(v => !!v));
    data.subscriptions.forEach(element => {
      element.activationDate = formatDate(element.activationDate, "yyyy-MM-dd", "en-us");
      element.deactivationDate = element.deactivationDate? formatDate(element.deactivationDate, "yyyy-MM-dd", "en-us") : element.deactivationDate;
    });

    if (this.createMode) {
      observable = this.siteService.createSite(data);
    } else {
      observable = this.siteService.updateSite(data);
    }
    this.loadingCount++;
    observable
      .subscribe(res => {
        if (this.createMode) {
          this.toaster.success('Site created successfully.\nID: ' + res.id);
          this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${res.id}/view`]);
        } else {
          this.toaster.success('Site updated successfully.');
          this.toggleUpdate();
          this.loadSiteDetails();
        }
      },
        err => {
          if (err?.error?.message) {
            this.toaster.error(err.error.message.split(',').join('\n'));
          } else {
            this.toaster.error('Something went wrong. Please try again.');
          }
        })
      .add(() => {
        this.loadingCount--;
      });
  }

  resetForm(): void {
    this.form.reset();
    ['keys', 'labels', 'marketSegment', 'subscriptions'].forEach(field => {
      (this.form.get(field) as UntypedFormArray).clear();
    });
  }

  loadSiteDetails(): void {
    this.loadingCount++;
    this.siteService.findById(this.siteId)
      .subscribe(res => {
        this.resetForm();
        res.labels = res?.labels ?? [];
        res.keys = res?.keys ?? []
        this.rawData = res;
        if(this.rawData?.location) {
          this.locationValid = true
        }
        const subscriptions = this.form?.get('subscriptions') as UntypedFormArray;
        subscriptions.clear();
        let rearrangedSubscriptions = [];
        let cascadeProductCodes = this.cascadeSubscriptions.map(a => a.productCode);

        res.subscriptions.forEach((subscription) => {
          if (!cascadeProductCodes.includes(subscription.productCode) &&
            new Date(subscription.deactivationDate) > new Date()) {
            subscription.activationDate = new Date().toJSON().slice(0, 10);
            subscription = {...subscription, editing: [false]};
            this.siteOnlySubList.push(subscription);
            this.cascadeSubscriptions.push(subscription);
          }
        })
        sessionStorage.setItem("labels", JSON.stringify(res.labels.map(i => i.name)));
        sessionStorage.setItem("existingSub", JSON.stringify(this.siteOnlySubList.map(a => a.productCode)));
        const subscriptionsArray1 = this.cascadeSubscriptions.concat(this.subscriptionsRearrangement(this.siteOnlySubList));
        const subscriptionsArray2 = res?.subscriptions;
        rearrangedSubscriptions = subscriptionsArray1?.map(subscription1 => {
          const subscription2 = subscriptionsArray2?.find(subscription => subscription.productCode === subscription1.productCode);
          if (subscription2 && (subscription2.activationDate == subscription1.activationDate)) {
            return { ...subscription1, ...subscription2 };
          }
          return subscription1;
        });

        res = { ...res, subscriptions: rearrangedSubscriptions };
        delete res.floors;
        patchForm(this.form, res);

        if (this.cascadeSubscriptions?.length) {
          for (let index = 0; index < this.cascadeSubscriptions?.length; index++) {
            subscriptions?.at(index)?.get('cascadedSubscription')?.setValue(true);
            const control = subscriptions?.controls[index] as UntypedFormGroup;
            let disabledCtrl = this.fb.control(true);
            control?.setControl('_disabled', disabledCtrl);
          }
        }
        subscriptions.controls.forEach((control) => {
          if (control instanceof UntypedFormGroup) {
            control.controls['activationDate'].setValidators(dateValidation('deactivationDate'));
            control.controls['deactivationDate'].setValidators(dateValidation('activationDate'));
            control.controls['activationDate'].updateValueAndValidity();
            control.controls['deactivationDate'].updateValueAndValidity();
          }
        });
      },
        err => {
          if (err.status === 404) {
            this.toaster.error(err?.error?.message ?? 'Site not found');
          } else {
            this.toaster.error('Something went wrong. Please try again');
          }
        })
      .add(() => {
        this.loadingCount--;
      });
  }

  subscriptionsRearrangement(responseSubscriptions) {
    const results = responseSubscriptions?.filter(({ productCode: value1 }) => !this.cascadeSubscriptions?.some(({ productCode: value2 }) => value2 === value1));
    return results;
  }

  updateLocationValidity(invalid: boolean): void {
    this.locationValid = !invalid;
  }

  goToSiteHome(): void {
    if (this.admin) {
      this.router.navigate([`dashboard/view/${this.tenantId}`]);
    } else {
      this.router.navigate(['/sites']);
    }
  }

  addFloor(): void {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/floors/new`]);
  }

  addDevice(): void {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${this.siteId}/devices/new`],
    { queryParams: { custSiteId: this.form.get('customerSiteId').value ?? "", siteName:  this.form.get('name').value ?? ""}});
  }

  prefillFields(): void {
    this.loadingCount++;
    this.buService.findBusinessUnitsByBuId(this.buId)
      .subscribe(businessUnit => {
        const marketSegmentField = this.form.get('marketSegment') as UntypedFormArray;
        businessUnit.marketSegment?.forEach(i => {
          marketSegmentField?.push(new UntypedFormControl(i));
        });
        sessionStorage.setItem("labels", JSON.stringify([]));
      }, () => {
        this.toaster.error('Something went wrong. Please try again');
      })
      .add(() => this.loadingCount--);
  }
}
