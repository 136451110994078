<mat-card class="mt-4">
    <mat-card-content>
      <div class="loader">
        <app-loader *ngIf="loading" [noText]="true"></app-loader>
      </div>
  
      <table [dataSource]="dataSource" [ngClass]="{loading: loading}" class="w-100 custom-table"
             mat-table matSort matSortActive="dtCreated" matSortDirection="desc" matSortDisableClear>
  
        <ng-container matColumnDef="deviceId">
            <th *matHeaderCellDef mat-header-cell>DEVICE ID</th>
            <td *matCellDef="let element" mat-cell> {{element.id}} </td>
        </ng-container>
  
        <ng-container matColumnDef="deviceName">
            <th *matHeaderCellDef mat-header-cell>DEVICE NAME</th>
            <td *matCellDef="let element" mat-cell> {{element.name}} </td>
        </ng-container>

        <ng-container *ngIf="!parentDeviceId" matColumnDef="parentId">
          <th *matHeaderCellDef mat-header-cell>PARENT ID</th>
          <td *matCellDef="let element" mat-cell> {{element.parentId}} </td>
        </ng-container>
  
        <ng-container matColumnDef="model">
            <th *matHeaderCellDef mat-header-cell>MODEL</th>
            <td *matCellDef="let element" mat-cell> {{element.model}} </td>
        </ng-container>
  
        <ng-container matColumnDef="manufacturer">
            <th *matHeaderCellDef mat-header-cell>MANUFACTURER</th>
            <td *matCellDef="let element" mat-cell> {{element.manufacturer}} </td>
        </ng-container>
  
        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>STATUS</th>
            <td *matCellDef="let element" mat-cell>{{element.isActive ? 'ACTIVE' : 'INACTIVE'}}</td>
        </ng-container>

        <ng-container matColumnDef="zoneName">
            <th *matHeaderCellDef mat-header-cell>ZONE NAME</th>
            <td *matCellDef="let element" mat-cell>{{element.zoneName}}</td>
        </ng-container>

        <ng-container matColumnDef="floorName">
            <th *matHeaderCellDef mat-header-cell>FLOOR NAME</th>
            <td *matCellDef="let element" mat-cell>{{element.floorName}}</td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th *matHeaderCellDef class="text-center" mat-header-cell>ACTION</th>
          <td *matCellDef="let element" mat-cell>
            <div class="w-100 text-center">
              <button (click)="viewDevice(element)" class="mat-elevation-z1 small mr-2" color="primary" mat-mini-fab>
                <mat-icon>visibility</mat-icon>
              </button>
              <button (click)="editDevice(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
                <mat-icon>mode_edit</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
  
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        <tr *matNoDataRow class="mat-row">
          <td [attr.colspan]="displayedColumns.length" class="mat-cell text-center">
            No Devices found
          </td>
        </tr>
      </table>

      <mat-paginator #paginator
                     [pageSize]="20"
                     appCustomPaginator
                     aria-label="Select page">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
  