<div *ngIf="loading" class="loader-container">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <h1>Loading User Information...</h1>
</div>
<div *ngIf="!loading" class="page-container">
    <div class="d-flex info-header">
        <div *ngIf="userId">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="goToUsersHome()" *ngIf="admin || superAdmin">
                <mat-icon>arrow_back</mat-icon>
              {{ this.admin == true ? "Back to Tenant Details":"User Home" }}
            </button>
        </div>
        <div class="ml-3 my-auto">
            <span *ngIf="this.superAdmin || this.admin">TENANT ID:&nbsp;<strong>{{tenantId}}</strong></span>
            <span *ngIf="!this.superAdmin && !this.admin">TENANT NAME: <strong>{{tenantName}}</strong></span>
            <span *ngIf="!userId"> / ADD USER</span>
            <span *ngIf="userId">&nbsp;|&nbsp;User ID:&nbsp;<strong>{{userId}}</strong></span>
        </div>
    </div>
    <mat-tab-group class="card-tabs" [class.hide-tabs]="createMode">
        <mat-tab label="User Info">
            <form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()" class="mb-4" [class.read-only]="isReadonly">
                <div class="text-right mt-4">
                    <ng-container *ngIf="!viewMode">
                        <button (click)="onClickCancel()" class="rounded-pill small mr-4 border border-primary" color="primary"
                            mat-button type="button">
                            CANCEL
                        </button>
                        <button [disabled]="!createMode && (!admin && !superAdmin)" class="rounded-pill small" color="primary" mat-flat-button type="submit">
                            {{userId ? 'UPDATE' : 'CREATE USER'}}
                        </button>
                    </ng-container>
                    <button [disabled]="!createMode && (!admin && !superAdmin)" (click)="OnClickUpdate()" *ngIf="viewMode" class="rounded-pill small" color="primary"
                        mat-flat-button type="button">
                        UPDATE
                    </button>
                </div>
                <section>
                    <h2>General Information</h2>
                    <mat-card>
                        <mat-card-content>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>First Name</mat-label>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter First Name'" formControlName="firstName"
                                            [required]="!isReadonly" [readonly]='isReadonly' [matTooltip]="!isCreateMode ? userForm.controls['firstName'].value : ''">
                                        <mat-error *ngIf="!isReadonly && userForm.controls['firstName'].hasError('required')">You must enter First Name</mat-error>
                                        <mat-error *ngIf="!isReadonly && userForm.controls['firstName'].hasError('pattern')">Enter valid First Name</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Last Name'" formControlName="lastName"
                                            [required]="!isReadonly" [readonly]='isReadonly' [matTooltip]="!isCreateMode ? userForm.controls['lastName'].value : ''">
                                        <mat-error *ngIf="!isReadonly && userForm.controls['lastName'].hasError('required')">You must enter Last Name</mat-error>
                                        <mat-error *ngIf="!isReadonly && userForm.controls['lastName'].hasError('pattern')">Enter valid Last Name</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>User Name</mat-label>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter User Name'" formControlName="userName"
                                            [required]="isCreateMode" [readonly]='!isCreateMode' [ngClass]="{'greyed-out': (!isCreateMode && !isReadonly)}" [matTooltip]="!isCreateMode ? userForm.controls['userName'].value : ''">
                                        <mat-error *ngIf="!isReadonly && userForm.controls['userName'].hasError('required')">You must enter a User Name</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Email</mat-label>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Email ID'" formControlName="emailId"
                                            [required]="isCreateMode" [readonly]='!isCreateMode' [ngClass]="{'greyed-out': (!isCreateMode && !isReadonly)}" [matTooltip]="!isCreateMode ? userForm.controls['emailId'].value : ''">
                                        <mat-error *ngIf="!isReadonly && userForm.controls['emailId'].hasError('required')">You must enter an Email Id</mat-error>
                                        <mat-error *ngIf="!isReadonly && userForm.controls['emailId'].hasError('pattern')">Enter valid email address</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-card-content>
                    </mat-card>
                </section>
                <section *ngIf="!viewMode">
                    <h2>Password</h2>
                    <app-user-password [isReadOnly]='isReadonly' [isCreateMode]="isCreateMode"></app-user-password>
                </section>
                <section>
                    <h2>User Roles</h2>
                    <app-user-roles [isReadOnly]='isReadonly' [isCreateMode]="isCreateMode" [claimsFromResponse]="this.userValue?.claims"></app-user-roles>
                </section>

                <section *ngIf="buList">
                    <h2>Access</h2>
                    <app-user-access [isReadonly]='isReadonly' [isCreateMode]="isCreateMode" [tenantId]="tenantId" [buList]="buList"></app-user-access>
                </section>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>
