<mat-card>
  <mat-card-content *ngIf="form" [formGroup]="form">
    <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small">
          <mat-label>Name</mat-label>
          <input
            matInput
            type="text"
            placeholder="Enter Name Here"
            formControlName="name"
            required
            maxlength="100"
            minlength="4"
            [readonly]="readOnly"
          />
          <mat-error *ngIf="form.controls['name'].hasError('minlength') && !isReadonly">Name must be 4-100 chars</mat-error>
          <mat-error *ngIf="form.controls['name'].hasError('required') && !isReadonly">Please enter a floor name</mat-error>
          <mat-error *ngIf="form.controls['name'].hasError('maxlength') && !isReadonly">Name must be 4-100 chars</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small" [class.read-only]="readOnly">
          <mat-label>Floor Level</mat-label>
          <mat-select placeholder="Enter Floor Level here" formControlName="zIndex" required [disabled]="readOnly">
            <mat-option *ngFor="let i of floorPlan" [value]="i">
              {{i}}
            </mat-option>
          </mat-select>        
          <!-- <input
            matInput
            type="number"
            placeholder="Enter Floor Level here"
            formControlName="zIndex"
            required
            [readonly]="readOnly"
          /> -->
          <mat-error *ngIf="form.controls['zIndex'].hasError('required') && !isReadonly">Please enter a floor level</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field
          floatLabel="always"
          class="w-100 small"
          [class.read-only]="readOnly"
        >
          <mat-label>Status</mat-label>
          <mat-select
            placeholder="Select Status"
            formControlName="isActive"
            required
            [disabled]="readOnly"
          >
            <mat-option [value]="true">Active</mat-option>
            <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="4">
        <mat-form-field floatLabel="always" class="w-100 small" [class.read-only]="readOnly">
          <mat-label *ngIf="!readOnly">Site ID</mat-label>
          <span *ngIf="readOnly" class="w-100 small">
            <mat-label>Site ID</mat-label>
            <mat-icon
              (click)="showToast('Site ID')"
              *ngIf="readOnly"
              matRipple
              matSuffix
              matRippleColor="white"
              [cdkCopyToClipboard]="this.form.get('siteId').value"
              >content_copy</mat-icon
            >
          </span>
          <input
            matInput
            type="text"
            placeholder="Enter Site ID"
            formControlName="siteId"
            required
            [readonly]="true"
          />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
