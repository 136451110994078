import {CustomDataSource, Page} from './CustomDataSource';
import {AuthService} from '../auth/services/auth.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {BusinessUnitDataSource} from './BusinessUnitDataSource';

export class UserDataSource extends CustomDataSource<User> {

  admin: Boolean = this.authService.getFromCookie("admin") == "true";
  superAdmin = this.authService.getFromCookie("superAdmin") == "true";
  tenantIdSso = this.authService.getTenantIdFromCookie();

  constructor(private authService: AuthService, private userService: UserService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string): Observable<Page<User>> {
    let observable: Observable<any>;
    if (this.superAdmin) {
      let selectedTenantId = this.authService.getFromCookie("selectedTenantId");
      if (selectedTenantId) {
        observable = this.userService.findAllUsersByTenant(selectedTenantId);
      }
    } else {
      observable = this.userService.findAllUsersByTenant(this.tenantIdSso);
    }

    if (search) {
      let length: number;
      let searchArray: [];
      observable.subscribe(res => {
        searchArray = res.filter((user) => {
          if (user.name !== "" && user.name) {
            let name =  user.name.toLowerCase();
            search = search.toLowerCase();
            return name.includes(search);
          }
        });
        length = searchArray.length;
      });
      return (observable.pipe(map(res => ({data: searchArray, total: length}))));
    } else {
      return observable.pipe(map(res => ({data: res, total: res.total})));
    }
  }
}
export interface User {
  id: string;
  name: string;
  username: string;
  email: string;
  userRole: string;
  enabled: boolean;
  lastLogin: string;
}
