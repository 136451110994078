import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss']
})
export class SearchCardComponent implements OnInit {

  @Input()
  placeholder: string;

  @Input()
  label: string;

  @Input()
  filterLabel: string;

  @Input()
  filterBtn: boolean = false;

  @Output()
  filter = new EventEmitter<boolean>();

  @Output()
  search = new EventEmitter<string>();

  inputCtrl = new UntypedFormControl();

  filterClicked = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  triggerSearch(): void {
    this.filterClicked = false;
    this.search.emit(this.inputCtrl.value);
  }

  clearSearch(): void {
    this.inputCtrl.setValue(null);
    this.triggerSearch();
  }

  triggerFilter() {
    this.inputCtrl.setValue(null);
    this.filterClicked = !this.filterClicked
    this.filter.emit(this.filterClicked)
  }

}
