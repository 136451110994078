<div class="page-container">
    <h1 class="page-title text-uppercase">Devices</h1>
  
    <app-search-card placeholder="Enter Device Details" (search)="onSearch($event)" [filterBtn]="true" (filter)="onFilter($event)">
    </app-search-card>
  
    <mat-card class="mt-4">
      <mat-card-title class="card-title text-uppercase">
        Recently Added Devices
      </mat-card-title>
      <mat-card-content>
        <div class="loader">
          <app-loader *ngIf="dataSource.loading$ | async" [noText]="true"></app-loader>
        </div>
  
        <table [dataSource]="dataSource" [ngClass]="{loading: dataSource.loading$ | async}" class="w-100 custom-table"
               mat-table matSort matSortActive="dtCreated" matSortDirection="desc" matSortDisableClear>
  
          <ng-container matColumnDef="deviceId">
            <th mat-header-cell *matHeaderCellDef>DEVICE ID</th>
            <td mat-cell class="truncate-cell" *matCellDef="let element" [matTooltip]="element.id"> {{element.id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="deviceName">
            <th mat-header-cell *matHeaderCellDef >DEVICE NAME</th>
            <td mat-cell class="truncate-cell" *matCellDef="let element" [matTooltip]="element.name"> {{element.name}} </td>
          </ng-container>
  
          <!-- <ng-container matColumnDef="parentId" *ngIf="!parentDeviceId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PARENT ID</th>
            <td mat-cell *matCellDef="let element" > {{element.parentId}} </td>
          </ng-container> -->

          <ng-container matColumnDef="model">
            <th *matHeaderCellDef mat-header-cell>MODEL</th>
            <td *matCellDef="let element" mat-cell> {{element.model}} </td>
        </ng-container>
  
        <ng-container matColumnDef="manufacturer">
            <th *matHeaderCellDef mat-header-cell>MANUFACTURER</th>
            <td *matCellDef="let element" mat-cell> {{element.manufacturer}} </td>
        </ng-container>
  
        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>STATUS</th>
            <td *matCellDef="let element" mat-cell>{{element.isActive ? 'ACTIVE' : 'INACTIVE'}}</td>
        </ng-container>

        <ng-container matColumnDef="siteName">
          <th *matHeaderCellDef mat-header-cell>SITE NAME</th>
          <td mat-cell class="truncate-cell" *matCellDef="let element" [matTooltip]="element.siteName">{{element.siteName}}</td>
        </ng-container>
  
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">ACTION</th>
            <td mat-cell *matCellDef="let element">
              <div class="w-100 text-center">
                <button (click)="viewSite(element)" class="mat-elevation-z1 small mr-2" color="primary"
                        mat-mini-fab>
                  <mat-icon>visibility</mat-icon>
                </button>
                <button (click)="editSite(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab>
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
              No devices found
            </td>
          </tr>
        </table>
        <mat-paginator #paginator
                       [pageSize]="20"
                       appCustomPaginator
                       aria-label="Select page">
        </mat-paginator>
  
      </mat-card-content>
    </mat-card>
  
  </div>
  