import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../auth/services/auth.service';
import {Observable} from 'rxjs';
import {REST_ENDPOINT} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private baseUrl = environment.apigeeLocationUrl;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  createSite(site): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.create}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (site.location[objName]) {
        delete site.location[objName];
      }
    });

    return this.httpClient.post(url, site, this.authService.getAuthHttpOptions());
  }

  updateSite(site): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.update}`;
    const objectsToRemove = ['cityObj', 'stateObj', 'countryObj'];
    objectsToRemove.forEach((objName) => {
      if (site.location[objName]) {
        delete site.location[objName];
      }
    });
    
    return this.httpClient.post(url, site, this.authService.getAuthHttpOptions());
  }

  findAllSites(page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.findAll}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  searchSites(search: string, page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.search}?search=${search}&page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  findById(siteId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.findById}/${siteId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  loadKeys(): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.findKeys}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  loadLabels(): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.findLabels}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  findAllSitesByBu(buId: string, page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc'): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.findByBu}${buId}?page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  uploadCSV(buId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('businessUnitId', buId)
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.uploadCSV}`;
    return this.httpClient.post(url, formData, this.authService.getAuthHttpOptions());
  }

  getUploadHistory(buId: string, page: number = 0, size: number = 20, sort: string = 'createdAt', sortDir: string = 'desc') {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.uploadHistory}?buId=${buId}&page=${page}&size=${size}&sort=${sort},${sortDir}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  downloadCSV(fileId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.sites.downloadCSV}?fileId=${fileId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }
}
