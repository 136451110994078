<h2 class="mat-dialog-title" mat-dialog-title>Create Super Admin</h2>
<mat-dialog-content class="mat-typography super-admin-content">

     <!-- loading indicator -->
     <div *ngIf="disabled" class="loader-container">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h1>Creating Super Admin...</h1>
    </div>

    <!-- Error Message message -->
    <div *ngIf="errorMessage" class="error-message-block">
        <span class="text-danger">{{ errorMessage }}</span>
    </div>


    <form class="w-100" [formGroup]="superAdminForm" (submit)="createSuperAdmin()">
        <div class="w-100">
            <mat-form-field>
                <mat-label>username</mat-label>
                <input matInput placeholder="Enter Username" formControlName="username">
                <mat-error *ngIf="superAdminForm.controls['username'].hasError('required')">You must enter username</mat-error>
            </mat-form-field>
        </div>    
        <div class="w-100">
            <mat-form-field>
                <mat-label>Email id</mat-label>
                <input matInput placeholder="Enter User Email ID" formControlName="email">
                <mat-error *ngIf="superAdminForm.controls['email'].hasError('required')">You must enter a email id</mat-error>
                <mat-error *ngIf="superAdminForm.controls['email'].hasError('email')">Enter valid email address</mat-error>
                <mat-error *ngIf="superAdminForm.controls['email'].hasError('emailDomain')">Enter Valid domain email</mat-error>
                <mat-hint>Please enter email address belongs to @shoppertrak or @jci</mat-hint>
            </mat-form-field>
        </div>
        <div class="btn-action-block my-2">
            <button type="button" mat-button mat-dialog-close class="btn-cancel" color="primary" mat-stroked-button
                [disabled]="disabled">Cancel</button>
            <button mat-button class="btn-create" [disabled]="disabled" mat-raised-button color="primary"
                cdkFocusInitial>Create</button>
        </div>
    </form>
</mat-dialog-content>