import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteService } from '../../../services/site.service';
import { ToasterService } from '../../../services/toaster.service';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input()
    placeholder: string;

    fileName: string = "";
    selectedFile: File;
    loading: boolean = false;

    @Input()
    buId: string;

    @Input()
    uploadCSV: () => void;

    @Output()
    onFileSelected = new EventEmitter<File>();

    constructor(private spinner: NgxSpinnerService, 
        private toaster: ToasterService,
        private siteService: SiteService,
        private route: ActivatedRoute,
        private router: Router){

    }

    ngOnInit(): void {
    }

    clear() {
        this.selectedFile = null;
        this.fileName = null;
        this.onFileSelected.emit(null)
    }

    fileSelected(ev) {
        this.selectedFile = ev.target.files[0]
        this.fileName = this.selectedFile.name
        this.onFileSelected.emit(this.selectedFile)
    }
}
