import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import { Site, SitesDataSource } from '../../../misc/SitesDataSource';
import {SiteService} from '../../../services/site.service';

@Component({
  selector: 'app-sites-overview',
  templateUrl: './sites-overview.component.html',
  styleUrls: ['./sites-overview.component.scss']
})
export class SitesOverviewComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [ 'id', 'name', 'tenantId', 'buId', 'createdAt', 'isActive', 'action'];
  dataSource: SitesDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private siteService: SiteService) {
    this.dataSource = new SitesDataSource(siteService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort);
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }

  editSite(site: Site): void {
    this.router.navigate([`tenants/${site.tenantId}/business-units/${site.businessUnitId}/sites/${site.id}/edit`]);
  }

  viewSite(site: Site): void {
    this.router.navigate([`tenants/${site.tenantId}/business-units/${site.businessUnitId}/sites/${site.id}/view`]);
  }

}
