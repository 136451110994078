<mat-card>
    <mat-card-content *ngIf="form" [formGroup]="form">
        <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" [placeholder]=" isReadonly ? '' : 'Enter Name'" formControlName="name"
                        [required]="!isReadonly" [readonly]="isReadonly" minlength="4" maxlength="100">
                        <mat-error *ngIf="form.controls['name'].hasError('minlength') && !isReadonly">Name must be 4-100 chars</mat-error>
                        <mat-error *ngIf="form.controls['name'].hasError('maxlength') && !isReadonly">Name must be 4-100 chars</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label>Abbreviation</mat-label>
                    <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Abbreviation'"
                        formControlName="abbreviation" [required]="!isReadonly" [readonly]="isReadonly">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label>Status</mat-label>
                    <mat-select matInput [placeholder]="isReadonly ? '' : 'Select Status'" formControlName="isActive"
                        [required]="!isReadonly" [readonly]="isReadonly" [disabled]="isReadonly">
                        <mat-option [value]="true">Active</mat-option>
                        <mat-option [value]="false">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
            <mat-grid-tile [colspan]="4" *ngIf="parentId">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label *ngIf="!isReadonly">Parent Zone</mat-label>
                    <span *ngIf="isReadonly" class="w-100 small">
                        <mat-label>Parent Zone</mat-label>
                        <mat-icon
                          (click)="showToast('Parent ID')"
                          *ngIf="isReadonly"
                          matRipple
                          matSuffix
                          matRippleColor="white"
                          [cdkCopyToClipboard]="this.form.get('parentId').value"
                          >content_copy</mat-icon
                        >
                      </span>
                    <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter parent zone'"
                        formControlName="parentId" [readonly]="true">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label *ngIf="!isReadonly">Floor Id</mat-label>
                    <span *ngIf="isReadonly" class="w-100 small">
                        <mat-label>Floor Id</mat-label>
                        <mat-icon
                          (click)="showToast('Floor Id')"
                          *ngIf="isReadonly"
                          matRipple
                          matSuffix
                          matRippleColor="white"
                          [cdkCopyToClipboard]="this.form.get('floorId').value"
                          >content_copy</mat-icon
                        >
                      </span>
                    <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Floor id'"
                        formControlName="floorId" [required]="!isReadonly" [readonly]="true">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label *ngIf="!isReadonly">Site Id</mat-label>
                    <span *ngIf="isReadonly" class="w-100 small">
                        <mat-label>Site Id</mat-label>
                        <mat-icon
                          (click)="showToast('Site Id')"
                          *ngIf="isReadonly"
                          matRipple
                          matSuffix
                          matRippleColor="white"
                          [cdkCopyToClipboard]="this.form.get('siteId').value"
                          >content_copy</mat-icon
                        >
                      </span>
                    <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Site id'" formControlName="siteId"
                        [required]="!isReadonly" [readonly]="true">
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="12" gutterSize="16px" rowHeight="72px">
            <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label>Zone Type</mat-label>
                    <mat-select matInput [placeholder]="isReadonly ? '' : 'Select zone type'" formControlName="zoneType"
                        [required]="!isReadonly" [readonly]="isReadonly" [disabled]="isReadonly">
                        <mat-option value="area">Area</mat-option>
                        <mat-option value="entrance">Entrance</mat-option>
                        <mat-option value="entranceZone">Entrance Zone</mat-option>
                        <mat-option value="shelf">Shelf</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="8">
                <mat-form-field floatLabel="always" [class]='!isReadonly ? "w-100 small" : "w-100 small mt-5"'>
                    <mat-label>Description</mat-label>
                    <textarea matInput [rows]="isReadonly ? 3 : 1" [placeholder]="isReadonly ? '' : 'Enter Description' " formControlName="description"
                        [readonly]="isReadonly"></textarea>
                </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile [colspan]="4">
                <mat-form-field floatLabel="always" class="w-100 small">
                    <mat-label>Status</mat-label>
                    <mat-select matInput [placeholder]="isReadonly ? '' : 'Select Status'" formControlName="isActive"
                        [required]="!isReadonly" [readonly]="isReadonly" [disabled]="isReadonly">
                        <mat-option [value]="true">Active</mat-option>
                        <mat-option [value]="false">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile> -->
        </mat-grid-list>
    </mat-card-content>
</mat-card>