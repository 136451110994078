import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {

  @Input() isCreateMode;
  @Input() isReadOnly;

  password: string;
  confirmPassword: string;


  constructor(private fb: UntypedFormBuilder, private readonly formGroupDirective: FormGroupDirective) {}

  get form(): UntypedFormGroup {
    return this.formGroupDirective.form as UntypedFormGroup;
  }
  
  ngOnInit() {
  }

  onKeyUp(event, controlName: string): void {
    this.form.get(controlName).setValue(event.target.value);
    if(controlName == "password") {
      this.form.get("confirmPassword").setValue(this.form.get("confirmPassword").value);
    }
    if(controlName == "confirmPassword") {
      this.form.get("password").setValue(this.form.get("password").value);
    }
  }
}
