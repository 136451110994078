import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as countrycitystatejson from 'countrycitystatejson';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, State, City }  from 'country-state-city';
import { AuthService } from '../auth/services/auth.service';
import {REST_ENDPOINT} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  apiKey=environment.ValidateAddressApiKey;
  constructor(private httpClient:HttpClient, private authService: AuthService) { }
  validateAddressUrl= environment.validateAddressUrl;
  private baseUrl = environment.apigeeLocationUrl;

  private countryData = countrycitystatejson;

  getCountries() {
    // return this.countryData.getCountries();
    return Country.getAllCountries();
  }

  getStatesByCountry(country) {
    return State.getStatesOfCountry(country?.isoCode)
    // return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country, state) {
    return City.getCitiesOfState(country?.isoCode, state?.isoCode);
    // return this.countryData.getCities(country, state);
  }

  getLocationService(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.longitude })
      })
    })
  }

  getAllCountries() {
    const url = `${this.baseUrl}${REST_ENDPOINT.locationInformation.getAllCountries}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  getAllStatesByCountry(country) {
    const url = `${this.baseUrl}${REST_ENDPOINT.locationInformation.getAllStatesByCountryCode}${country?.iso2}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  getAllCitiesByStateAndCountry(country, state) {
    const url = `${this.baseUrl}${REST_ENDPOINT.locationInformation.getAllCitiesByCountryCodeAndStateCode}${country?.iso2}/${state?.iso2}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  validateAddress(payload):Observable<any> {
    const url= `${this.validateAddressUrl}?key=${this.apiKey}`;
    return this.httpClient.post(url,payload);
  }

  getTimezone(isoCode): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.address.get}${isoCode}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }
}
