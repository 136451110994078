import { Observable } from 'rxjs';
import { CustomDataSource, Page } from './CustomDataSource';
import { map } from 'rxjs/operators';
import { SiteService } from '../services/site.service';

export class SitesDataSource extends CustomDataSource<Site> {

  constructor(private siteService: SiteService) {
    super();
  }

  loadData(search: string, page: number, size: number, sortField: string, sortDir: string, options: any): Observable<Page<Site>> {
    let observable: Observable<any>;
    if (options?.buId) {
      observable = this.siteService.findAllSitesByBu(options.buId, page, size, sortField, sortDir);
    } else {
      if (search) {
        observable = this.siteService.searchSites(search, page, size, sortField, sortDir);
      } else {
        observable = this.siteService.findAllSites(page, size, sortField, sortDir);
      }
    }
    return observable.pipe(map(res => ({ data: res.sites, total: res.total })));
  }
}

export interface Site {
  tenantId: string;
  businessUnitId: string;
  name: string;
  id: string;
  createdAt: Date;
  isActive: boolean;
}
