import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { KeysService } from 'src/app/services/keys.service';
import { FormPart } from '../../tenants/create-tenant/create-tenant.component';

@Component({
  selector: 'app-keys',
  templateUrl: './keys.component.html',
  styleUrls: ['./keys.component.scss'],
  viewProviders: [FormPart]
})
export class KeysComponent implements OnInit {
  key: UntypedFormGroup;
  options: any[] = [];
  filteredOptions: Observable<any>;
  @Input() isReadonly;
  @Input() mode;
  @Input() id;
  viewMode: Boolean = false;
  constructor(private readonly formGroupDirective: FormGroupDirective,
    private _fb: UntypedFormBuilder,
    private keysService: KeysService,
    private router: Router
  ) { }

  get keys(): UntypedFormArray {
    return this.formGroupDirective.form?.get('keys') as UntypedFormArray;
  }

  ngOnInit(): void {
    if (this.isReadonly || this.mode == 'view') {
      this.viewMode = true
    }
    this.getKeys();
    this.inItForm();
  }

  filterKeys(value: string) {
    this.filteredOptions = this._filter(value || '');
  }

  inItForm() {
    this.key = this._fb.group({
      name: [''],
      value: [''],
    })
    if (this.viewMode) {
      if (this.key.value.length != null) {
        this.keys?.push(this.key);
      }
    }
    else {
      // this.keys?.push(this.key);
      if (!this.id) {
        this.keys?.push(this.key);
      } else if(this.id && !this.keys.length) {
        this.keys?.push(this.key);
      }
    }
  }

  addKeys() {
    this.key = this._fb.group({
      name: [''],
      value: [''],
    })
    this.keys?.push(this.key);
  }

  private _filter(value: string) {
    const filterValue = value;
    return of(this.options.filter(option => option.includes(filterValue)));
  }
  removeSubscription(index: number): void {
    this.keys.removeAt(index);
  }

  getKeys() {
    this.keysService.getKeys().subscribe(res => {
      this.options = res;
      this.filteredOptions = of(res);
    })
  }
}