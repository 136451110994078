import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-labels-sites',
  templateUrl: './labels-sites.component.html',
  styleUrls: ['./labels-sites.component.scss']
})
export class LabelsSitesComponent implements OnInit {

  @Input() readOnly;
  @Input() field;
  @Input() labelsFromBu;
  placeHolder = "Select a name";
  filteredOptions = [];

  constructor(private formGroupDirective: FormGroupDirective, private fb: UntypedFormBuilder) {
  }

  get labels(): UntypedFormArray {
    return (this.formGroupDirective.form?.get(this.field) as UntypedFormArray) ?? this.fb.array([]);
  }

  ngOnInit(): void {
    if (!this.readOnly && !this.labels?.length) {
      this.addLabel();
    }
  }

  addLabel(): void {
    const labelObj = this.fb.group({
      name: [''],
      value: [''],
    });
    this.labels.push(labelObj);
  }

  removeLabel(index: number): void {
    this.labels.removeAt(index);
    sessionStorage.setItem("labels", JSON.stringify(this.labels.value.map(i => i.name)));
    this.placeHolder = "Select a name";
  }

  getValuesByLabel(labelName: string) : any{
    const selectedObj = this.labelsFromBu?.find(obj => obj?.name === labelName);
    return selectedObj ? selectedObj?.values : [];
  }

  filterNames(value) {
    this.filteredOptions = this.filteredOptions.filter(label => label !== value);
    let existingLabels = JSON.parse(sessionStorage.getItem("labels"));
    existingLabels.push(value);
    sessionStorage.setItem("labels", JSON.stringify(existingLabels));
    if (this.filteredOptions.length == 0) {
      this.placeHolder = "No names available.";
    }
  }

  getAllLabelNames(): any {
    let existingLabels = JSON.parse(sessionStorage.getItem("labels"));
    let labelOptions = this.labelsFromBu?.map(item => item?.name);
    if (existingLabels.length != 0) {
      this.filteredOptions = labelOptions.filter(function(el) {
        return !existingLabels.includes(el);
      });
    } else {
      this.filteredOptions = labelOptions;
    }

    if (this.filteredOptions.length == 0 || labelOptions.length == 0) {
      this.placeHolder = "No names available.";
    }

    return labelOptions;
  }
}
