import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable, of} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

export abstract class CustomDataSource<T> extends DataSource<T> {

  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);

  public loading$ = this.loadingSubject.asObservable();
  private filter: string;
  private paginator: MatPaginator;
  private sort: MatSort;
  private options: any;

  get search(): string {
    return this.filter;
  }

  set search(filter: string) {
    this.filter = filter;
    this.options = {filter: false};
    if (this.paginator.pageIndex === 0) {
      this.loadPage();
    } else {
      this.paginator.firstPage();
    }
  }

  get deviceFilter(): boolean {
    return this.options?.filter;
  }

  set deviceFilter(opt: boolean) {
    this.filter = null;
    this.options.filter = opt;
    if (this.paginator.pageIndex === 0) {
      this.loadPage();
    } else {
      this.paginator.firstPage();
    }
  }

  protected constructor() {
    super();
  }

  public init(paginator: MatPaginator, sort: MatSort, options?: any): void {
    this.paginator = paginator;
    this.sort = sort;
    this.options = {filter: false};
    this.options = options
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadPage())
      )
      .subscribe();
    this.loadPage();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadPage(): void {
    this.loadingSubject.next(true);
    this.loadData(this.filter, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.options)
      .pipe(
        catchError(() => of({data: [], total: 0})),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(page => {
        this.dataSubject.next(page.data);
        this.paginator.length = page.total;
      });
  }

  abstract loadData(search: string, page: number, size: number, sortField: string, sortDir: string, options?: any): Observable<Page<T>>;
}

export interface Page<T> {
  data: T[];
  total: number;
}
