import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-add-org',
  templateUrl: './add-org.component.html',
  styleUrls: ['./add-org.component.scss']
})
export class AddOrgComponent implements OnInit {

  orgForm: UntypedFormGroup;
  disabled = false;
  errorMessage: string;
  tenantName = '';
  tenantId = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddOrgComponent>,
  private tenantService: TenantService) {
    this.tenantName = this.data.tenantName;
    this.tenantId = this.data.tenantId;
   }

  ngOnInit(): void {
    this.orgForm = new UntypedFormGroup({
      orgId: new UntypedFormControl('', [
        Validators.required
      ])
    });
  }

  addOrg() {
    if(this.orgForm.valid) {
       
        const request = {
          buOrgId: this.orgForm.value.orgId,
          tenantName: this.tenantName,
          tenantId: this.tenantId
        };
        this.disabled = true;
        this.tenantService.addOrgForTenant(request).subscribe(
          (res) => {
            this.disabled = false;
            this.orgForm.reset();
            // close the dialog
            this.dialogRef.close(true);
          },
          (err) => {
            this.errorMessage = err.error.apierror ? err.error.apierror.message 
            :  'Server error please try again';
            this.disabled = false;
            this.orgForm.enable();
          }
        )
    }
  }

}
