<div class="page-container">
    <span class="page-title">Users</span>
    <span class="button">
      <button class="rounded-pill small" color="primary" mat-flat-button (click)="onClickAdd()">
        <mat-icon>add</mat-icon> Create User
      </button>
    </span>
    <app-search-card placeholder="Enter User Details" (search)="onSearch($event)">
    </app-search-card>
    <mat-card class="mt-4">
      <mat-card-content>
        <table [dataSource]="dataSource" class="w-100 custom-table" mat-table matSort matSortActive="createdAt"
          matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>NAME</th>
            <td mat-cell class="wrap-text" *matCellDef="let element" [matTooltip]="element.name"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>USERNAME</th>
            <td mat-cell class="wrap-text" *matCellDef="let element" [matTooltip]="element.uid"> {{element.uid}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>EMAIL</th>
            <td mat-cell class="wrap-text" *matCellDef="let element" [matTooltip]="element.email"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="userRole">
            <th mat-header-cell *matHeaderCellDef>USER ROLE</th>
            <td mat-cell class="wrap-text" *matCellDef="let element" [matTooltip]="getRoleFromClaims(element?.claims)?.join(', ')"> {{getRoleFromClaims(element?.claims)?.join(', ')}} </td>
          </ng-container>

          <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef>LAST LOGIN</th>
            <td mat-cell *matCellDef="let element"> {{element.lastLogin ? (element.lastLogin | date : 'MM/dd/yyyy') : 'NULL'}} </td>
          </ng-container>

          <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef>APPS</th>
            <td mat-cell class="wrap-text" *matCellDef="let element" [matTooltip]="(element?.claims?.apps?.length>0) ? element?.claims?.apps.join(', ') : ''"> {{(element?.claims?.apps?.length>0) ? element?.claims?.apps.join(', ') : ''}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">ACTION</th>
            <td mat-cell *matCellDef="let element">
              <div class="w-100 text-center">
                <button (click)="viewTenantsUser(element)" class="mat-elevation-z1 small mr-2" color="primary" mat-mini-fab>
                  <mat-icon>visibility</mat-icon>
                </button>
<!--                <button (click)="editTenantsUser(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab [disabled]="!element.claims?.apps?.includes('shrinkanalyzer')">-->
                <button (click)="editTenantsUser(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab [disabled]="!admin && !superAdmin">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
              No Users found
            </td>
          </tr>
        </table>
        <mat-paginator #paginator [pageSize]="20" appCustomPaginator aria-label="Select page">
        </mat-paginator>

      </mat-card-content>
    </mat-card>
</div>
