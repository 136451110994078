import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import {ToasterService} from '../../../services/toaster.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {

  optionForm: UntypedFormGroup;
  loadingCount = 0;
  @Input() tenantId;
  @Input() isCreateMode;
  @Input() isReadonly;
  @Input() buList;


  constructor(private fb: UntypedFormBuilder, private readonly formGroupDirective: FormGroupDirective, private businessUnitService: BusinessUnitService, private toaster: ToasterService) {
   }

  ngOnInit(): void {
    if(this.isCreateMode) {
      this.initForm()
    }
  }

  get userAccesses(): UntypedFormArray {
    return (this.formGroupDirective.form?.get('userAccesses') as UntypedFormArray) ?? this.fb.array([]);
  }

  initForm() {
      this.buList.forEach((option) => {
        this.addUserAccess(option);
      });
  }

  addUserAccess(option: any) {
    const userAccess = this.fb.group({
      buName: [option?.name],
      buId: [option?.id],
      enable: [false],
    },);
    this.userAccesses.push(userAccess);
  }
}
