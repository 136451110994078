import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.scss']
})
export class DialogBodyComponent implements OnInit {
  unconfirmedValues:Boolean;
  missingValues:Boolean
  replacedComponents: boolean;
  unsupportedCountry: boolean;

  constructor( public dialogRef:MatDialogRef<DialogBodyComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
    if (this.data.status === 400) {
      this.unsupportedCountry = true;
    } else {
      if(this.data.result.verdict?.hasUnconfirmedComponents){
        this.unconfirmedValues=true;
      }
      if(this.data.result.address?.missingComponentTypes?.length > 0){
        this.missingValues=true;
      }
      if(!this.missingValues && !this.unconfirmedValues) {
        this.replacedComponents = true;
      }
    }
  }

  override(){
    this.dialogRef.close("override");
  }

  verify(){
    let text = this.replacedComponents ? {"address": this.data.result.address.formattedAddress} : "verify"
    this.dialogRef.close(text);
  }

  getMapping(key) {
    const mapping = {
      "postal_code": "Zip",
      "locality": "City",
      "country": "Country",
      "administrative_area_level_1": "State",
      "street_number": "Street Number",
      "route": "Street",
    }
    if (mapping[key]) {
      return mapping[key]
    } else {
      const element = document.getElementById(key);
      element.style.display = 'none';
    }
  }

}
