import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { REST_ENDPOINT } from '../app.constants';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  private baseUrl = environment.apigeeLocationUrl;

  constructor(private httpClient : HttpClient, private authService : AuthService) { }

  loadKeys(): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.findKeys}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptions());
  }

  createZone(payload) : Observable<any>{
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.add}`
    return this.httpClient.post(url, payload, this.authService.getAuthHttpOptions());
  }

  findZoneByZoneId(zoneId, floorId, siteId) : Observable<any>{
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.findById}`
    let params = new HttpParams();
    params = params.append('siteId', siteId);
    params = params.append('zoneId', zoneId);
    params = params.append('floorId', floorId);
    const options = {...this.authService.getAuthHttpOptions(), params: params}
    return this.httpClient.get(url, options);
  }

  updateZone(zone): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.update}`;
    return this.httpClient.post(url, zone, this.authService.getAuthHttpOptions());
  }

  findZonesByFloorId(floorId, siteId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.findByFloorId}`;
    let params = new HttpParams();
    params = params.append('siteId', siteId);
    params = params.append('floorId', floorId);
    const options = {...this.authService.getAuthHttpOptions(), params: params}
    return this.httpClient.get(url, options);
  }

  findChildZonesByZoneId(zoneId, siteId): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.zones.findChildZoneByParentId}`;
    let params = new HttpParams();
    params = params.append('parentId', zoneId);
    params = params.append('siteId', siteId);
    const options = {...this.authService.getAuthHttpOptions(), params: params}
    return this.httpClient.get(url, options);
  }
}
