import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuperAdminService } from 'src/app/services/super-admin.service';
import { validateEmailDomain } from 'src/app/validators/email-domain.validators';

@Component({
  selector: 'app-create-super-admin',
  templateUrl: './create-super-admin.component.html',
  styleUrls: ['./create-super-admin.component.scss']
})
export class CreateSuperAdminComponent implements OnInit {

  superAdminForm: UntypedFormGroup;
  disabled = false;
  errorMessage: string;
  
  constructor(public dialogRef: MatDialogRef<CreateSuperAdminComponent>,
    private superAdminService: SuperAdminService) { }

  ngOnInit(): void {
    this.superAdminForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [
        Validators.required
      ]),
      email: new UntypedFormControl('' , [
        Validators.required,
        Validators.email,
        validateEmailDomain
      ])
    });
  }

  createSuperAdmin() {
    if (this.superAdminForm.valid) {

      // reset the status message
      this.resetErrorMessage();

      // disable the form and button
      this.disabled = true;
      this.superAdminForm.disable();


      const payload = {
        ...this.superAdminForm.value
      }

      console.log(payload);

      this.superAdminService.createSuperAdmin(payload).subscribe(
        (res) => {
          this.disabled = false;
          this.superAdminForm.reset();

          // close the dialog
          this.dialogRef.close(true);
        },
        (err) => {
          this.errorMessage = 'Please try again';
          this.disabled = false;
          this.superAdminForm.enable();
        }
      );
    }
  }

  resetErrorMessage() {
    this.errorMessage = undefined;
  }



}
